import { Regex } from '../../../../config/regex';
import { AptPowerVoltage } from '../../../../enums/apttus/apt-power-voltage';
import { AttributeValues } from './base-attribute-values';
import { Product } from '../../../../../store/models/order-entry-state_v2';
import { toLocaleDateString } from '../../../../functions/misc.functions';
import { DestinationUse } from '../../../../enums/shared/destination-use.enum';
import { FlowType, MacroFlowType } from '../../../../../store/models/flow-type';
import { flowTypeUtil } from '../../../../functions/verifications.functions';

export class PowerAttributeValues extends AttributeValues {
    attributesMapping(): void {
        super.attributesMapping();

        this.attr['egl_POD__c'] = this.currentProduct?.podPdr;
        this.attr['egl_switchoutdate__c'] = toLocaleDateString(this.currentProduct?.technicalDetails?.switchOutDate);
        this.attr['egl_power_consumption__c'] = this.currentProduct?.technicalDetails?.consumption;
        this.attr['egl_power_consumption_declared__c'] = this.currentProduct?.technicalDetails?.consumption;
        this.attr['egl_voltage__c'] = getPowerVoltage(this.currentProduct);

        this.attr['egl_Required_Power__c'] =
            this.currentProduct?.technicalDetails?.pwrInstantaneousPower ||
            this.currentProduct?.technicalDetails?.needed?.pwrInstantaneousPower;
        this.attr['egl_Available_Power__c'] =
            this.currentProduct?.technicalDetails?.pwrAvailablePower ||
            this.currentProduct?.technicalDetails?.needed?.pwrAvailablePower;

        // Con il bug #246745 ci è stato richiesto di mandare i seguenti 2 campi solo per le Variazioni Tecniche
        // in quanto nel bug stesso viene asserito che siano ad uso esclusivo delle Variazioni
        if (flowTypeUtil(this.state.orderEntryV2.flowType).inMacroFlowTypes(MacroFlowType.Variazione)) {
            this.attr['egl_engaged_power_required__c'] =
                this.currentProduct?.technicalDetails?.needed?.pwrInstantaneousPower;
            this.attr['egl_available_power_required__c'] =
                this.currentProduct?.technicalDetails?.needed?.pwrAvailablePower;
        }

        this.attr['egl_supply_use__c'] = this.currentProduct?.configurations?.supplyUse;
        this.attr['egl_using_registration_tool__c'] = this.currentProduct?.configurations?.registrationTool;

        // NON INVIARE MAI egl_power_typesofusage__c, è un campo formula

        // this.attr['egl_power_typesofusage__c'] = this.currentProduct?.technicalDetails?.typeOfUsage as string;
        this.attr['egl_is_power_for_outbuilding__c'] =
            this.currentProduct?.configurations?.destinationUse === DestinationUse.Appurtenances;

        //Requisito Evoluzione Aumento Potenza
        //Mandiamo la valorizzazione del egl_predeterminate_estimate__c solo per i FlowType di VariazioneAumentoPotenza vs CPQ
        if (
            flowTypeUtil(this.state.orderEntryV2.flowType).equalTo(
                FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenza,
                FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenzaAmministrativa
            )
        ) {
            this.attr['egl_predeterminate_estimate__c'] = this.currentProduct?.prices?.predetermined ? 'SI' : 'NO';
        }
        /*

        Potenza Contrattuale egl_Required_Power__c 3
        Potenza Disponibile egl_Available_Power__c 3.3
        Potenza Contrattuale richiesta egl_engaged_power_required__c 10
        Potenza Disponibile richiesta egl_available_power_required__c 11

        egl_Available_Power__c: 3.3
        egl_Required_Power__c: "3"
        egl_available_power_required__c: "6.6"
        egl_engaged_power_required__c: "6"

        */
    }
}

const getPowerVoltage = (product: Product): AptPowerVoltage => {
    // Retrieving value from possible inputs
    const srcTension = `${product?.technicalDetails?.pwrVoltage || ''}`.trim();
    // Checking if value is a voltage number
    if (Regex.NUMBER_ONLY.test(srcTension) || !srcTension) {
        // Retrieving numeric part
        let floatTension = parseFloat(srcTension || '');
        // Handling default voltage
        if (!floatTension || isNaN(floatTension)) {
            floatTension = 220;
        }
        // Returning value + " V"
        return `${floatTension} V` as AptPowerVoltage;
    }
    // Returing non-numeric values for picklist
    return srcTension as AptPowerVoltage;
};
