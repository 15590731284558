import { CurrencyPipe, DatePipe, DecimalPipe, JsonPipe, PercentPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Regex } from '../config/regex';
import { getNumberOrNull } from '../functions/remap.functions';
import { FlowTranslatePipe } from './flow-translate.pipe';

type AutoFormatHandledType =
    | 'string'
    | 'number'
    | 'decimalNumber'
    | 'bigint'
    | 'currency'
    | 'boolean'
    | 'undefined'
    //    | 'symbol'
    //    | 'function'
    | 'Date'
    | 'Moment'
    | 'object'
    | 'i18n'
    | 'percent';

@Pipe({
    name: 'autoFormat',
})
export class AutoFormatPipe implements PipeTransform {
    DATA_TYPE_MAP: {
        [key in AutoFormatHandledType]: (value: any) => string;
    } = {
        string: (value: string) => value,
        number: (value: number | string) => value.toString(),
        decimalNumber: (value: number | string) => this.decimalPipe.transform(value),
        bigint: (value: number) => this.decimalPipe.transform(value),
        currency: (value: number | string) => {
            const numValue = getNumberOrNull(value);
            const ROOT_L10N_KEY = 'ORDER_ENTRY.COSTS.TABLE_FIELDS';
            return typeof numValue === 'number'
                ? this.currencyPipe.transform(numValue)
                : this.flowTranslatePipe.transform(ROOT_L10N_KEY, 'NOT_AVAILABLE');
        },
        boolean: (value: boolean) => this.translateService.instant(`COMMON.${value ? 'YES' : 'NO'}`),
        undefined: () => this.translateService.instant('COMMON.EMPTY'),
        Date: (value: Date) => this.datePipe.transform(moment(value).toDate(), 'shortDate'),
        Moment: (value: moment.Moment) =>
            value.isValid()
                ? this.datePipe.transform(value.toDate(), 'shortDate')
                : this.DATA_TYPE_MAP.undefined(value),
        object: (value: object) => this.jsonPipe.transform(value),
        i18n: (value: string) => {
            const i18n = this.flowTranslatePipe.transform(value);
            // Se traduzione è uguale alla chiave restituisco ''
            // Se la translate trova qualcosa, restituisco la traduzione
            return i18n === value && /[^.]+\.[^.]+/.test(value) ? '' : typeof i18n === 'string' || i18n ? i18n : value;
        },
        percent: (value: number | string) => this.percentPipe.transform(value, '1.0-2'), // es: 0.23479 => 23,48%
    };

    constructor(
        private decimalPipe: DecimalPipe,
        private currencyPipe: CurrencyPipe,
        private datePipe: DatePipe,
        private translateService: TranslateService,
        private jsonPipe: JsonPipe,
        private percentPipe: PercentPipe,
        private flowTranslatePipe: FlowTranslatePipe
    ) {}

    transform(value: any, customType?: 'currency' | 'decimalNumber' | 'percent' | 'string') {
        const type = customType || this.getValueType(value);
        return this.DATA_TYPE_MAP[type](value);
    }

    private getAutoFormatHandledType(value: any): AutoFormatHandledType {
        return !['function', 'symbol'].includes(typeof value) ? (typeof value as AutoFormatHandledType) : 'undefined';
    }

    private CUSTOM_TYPE_MAP: {
        [key in AutoFormatHandledType]?: (value: any) => boolean;
    } = {
        Date: (value) => value instanceof Date || Regex.ISO_8601.test(value),
        Moment: (value) => moment.isMoment(value),
        undefined: (value) => value === null || (typeof value === 'string' && !value.trim()),
        //'number': value =>  /^[\d.,]+$/.test(value),
        i18n: (value) => Regex.L10N_TOKEN_MATCHER.test(value),
    };

    private getValueType(value: any): AutoFormatHandledType {
        const handledFormatType: AutoFormatHandledType = (Object.entries(this.CUSTOM_TYPE_MAP).find(([, verificator]) =>
            verificator(value)
        ) || [])[0] as AutoFormatHandledType;
        return handledFormatType || this.getAutoFormatHandledType(value);
    }
}
