import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, PlatformConstants } from '@congacommerce/core';
import { get, includes, isEmpty, isNil } from 'lodash';
import { from, of, throwError, timer } from 'rxjs';
import { catchError, finalize, map, mergeMap, retryWhen, switchMap, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class EglApiService extends ApiService {
    callout(
        method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE',
        endpoint: string,
        payload?: any,
        responseType?: 'arraybuffer' | 'blob' | 'json',
    ) {
        const accessToken = this.eglSetAccessToken();
        const storefront = this.configService.get('storefront');
        const accountId = localStorage.getItem('account');
        const isRevoke = endpoint.indexOf('/users/token') >= 0 && method === 'DELETE';
        let headers = new HttpHeaders({
            'x-storefront': storefront,
        });
        if (!isNil(accessToken) && !isRevoke) headers = headers.set('Authorization', `Bearer ${accessToken}`);
        if (!isNil(accountId)) headers = headers.set('x-account', accountId);
        return this.httpClient.request(method, endpoint, {
            body: payload,
            responseType: responseType,
            headers: headers,
        });
    }

    eglSetAccessToken() {
        let _a;
        let accessToken;
        if (!isEmpty(document.cookie) && includes(document.cookie, `${PlatformConstants.ACCESS_TOKEN}=`)) {
            accessToken =
                (_a = document.cookie
                    .split('; ')
                    .find((row) => row.startsWith(`${PlatformConstants.ACCESS_TOKEN}=`))) === null || _a === void 0
                    ? void 0
                    : _a.split('=')[1];
        } else if (localStorage && localStorage.getItem(PlatformConstants.ACCESS_TOKEN)) {
            accessToken = localStorage.getItem(PlatformConstants.ACCESS_TOKEN);
        }
        return accessToken;
    }

    revokeToken() {
        const token = this.eglSetAccessToken();
        if (isNil(token)) return of(false);
        else {
            return this.delete(`/users/token/${token}`).pipe(
                catchError(() => of(true)),
                tap((success) => {
                    if (success) {
                        document.cookie = '';
                        localStorage.removeItem(PlatformConstants.ACCESS_TOKEN);
                    }
                }),
            );
        }
    }

    refreshToken(username, password) {
        if (
            !isEmpty(document.cookie) &&
            includes(document.cookie, `${PlatformConstants.ACCESS_TOKEN}=`) &&
            arguments.length === 0
        ) {
            return of(null);
        }
        if (localStorage && localStorage.getItem(PlatformConstants.ACCESS_TOKEN) && arguments.length === 0) {
            return of(null);
        }
        let attempts = 0;
        return this.eglEncryptCredentials(username, password).pipe(
            switchMap((credentials) => this.post(`/users/token`, credentials, null, false)),
            retryWhen((errors) =>
                errors.pipe(
                    map((error) => {
                        attempts += 1;
                        if (get(error, 'status') === 400 || attempts >= 2) throw error;
                        else {
                            document.cookie = '';
                            localStorage.removeItem(PlatformConstants.ACCESS_TOKEN);
                            localStorage.removeItem(PlatformConstants.USER_INFO);
                            return error;
                        }
                    }),
                ),
            ),
            tap((creds) => {
                if (!isNil(get(creds, 'accessToken'))) {
                    document.cookie = `${PlatformConstants.ACCESS_TOKEN}=${get(creds, 'accessToken')};path=/;Secure;SameSite=Lax`;
                    localStorage.setItem(PlatformConstants.ACCESS_TOKEN, get(creds, 'accessToken'));
                    this.onRefresh.emit(creds.accessToken);
                    if (!isNil(get(creds, 'LoginURL'))) {
                        const authenticationUrl = decodeURIComponent(get(creds, 'LoginURL'));
                        let url = authenticationUrl.replace('#URL#', encodeURIComponent(window.location.toString()));
                        url = `${this.configService.endpoint()}/secur/logout.jsp?retUrl=${encodeURIComponent(url)}`;
                        setTimeout(() => window.location.replace(url));
                    }
                }
            }),
        );
    }

    eglEncryptCredentials(username, password) {
        if (isNil(username) || isNil(password)) return of(null);
        else {
            return this.eglGetAuthKey().pipe(
                switchMap((key) => {
                    // Initialization Vector for CBC algorithm
                    const iv = window.crypto.getRandomValues(new Uint8Array(16));
                    // Raw customer credential data
                    const clientCredentials = {
                        key: username,
                        value: password,
                    };
                    // Supporting function to convert the hex key into an ArrayBuffer object
                    const hexToArrayBuffer = (hex) => {
                        const typedArray = new Uint8Array(
                            hex.match(/[\da-f]{2}/gi).map(function (h) {
                                return parseInt(h, 16);
                            }),
                        );
                        return typedArray.buffer;
                    };
                    // Supporting function converts a string into an ArrayBuffer
                    const getUtf8Bytes = (str) =>
                        new Uint8Array([...unescape(encodeURIComponent(str))].map((c) => c.charCodeAt(0)));
                    return from(
                        crypto.subtle
                            .importKey(
                                'raw',
                                hexToArrayBuffer(key),
                                {
                                    // this is the algorithm options
                                    name: 'AES-CBC',
                                    length: 256,
                                },
                                false, // whether the key is extractable (i.e. can be used in exportKey)
                                ['encrypt', 'decrypt'], // key can be used for encryption and decryption
                            )
                            .then((keyImport) => {
                                return crypto.subtle.encrypt(
                                    {
                                        name: 'AES-CBC',
                                        // Don't re-use initialization vectors!
                                        // Always generate a new iv every time your encrypt!
                                        iv: iv,
                                        length: 256,
                                    },
                                    keyImport, // CryptoKey object generated from hex key
                                    getUtf8Bytes(JSON.stringify(clientCredentials)), // ArrayBuffer of client credentials
                                );
                            })
                            .then((encrypted) => {
                                // The result from the promise will contain encrypted customer credentials.
                                // In order to decrypt the request, the IV used must be prepended to the body
                                const requestData = btoa(
                                    String.fromCharCode(...iv) + String.fromCharCode(...new Uint8Array(encrypted)),
                                );
                                return requestData;
                            }),
                    );
                }),
            );
        }
    }

    eglGetAuthKey() {
        return this.get('/users/key');
    }
}

export const genericRetryStrategy =
    ({
        maxRetryAttempts = 3,
        scalingDuration = 1000,
        excludedStatusCodes = [],
        onError = null,
        setTokenExpired = true,
    } = {}) =>
    (attempts) => {
        return attempts.pipe(
            mergeMap((error: any, i) => {
                const retryAttempt = i + 1;
                // if maximum number of retries have been met
                // or response is a status code we don't wish to retry, throw error
                if (
                    error.status === 0 &&
                    !error.body &&
                    ((!isEmpty(document.cookie) && includes(document.cookie, `${PlatformConstants.ACCESS_TOKEN}=`)) ||
                        localStorage.getItem(PlatformConstants.ACCESS_TOKEN))
                ) {
                    if (setTokenExpired) localStorage.setItem(PlatformConstants.TOKEN_EXPIRED, JSON.stringify(true));
                    document.cookie = '';
                    localStorage.removeItem(PlatformConstants.ACCESS_TOKEN);
                    window.location.reload();
                    return throwError(error);
                }
                if (retryAttempt > maxRetryAttempts || excludedStatusCodes.find((e) => e === error.status)) {
                    return throwError(error);
                } else {
                    if (error.name === 'HttpErrorResponse' && onError)
                        return onError.pipe(switchMap(() => timer(retryAttempt * scalingDuration)));
                    else return timer(retryAttempt * scalingDuration);
                }
            }),
            finalize(() => console.log('Api Callout Done')),
        );
    };
