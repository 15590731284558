import { EventEmitter, Injectable, Injector } from '@angular/core';
import { Cart, CartService } from '@congacommerce/ecommerce';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { take, map, tap, retryWhen, delay, switchMap, filter, catchError } from 'rxjs/operators';
import { apttusResponseIronHand } from '../../../functions/misc.functions';
import { EglCartExtended } from '../../../models/apttus/tables/cart/egl-cart-extended';
import { EglCartItemExtended } from '../../../models/apttus/tables/cart/egl-cart-item-extended';
import { CacheService, ConfigurationService, MetadataService, PlatformService } from '@congacommerce/core';
import { compact, get, isNil, join, set, map as _map, uniq } from 'lodash';
import { FeatureToggleService } from '../../shared/feature-toggle.service';
import { LoggerService } from '../../shared/logger.service';
import { plainToClass } from 'class-transformer';
import { AptSalesProcess } from '../../../enums/apttus/apt-sales-process';
import { MacroFlowType, FlowType } from '../../../../store/models/flow-type';
import { aptSalesProcessToFlowType } from '../../../functions/remap.functions';
import { flowTypeUtil } from '../../../functions/verifications.functions';
import { LoadingService } from '../../shared/loading.service';

export type EglCartPriceResponse = {
    status: 'success' | 'error';
    response: EglCartExtended;
};

@Injectable({ providedIn: 'root' })
export class EglCartService extends CartService {
    eglCartLookups = `AccountId,PriceListId,OrderId,Proposald,Account.PriceListId,Account.OwnerId,ShipToAccountId,BillToAccountId`;
    eglCartChildren = `SummaryGroups,Applied_Rule_Infos,AppliedRuleActionInfos`;
    EGL_STORAGE_KEY = 'local-cart';

    public priceResponseEvt = new EventEmitter<EglCartPriceResponse>(); // usato in cart-pricing.service

    constructor(
        cacheService: CacheService,
        platformService: PlatformService,
        metadataService: MetadataService,
        configurationService: ConfigurationService,
        injector: Injector,
        private toggleSrv: FeatureToggleService,
        private logger: LoggerService
    ) {
        super(cacheService, platformService, metadataService, configurationService, injector);
        this.setType(EglCartExtended);
    }

    refreshCart() {
        let attempts = 0;
        return this.userService
            .isLoggedIn()
            .pipe(
                switchMap((loggedIn) => {
                    const cart$ = this.apiService.get(
                        `/carts/${CartService.getCurrentCartId()}?lookups=${this.eglCartLookups}&children=${
                            this.eglCartChildren
                        }&alias=false`,
                        this.type,
                        false
                    );
                    const obsv$ = cart$.pipe(
                        switchMap((cart) => {
                            if (!isNil(get(cart, 'Id')))
                                return this.getEglCartLineItems(cart.Id).pipe(
                                    map((lines: EglCartItemExtended[]) => {
                                        lines = lines.map((lineItem) =>
                                            apttusResponseIronHand<EglCartItemExtended>(lineItem, ['AttributeValue'])
                                        );
                                        set(cart, 'LineItems', lines);
                                        return cart;
                                    })
                                );
                            else return of(cart);
                        })
                    );
                    return obsv$.pipe(
                        map((cart) => {
                            if (loggedIn && !isNil(get(cart, 'Id'))) CartService.setCurrentCartId(get(cart, 'Id'));
                            else if (isNil(cart) && localStorage.getItem(this.EGL_STORAGE_KEY)) {
                                CartService.deleteLocalCart();
                                this.refreshCart();
                            }
                            if (
                                get(cart, 'IsPricePending') === true &&
                                this.configService.get('pricingMode') !== 'turbo'
                            ) {
                                if (attempts <= this.configService.get('cartRetryLimit', 3)) {
                                    throw new Error('Cart Pending');
                                }
                            }
                            return cart;
                        }),
                        retryWhen((errors) =>
                            errors.pipe(
                                tap((e) => {
                                    if (e?.message === 'Cart Pending') attempts += 1;
                                    else throw e;
                                }),
                                delay(500)
                            )
                        )
                    );
                }),
                take(1),
                map((cart) => apttusResponseIronHand<EglCartExtended>(cart, ['LineItems']))
            )
            .subscribe((c) => this.publish(c));
    }

    private getEglCartLineItems(cartId: string) {
        if (isNil(cartId)) return of(null);

        return this.apiService.post(
            '/Apttus_Config2__LineItem__c/query',
            {
                alias: false,
                conditions: [
                    {
                        field: 'ConfigurationId',
                        filterOperator: 'Equal',
                        value: cartId,
                    },
                ],
                lookups: [
                    {
                        field: 'Product',
                        children: [
                            {
                                field: 'OptionGroups',
                            },
                        ],
                    },
                    {
                        field: 'Option',
                        children: [
                            {
                                field: 'OptionGroups',
                            },
                        ],
                    },
                    {
                        field: 'ProductOption',
                    },
                    {
                        field: 'AttributeValue',
                    },
                    {
                        field: 'AssetLineItem',
                    },
                    {
                        field: 'PriceListItem',
                    },
                    {
                        field: 'Location',
                    },
                    {
                        field: 'egl_service_address_id',
                    },
                ],
                children: [
                    {
                        field: 'AdjustmentLineItems',
                    },
                ],
            },
            EglCartItemExtended,
            null,
            false
        );
    }

    getMyCart(pending = false): Observable<EglCartExtended> {
        return this.cartState.pipe(
            filter((c: EglCartExtended) => !isNil(c)),
            map((cart: EglCartExtended) => {
                const remappedCart = apttusResponseIronHand<EglCartExtended>(cart, ['LineItems']);
                remappedCart.LineItems = (cart.LineItems || []).map((lineItem) =>
                    apttusResponseIronHand<EglCartItemExtended>(lineItem, ['AttributeValue'])
                );
                return remappedCart;
            })
        );
    }

    /**
     * Overriding base function. We need to check errors in pricing. Function super.isPricePending is not enough
     * @param cart
     * @returns true if pricePending or priceError
     */
    isPricePending(cart: EglCartExtended): boolean {
        /**
         * I carrelli dei flussi di SWITCH-IN RECUPERO, SWITCH-IN INSERIMENTO POSTICIPATO e tutti gli amministrativi di VOLTURA, VOLTURA CON SWITCH e ATTIVAZIONI
         * non avendo la pricing date hanno problemi di pricing nella pagina del cart, si bypassa il pricing in quella fase e lo si esegue dopo aver settato la data firma (SignatureDateComponent - ContrattoCartaceoComponent)
         */
        if (
            (flowTypeUtil(aptSalesProcessToFlowType(cart.egl_sales_process)).inMacroFlowTypes(
                [MacroFlowType.SwitchIn, MacroFlowType.Administrative],
                [MacroFlowType.VolturaSwitchIn, MacroFlowType.Administrative, MacroFlowType.Vip],
                [MacroFlowType.Attivazione, MacroFlowType.Administrative, MacroFlowType.Vip],
                [MacroFlowType.AttivazioneComplessa, MacroFlowType.Administrative, MacroFlowType.Vip],
                [MacroFlowType.Voltura, MacroFlowType.Administrative, MacroFlowType.Vip]
            ) ||
                (flowTypeUtil(aptSalesProcessToFlowType(cart.egl_sales_process)).equalTo(FlowType.SwitchIn) &&
                    cart.LineItems.some((li) => li.Product?.egl_is_expired))) &&
            !cart.PricingDate
        ) {
            LoadingService.hide();
            return false;
        }
        const isPricePending = super.isPricePending(cart);
        const priceNotCompleted = !!(cart?.LineItems || []).find((items) => items.PricingStatus !== 'Complete');
        return isPricePending || priceNotCompleted;
    }

    priceCart(pricingMode = this.configService.get('pricingMode'), lineNumbers: number[]): void {
        const inputPricingMode = pricingMode;
        const isAboOperation =
            (this.cartState?.value?.LineItems || []).some((l) => l.AssetLineItemId) &&
            this.toggleSrv.isAboPricingModeEnabled;
        pricingMode = isAboOperation ? this.configService.get('aboPricingMode') || 'default' : pricingMode;
        // Se il carrello ha dei coupon/incentive applicati, uso il pricing di default perchè il turbo da errore
        if (this.cartState?.value?.CouponCodes) {
            pricingMode = 'default';
        }
        this.logger.info(`Modalità pricing per il cart ${CartService.getCurrentCartId()}: ${pricingMode}`, {
            isAboOperation,
            isAboPricingModeEnabled: this.toggleSrv.isAboPricingModeEnabled,
            inputPricingMode,
            configAboPricingMode: this.configService.get('aboPricingMode'),
            couponOrIncentive: this.cartState?.value?.CouponCodes,
        });
        // super.priceCart(mode, lineNumbers);

        const lineParam = !isNil(lineNumbers) ? '&lineNumbers=' + join(compact(lineNumbers), ',') : '';
        const currentCartId = CartService.getCurrentCartId();
        const url = `/carts/${currentCartId}/price?mode=${pricingMode}&alias=false${lineParam}`;
        this.apiService
            .post(url, null, this.type, null, false)
            .pipe(
                take(1),
                catchError((error) => {
                    this.priceResponseEvt.emit({ status: 'error', response: error });
                    throw error;
                }),
                tap((response) => this.priceResponseEvt.emit({ status: 'success', response }))
            )

            .subscribe((priceResponse: EglCartExtended) => {
                if (pricingMode === 'turbo' && typeof priceResponse !== 'boolean') {
                    const cartState = this.cartState.value;

                    for (const currentLineItemRes of priceResponse?.LineItems) {
                        if (!currentLineItemRes.Id) {
                            currentLineItemRes.Id = cartState.LineItems.find(
                                (cartLineItem) =>
                                    cartLineItem.ExtendedDescription === currentLineItemRes.ExtendedDescription
                            )?.Id;
                        }

                        const cartLine = cartState.LineItems?.find(
                            (cartLineItem) => cartLineItem.Id === currentLineItemRes.Id
                        );

                        if (cartLine) {
                            currentLineItemRes.Product = cartLine.Product;
                            currentLineItemRes.AttributeValue = cartLine.AttributeValue;
                            currentLineItemRes.ProductOption = cartLine.ProductOption;
                            currentLineItemRes.Option = cartLine.Option;
                        }
                    }

                    const lineItemeIdInResponse = priceResponse?.LineItems?.map((lineItemResp) => lineItemResp?.Id);
                    for (const cartLineItem of cartState?.LineItems) {
                        if (lineItemeIdInResponse.includes(cartLineItem.Id)) {
                            cartLineItem.AdjustmentLineItems = null;
                            cartLineItem.IncentiveAdjustmentAmount = null;
                            cartLineItem.IncentiveCode = null;
                        }
                    }
                    const newLines = priceResponse?.LineItems?.filter((line) => !line.Id);
                    const existingLines = priceResponse?.LineItems?.filter((line) => !!line.Id);

                    const lineItemsById = <{ [key in string]?: EglCartItemExtended }>{};
                    for (const item of cartState.LineItems.concat(existingLines)) {
                        lineItemsById[item.Id] = item;
                    }
                    const mergedItems = Object.values(lineItemsById).filter(Boolean);

                    cartState.LineItems = <EglCartItemExtended[]>(
                        plainToClass(
                            this.metadataService.getTypeByApiName('Apttus_Config2__LineItem__c'),
                            [].concat(mergedItems, newLines),
                            { ignoreDecorators: true }
                        )
                    );
                    cartState.SummaryGroups = priceResponse?.SummaryGroups;
                    cartState.Id = cartState.Id || CartService.getCurrentCartId();
                    cartState.IsPricePending = false;
                    if (CartService['DeferredPrice']) {
                        cartState['IsPriced'] = false;
                    }
                    if (!lineNumbers) {
                        // Se chiamo l'api di pricing senza lineNumbers allora la response conterrà sicuramente tanti line item quanti quelli presente a carello su salesforce.
                        // In questo caso se nello state (getMyCart) ci sono più line items li rimuovo
                        cartState.LineItems = (cartState.LineItems || []).filter((l) =>
                            priceResponse.LineItems.map((resLineItem) => resLineItem.Id).includes(l.Id)
                        );
                    }
                    this.publish(cartState);
                } else {
                    this.refreshCart();
                }
            });
    }

    public get cartState(): BehaviorSubject<EglCartExtended> {
        return this['state'];
    }
}

export function mapPriceStatus(cart: Cart) {
    const outMap = (cart?.LineItems || []).reduce(
        (agg, curr) => ({
            ...agg,
            [curr.PricingStatus || 'NoPricingStatus']: [
                ...get(agg, [curr?.PricingStatus], {}),
                {
                    id: curr?.Id,
                    lineItemId: curr?.AssetLineItemId,
                    lineNumber: curr?.LineNumber,
                    priceListId: curr?.PriceListId,
                    priceListItemId: curr?.PriceListItemId,
                    name: curr?.Name,
                    description: curr?.LineType === 'Option' ? curr?.Option?.Name : curr?.Product?.Name,
                    lineType: curr?.LineType,
                    netPrice: curr?.NetPrice,
                },
            ],
        }),
        {}
    );
    return {
        cartId: cart?.Id,
        ...outMap,
    };
}
