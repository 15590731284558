import { AptSalesProcess } from './../../../common/enums/apttus/apt-sales-process';
import { BoActionFilter } from '../../../common/enums/shared/bo-action-filter';
import { D365ProfileCode } from '../../../common/enums/d365/d365-profile-code';

export class ManageQuoteRequest {
    Filtri: ManageQuoteFiltri;
    Obfuscate: boolean;

    constructor(skip: number = 0, take: number = 0, profilo?: D365ProfileCode) {
        this.Filtri = new ManageQuoteFiltri(skip, take, profilo);
    }
}
export class ManageQuoteFiltri {
    /**
     *
     */
    constructor(skip: number, take: number, profilo: D365ProfileCode) {
        this.skip = skip;
        this.take = take;
        this.profilo = profilo;
    }
    profilo: D365ProfileCode;
    channel: string[];
    virtualAgencies: string[];
    dateFilter: 'signDate' | 'createDate' | '';
    dateFrom: string;
    dateTo: string;
    action: BoActionFilter[] = [];
    skip: number;
    take: number;

    sortByField: string;
    sortByType: 'ASC' | 'DESC';
    quoteId?: string;
    salesProcess: AptSalesProcess;

    agencyBranch: string[];
}
