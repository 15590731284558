/**
 * @author Marco Ricupero ft Andreea Stegariu, Felice Lombardi, Ajay Kumar, Giovanni Bazan, Lorenzo Corbella, Natalia Veras 🐲
 * @version 3.1.0
 *                                         ,   ,
 *                                         $,  $,     ,
 *                                         "ss.$ss. .s'
 *                                 ,     .ss$$$$$$$$$$s,
 *                                 $. s$$$$$$$$$$$$$$`$$Ss
 *                                 "$$$$$$$$$$$$$$$$$$o$$$       ,
 *                                s$$$$$$$$$$$$$$$$$$$$$$$$s,  ,s
 *                               s$$$$$$$$$"$$$$$$""""$$$$$$"$$$$$,
 *                               s$$$$$$$$$$s""$$$$ssssss"$$$$$$$$"
 *                              s$$$$$$$$$$'         `"""ss"$"$s""
 *                              s$$$$$$$$$$,              `"""""$  .s$$s
 *                              s$$$$$$$$$$$$s,...               `s$$'  `
 *                          `ssss$$$$$$$$$$$$$$$$$$$$####s.     .$$"$.   , s-
 *                            `""""$$$$$$$$$$$$$$$$$$$$#####$$$$$$"     $.$'
 *                                  "$$$$$$$$$$$$$$$$$$$$$####s""     .$$$|
 *                                   "$$$$$$$$$$$$$$$$$$$$$$$$##s    .$$" $
 *                                    $$""$$$$$$$$$$$$$$$$$$$$$$$$$$$$$"   `
 *                                   $$"  "$"$$$$$$$$$$$$$$$$$$$$S""""'
 *                              ,   ,"     '  $$$$$$$$$$$$$$$$####s
 */
import { Injectable, Injector } from '@angular/core';
import { Location } from '@angular/common';
import {
    ActivatedRoute,
    NavigationEnd,
    NavigationError,
    NavigationExtras,
    Params,
    ResolveEnd,
    Router,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, EMPTY, forkJoin, from, Observable, of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { RoutesPaths } from '../../../config/routes-paths';
import { EglState } from '../../../../store/reducers';
import { selectFlowType } from '../../../../store/selectors/order-entry.selectors';
import { RouterServiceInterface } from './router-service.interface';
import { LoggerService } from '../logger.service';
import {
    DragonRouteConfiguration,
    DragonRouterPageConfig,
    DragonRouteSubConfiguration,
    OrderEntryPathConfiguration,
    RailwayFn,
    RailwayStop,
} from './dragon-router.type';
import { ORDER_ENTRY_PATHS } from './dragon-router-config';
import { FeatureToggleService } from '../feature-toggle.service';
import { callbackToObservable, mergeMapOrEmpty } from '../../../functions/observable-operators';
import { FlowType } from '../../../../store/models/flow-type';
import { LoadingService } from '../loading.service';
import { V2SelectDefaultNavigationParams } from '../../../../store/selectors/order-entry-v2.selectors';
import { DragonUtilsService } from './dragon-utils.service';
import { PrivateConfigurationService } from '../private-configuration.service';
import { ServiceError } from '../../../models/app/service-error';

@Injectable({
    providedIn: 'root',
})
export class DragonRouterService implements RouterServiceInterface {
    private readonly VERIFIED_ROUTE_CONFIGS: DragonRouteConfiguration[] =
        this.checkOrderEntryConfigs(ORDER_ENTRY_PATHS);
    constructor(
        private eglState: Store<EglState>,
        private injector: Injector,
        private activatedRoute: ActivatedRoute,
        private logger: LoggerService,
        private featureToggleService: FeatureToggleService,
        private dragonUtilsSrv: DragonUtilsService,
        private location: Location,
        private router: Router,
        private configSrv: PrivateConfigurationService
    ) {
        this.preventHistoryBackListener();
    }

    private preventHistoryBackListener(): void {
        const pushState = (url?: string) =>
            this.configSrv.config.pagesWithoutBackBtn.find((pageNoBack) =>
                (url || this.router.url).includes(pageNoBack)
            ) && history.pushState(null, null, null);

        // listener on history back
        addEventListener('popstate', (res: PopStateEvent) => pushState());

        // listener on navigation end
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                tap((event: NavigationEnd) => pushState(event?.url))
            )
            .subscribe();
    }

    private checkOrderEntryConfigs(orderEntryConfigs: OrderEntryPathConfiguration[]): DragonRouteConfiguration[] {
        return (orderEntryConfigs || [])
            .map((config, index) => this.checkOrderEntryConfig(config, index))
            .filter(({ flowTypes, railway }) => flowTypes.size && railway.size);
    }

    private checkOrderEntryConfig(
        { flowTypes, railway, ...params }: OrderEntryPathConfiguration,
        index?: number
    ): DragonRouteConfiguration {
        const flowTypeSet = new Set(flowTypes || []);
        const railwaySet = new Set(
            (railway || []).map((railwayStop) =>
                this.dragonUtilsSrv.isOrderEntrySubPathConfiguration(railwayStop)
                    ? {
                          ...railwayStop,
                          requiredParams: new Set(railwayStop.requiredParams || []),
                          railway: new Set(railwayStop.railway || []),
                      }
                    : railwayStop
            )
        );

        if (flowTypeSet.size !== (flowTypes || []).length) {
            this.logger.warn(
                `[Dragon Router] Configuration #${index} has duplicate flowType and it was removed at runtime`
            );
        }

        if (railwaySet.size !== (railway || []).length) {
            this.logger.warn(
                `[Dragon Router] Configuration #${index} has duplicate railway item and it was removed at runtime`
            );
        }

        return {
            flowTypes: flowTypeSet,
            railway: railwaySet,
            ...params,
        };
    }

    private isDestinationRailwayStop(
        railwayStop: RailwayStop | RoutesPaths | DragonRouteSubConfiguration
    ): railwayStop is DragonRouterPageConfig | RoutesPaths {
        return typeof railwayStop === 'string' || this.dragonUtilsSrv.isDragonRouterPageConfig(railwayStop);
    }

    async back(): Promise<void> {
        this.location.back();
    }

    private spliceNextPossibleRoutes(
        railwaySet: RailwayStop[],
        routeFragment: string
    ): Array<DragonRouterPageConfig | RailwayFn> {
        const railway = Array.from(railwaySet);

        // Individuo la Pagina corrente all'interno della sequenza
        const currentStopIndex = this.dragonUtilsSrv.findRailwayStopIndex(railway, routeFragment);
        // Se non trovo la rotta corrente nella railway in input resistuisco null
        if (currentStopIndex < 0 && routeFragment) {
            return [];
        }

        // Costruisco la response con l'elenco di eventuali funzioni da eseguire e/o path di destinazione
        // Filtro la porzione del railway tra la rotta corrente
        const slicedRailway = railway
            .slice(currentStopIndex + 1)
            // filtro via le espressioni regolari
            .filter(
                (railwayStop): railwayStop is DragonRouterPageConfig | RailwayFn => !(railwayStop instanceof RegExp)
            );
        return slicedRailway.some((railwayStop) => this.isDestinationRailwayStop(railwayStop)) ? slicedRailway : [];
    }

    private getParams(routeParams?: Params) {
        return combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams]).pipe(
            map(([params, queryParams]) => ({ ...params, ...queryParams, ...(routeParams || {}) }))
        );
    }

    private *retrievePaths({
        flowType,
        routeFragment,
        params,
    }: {
        flowType: FlowType;
        routeFragment: string;
        params: Params;
    }): Generator<
        (input: { params: Params }) => Observable<{
            params: Params;
            path?: string;
        }>
    > {
        for (let routeCfg of this.VERIFIED_ROUTE_CONFIGS) {
            // Individuo la configurazione che include il FlowType corrente e la rotta corrente
            if (
                routeCfg?.flowTypes.has(flowType) &&
                (!routeCfg?.toggleField || this.featureToggleService[routeCfg?.toggleField] !== false)
            ) {
                // Mapping route paths & DragonRouterPageConfig to DragonRouterPageConfig, flattening sub routes w/ skippingRules
                const flatRailway = Array.from(routeCfg?.railway).reduce(
                    (aggr, railwayStop) => [
                        ...aggr,
                        // Enriching configurations with rootPath
                        ...this.dragonUtilsSrv.mapRouteConfigPath(railwayStop),
                    ],
                    []
                );

                const nextPossibleRoutes = this.spliceNextPossibleRoutes(flatRailway, routeFragment) || [];
                for (let railwayStop of nextPossibleRoutes) {
                    // gestione functions interne al railway
                    if (typeof railwayStop === 'function') {
                        // eseguire eventuali functions a cascata
                        this.logger.info(`[🐲] retrievePaths | Fn | ${railwayStop.name}`);
                        yield (input: { params: Params }) =>
                            callbackToObservable(
                                (railwayStop as RailwayFn)({
                                    injector: this.injector,
                                    eglState: this.eglState,
                                    params: { ...(params || ({} as Params)), ...(input?.params || ({} as Params)) },
                                })
                            ).pipe(
                                map((resParams) => ({
                                    params: resParams,
                                }))
                            );
                    } else if (!!railwayStop?.path) {
                        // eseguire le skipping rules sui percorsi rimasti passando i parametri ricevuti da functions precedenti
                        this.logger.info(`[🐲] retrievePaths | path | ${railwayStop.path}`);
                        yield (input: { params: Params }) =>
                            (railwayStop as DragonRouterPageConfig).skip(input?.params).pipe(
                                map((toSkip) => ({
                                    ...input,
                                    path: toSkip ? null : (railwayStop as DragonRouterPageConfig).path,
                                }))
                            );
                    }
                }
            }
        }
        return EMPTY;
    }

    private dragonScan<T>(
        resolverRetriever: (input: T) => (input: IPathParams) => Observable<IPathParams>,
        paramsRetriever: (input: T) => Params
    ): (src: Observable<T>) => Observable<IPathParams> {
        // Accumulatore di queryParams
        let resolvedParams: Params;
        return (src: Observable<T>) =>
            src.pipe(
                // Utilizzo la concatMap per lavorare un evento alla volta solo dopo il completamento del precedente
                concatMap((data: T) =>
                    // Aggrego i parametri dell'accumulatore con quelli della funzione di recupero in ingresso
                    of({
                        ...(resolvedParams || {}),
                        ...(paramsRetriever(data) || {}),
                    }).pipe(
                        // Aggancio l'observable della chiamata di recupero dati (function o skipping rule)
                        mergeMap((params) =>
                            // Eseguo la chiamata con i parametri arricchiti
                            resolverRetriever(data)({ params }).pipe(
                                // Limito il recupero dati ad 1 evento
                                take(1),
                                // Rimappo i parametri in uscita con quelli utilizzati per la chiamata arricchiti con quelli nella response
                                map((data) => ({
                                    ...data,
                                    params: {
                                        ...params,
                                        ...(data.params || {}),
                                    },
                                }))
                            )
                        )
                    )
                ),
                tap(({ params }) => {
                    // salvo i parametri nell'accumulatore
                    resolvedParams = params;
                }),
                filter((res) => !!res?.path)
            );
    }

    next(routeParams?: Params): Promise<boolean> {
        // retrieving flowType
        return combineLatest([
            this.eglState.select(selectFlowType),
            of(this.router.url.replace(/^(\/)/, '')),
            this.getParams(routeParams),
        ])
            .pipe(
                take(1),
                concatMap(([flowType, routeFragment, params]) =>
                    from(this.retrievePaths({ flowType, routeFragment, params })).pipe(
                        map((resolver) => ({
                            // Funzione nella configurazione del railway o skipping rule
                            resolver,
                            // Eventuali parametri restituiti dalla funzione e/o query params
                            params,
                        }))
                    )
                ),
                this.dragonScan(
                    // Funzione nella configurazione del railway o skipping rule
                    ({ resolver }) => resolver,
                    // Eventuali parametri restituiti dalla funzione e/o query params
                    ({ params }) => params
                ),
                take(1),
                mergeMapOrEmpty(
                    // Redirect on target found
                    ({ path, params }) => (
                        this.logger.info(`[🐲] Handled Route => ${path}`), this.navigateToPath(path, params)
                    ),
                    // fallback using legacy router on empty obs
                    () => (this.logger.error(`[🐲] Unhandled route ${this.router.url}`), of(null))
                ),
                // fallback interface
                catchError((error) => {
                    this.logger.error(null, '[🐲] Dragon-router error', error, false);
                    if (ServiceError.isServiceError(error)) {
                        if (error.code === 'CC_EXTRACOMMODITY') {
                            // Nel caso in cui la scorecard abbia fornito esito negativo e il carrello contenga solo prodotti extracommodity, rilancio l'errore per poter aprire una modale
                            throw error;
                        }
                        return this.navigateToPath(RoutesPaths.KoCreditCheck, { errorCode: error.code });
                    }
                    return this.navigateToPath(RoutesPaths.Error500, {
                        message: error?.message,
                        errorCode: error?.code,
                    });
                }),
                LoadingService.loaderOperator()
            )
            .toPromise();
    }

    private replacePathParams(
        path: string,
        params: Params
    ): {
        path: string; // Resolved path with atching params
        params: Params; // Left params
    } {
        // Sostituisco nel path eventuali parametri in input che corrispondono a campi dinamici nello stesso
        return Object.entries(params).reduce(
            (aggr, [key, value]) => ({
                // Sostituisco il placeholder del campo dinamico, se presente, con il relativo valore
                path: aggr.path.replace(`:${key}`, value),
                params: {
                    ...aggr.params,
                    // Se la chiave presente non è presente nel path originale, viene raccolta nell'elenco di parametri non utilizzati
                    ...(!aggr.path.includes(`:${key}`)
                        ? {
                              [key]: value,
                          }
                        : {}),
                },
            }),
            { path, params: {} as Params }
        );
    }

    public navigateToPath(path: string, params: Params = {}, preserveParams = true) {
        return combineLatest([
            this.eglState.select(V2SelectDefaultNavigationParams),
            preserveParams ? this.activatedRoute.queryParams : of(null),
        ]).pipe(
            take(1),
            mergeMap(([stateParams, queryParams]) =>
                forkJoin([
                    this.router.events.pipe(
                        tap((event) => {
                            if (event instanceof NavigationError) {
                                this.logger.error(null, event.error.message, event.error);
                                throw new Error(`Navigation error to ${path}`);
                            }
                        }),
                        filter((event) => event instanceof NavigationEnd || event instanceof ResolveEnd),
                        take(1)
                    ),
                    this.routerNavigate({ path, params, stateParams, queryParams }),
                ])
            ),
            map(() => true),
            tap(() => this.logger.debug(`Navigation to ${path} completed`))
        );
    }

    private routerNavigate({
        path,
        params,
        stateParams,
        queryParams,
    }: {
        path: string;
        params: Params;
        stateParams: Params;
        queryParams: Params;
    }): Observable<void> {
        // Recupero il path con i campi dinamici risolti (ovvero valorizzati con i params in ingresso) e l'elenco parametri non utilizzati per il path
        const resolvedPathParams = this.replacePathParams(path, {
            ...params,
            // Aggiungo il flowtype e altri parametri dello state per automatizzare la gestione del flusso
            ...stateParams,
        });
        const navExtra = {
            // Costruisco i query params
            queryParams: {
                // Utilizzo i query params sulla rotta corrente
                ...(queryParams || {}),
                //arricchisco con i parametri non utilizzati nel path
                ...resolvedPathParams.params,
                // Rimuovo tutti i parametri precedentemente aggiunti
                ...Object.keys(stateParams).reduce(
                    (agg, key) => ({
                        ...agg,
                        [key]: undefined,
                    }),
                    {} as Params
                ),
            },
        };

        /*
        return from(this.router.navigate([resolvedPathParams.path], navExtra)).pipe(
            tap((success) => {
                if (!success) {
                    throw new Error(
                        `Non è stato possible navigare alla pagina ${
                            resolvedPathParams.path
                        }, extra params: ${JSON.stringify(navExtra || 'null or undefined')}`
                    );
                }
            }),
            map(() => null)
        );
        */
        this.router.navigate([resolvedPathParams.path], navExtra);
        return of(null);
    }

    /**
     * @description: Naviga alla pagina specificata
     */
    navigate(commands: any[], extras?: NavigationExtras): void {
        LoadingService.show();
        // TODO: rifattorizzare con routerNavigate
        this.router
            .navigate(commands, extras)
            .then((navigateSuccess) => {
                if (!navigateSuccess) {
                    this.logger.error(`Navigation to '${commands}' failed`);
                }
            })
            .finally(() => LoadingService.hide());
    }

    /**
     * @description Naviga verso il catalogo dei prodotti
     * @returns void
     */
    public goToCatalog(...items: string[]): void {
        const routesArray: string[] = [RoutesPaths.AllProducts];
        items = items?.filter((value) => !!value);
        if (items?.length > 0) {
            routesArray.push(RoutesPaths.ProductListCategory, items?.join('/'));
        }
        this.navigateToPath(routesArray?.join('/'), {}, false).subscribe();
    }

    /**
     * @description Naviga verso la configurazione di un prodotto
     * @returns void
     */
    public goToProductDetail(...items: string[]): void {
        const routesArray = [RoutesPaths.AllProducts].concat(<RoutesPaths[]>items?.filter(Boolean));
        this.navigateToPath(routesArray.join('/'), {}, false).subscribe();
    }

    /**
     * @description Naviga verso la homepage
     * @returns void
     */
    public goToDashboard(): void {
        this.navigateToPath(RoutesPaths.Dashboard, {}, false).subscribe();
    }
}

interface IPathParams {
    params: Params;
    path?: string;
}
