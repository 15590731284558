import { AttributeRuleService, Product, ProductAttributeValue } from '@congacommerce/ecommerce';
import { Injectable } from '@angular/core';
import { ACondition, AFilter, APageInfo, SalesforceUtils } from '@congacommerce/core';
import { zip, of, BehaviorSubject, Observable } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { MemoizeAll } from 'lodash-decorators';
import { LoggerService } from '../../../../common/services/shared/logger.service';
import { EglProductExtended } from '../../../../common/models/apttus/tables/product/egl-product-extended';
import {
    ConditionCriteriaEntity,
    EglProductAttributeRule,
} from '../../../../common/models/apttus/tables/product/egl-product-attribute-rule';
import { flatten, get, join, replace, set, trim, trimEnd } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class EglAttributeRuleService extends AttributeRuleService {
    get logger() {
        return this.injector.get(LoggerService);
    }

    private OPERATOR_STRATEGY: {
        [key in '!=' | '==']: (
            ruleName: string,
            condition: string, //condizione completa
            actualValue: string, //Valore del field attuale
            expectedValue: string, //Valore che ci si aspetta nella rule
            checkedField: string
        ) => boolean;
    } = {
        '!=': (
            ruleName: string,
            condition: string,
            actualValue: string,
            expectedValue: string,
            checkedField: string
        ) => {
            if (actualValue === expectedValue) {
                this.logger.info(`Rule. Not applicable "${ruleName}".`, {
                    condition,
                    mismatch: `${actualValue} === ${expectedValue}`,
                    [checkedField]: actualValue,
                    [checkedField.replace('__c', '')]: actualValue,
                    checkValue: expectedValue,
                });
                return false;
            }
            return true;
        },
        '==': (
            ruleName: string,
            condition: string,
            actualValue: string,
            expectedValue: string,
            checkedField: string
        ) => {
            if (actualValue !== expectedValue) {
                this.logger.info(`Rule. Not applicable "${ruleName}".`, {
                    condition,
                    mismatch: `${actualValue} !== ${expectedValue}`,
                    [checkedField]: actualValue,
                    [checkedField.replace('__c', '')]: actualValue,
                    checkValue: expectedValue,
                });
                return false;
            }
            return true;
        },
    };

    private isRuleApplicable(
        condition: { field: string; target: any; ruleName: string; criteria: string },
        operator: '!=' | '=='
    ): boolean {
        const ruleDetails = condition.field?.split(operator);
        if (ruleDetails?.length > 1) {
            const fieldName = ruleDetails[0].trim();
            // Utilizzo "get" di lodash perchè "fieldName" può contenere dei path (tipo Apttus_Config2__ProductConfiguration__c.egl_sales_channel) e agevola il recupero delle informazioni negli oggetti innestati
            const actualValue =
                get(condition.target, fieldName) || get(condition.target, fieldName.replace('__c', '')?.trim());
            const expectedValue = trim(trimEnd(replace(ruleDetails[1].trim(), 'TEXT(', ''), ')'), "'");
            return this.OPERATOR_STRATEGY[operator](
                condition.ruleName,
                condition.criteria,
                actualValue,
                expectedValue,
                fieldName
            );
        }
        return false;
    }

    getAttributeRulesForProducts(productList: string[] | Product[], attributes?: any, cart?: any) {
        return this.getCustomAttributeRulesForProducts(productList).pipe(
            map((res) => [res[0], res[1], res[2]]),
            map(([attrRules, attrMetadata, _productList]: [EglProductAttributeRule[], any, EglProductExtended[]]) => {
                attributes = {
                    ...attributes,
                    Apttus_Config2__ProductConfiguration: cart,
                };
                _productList.forEach((product) => {
                    const mergeActionResult = {};
                    (attrRules || []).forEach((attrRule) => {
                        const ruleCriteriaConditions = attrRule.ConditionCriteriaExpression;
                        let areActionsValid = true;
                        const conditions = ruleCriteriaConditions?.split('&&').map((c) => ({
                            field: c
                                .replaceAll(ConditionCriteriaEntity.PAV, '')
                                .replaceAll(ConditionCriteriaEntity.Product, ''),
                            target: c.startsWith(ConditionCriteriaEntity.Product) ? product : attributes,
                            ruleName: attrRule.Name,
                            criteria: c,
                        }));

                        if (!!conditions) {
                            if (conditions?.find((c) => c.field.includes('Apttus_Config2__ProductConfiguration__c.'))) {
                                conditions.forEach((condition, i) => {
                                    if (condition.field.includes('Apttus_Config2__ProductConfiguration__c.')) {
                                        conditions[i].field = condition.field.replaceAll('__c', '');
                                    }
                                });
                            }
                            areActionsValid = conditions
                                ?.map((condition) =>
                                    this.isRuleApplicable(condition, condition.field.includes('==') ? '==' : '!=')
                                )
                                .every(Boolean);
                        }

                        if (areActionsValid) {
                            const ruleActions = attrRule.ProductAttributeRuleActions || [];
                            ruleActions.forEach((ruleAction, index, list) => {
                                const apiName =
                                    ruleAction.Field.indexOf('.') > -1
                                        ? ruleAction.Field.split('.').pop()
                                        : ruleAction.Field;
                                const fieldType = attrMetadata.fields.find((r) => r.name === apiName).type;
                                const isSelectType = fieldType === 'multipicklist' || fieldType === 'picklist';
                                if (ruleAction.Action === 'Allow' && !isSelectType) {
                                    list.splice(index, 1);
                                } else {
                                    if (mergeActionResult.hasOwnProperty(apiName) && mergeActionResult[apiName]) {
                                        const attributeResult = mergeActionResult[apiName];
                                        attributeResult.isHiddenAction = attributeResult.isHiddenAction
                                            ? attributeResult.isHiddenAction
                                            : this.getActionFlag(ruleAction.Action, 'Hidden');
                                        attributeResult.isReadOnlyAction = attributeResult.isReadOnlyAction
                                            ? attributeResult.isReadOnlyAction
                                            : this.getActionFlag(ruleAction.Action, 'Disabled');
                                        attributeResult.isRequiredAction = attributeResult.isRequiredAction
                                            ? attributeResult.isRequiredAction
                                            : this.getActionFlag(ruleAction.Action, 'Required');
                                        if (
                                            this.getActionFlag(ruleAction.Action, 'Allow') &&
                                            !attributeResult.isConstraintAction
                                        ) {
                                            attributeResult.isConstraintAction = true;
                                            attributeResult.values = ruleAction.Values;
                                        }
                                        if (
                                            this.getActionFlag(ruleAction.Action, 'Default') &&
                                            !attributeResult.isDefaultAction
                                        ) {
                                            attributeResult.isDefaultAction = true;
                                            attributeResult.values = ruleAction.Values;
                                        }
                                        if (
                                            this.getActionFlag(ruleAction.Action, 'Reset') &&
                                            !attributeResult.isReset &&
                                            ruleAction.Values.length > 0
                                        ) {
                                            attributeResult.isReset = true;
                                            attributeResult.resetValues = ruleAction.Values;
                                        }
                                    } else {
                                        const attributeResult = {
                                            field: apiName,
                                            isConstraintAction: this.getActionFlag(ruleAction.Action, 'Allow'),
                                            isDefaultAction: this.getActionFlag(ruleAction.Action, 'Default'),
                                            isHiddenAction: this.getActionFlag(ruleAction.Action, 'Hidden'),
                                            isReadOnlyAction: this.getActionFlag(ruleAction.Action, 'Disabled'),
                                            isRequiredAction: this.getActionFlag(ruleAction.Action, 'Required'),
                                            isReset: this.getResetFlag(ruleAction.Action, ruleAction.Values),
                                            values: ruleAction.Values,
                                            defaultValue: SalesforceUtils.getDefaultValue(
                                                attrMetadata.fields.find((field) => field.name === apiName)
                                            ),
                                            resetValues: this.getResetFlag(ruleAction.Action, ruleAction.Values)
                                                ? ruleAction.Values
                                                : [],
                                        };
                                        mergeActionResult[apiName] = attributeResult;
                                    }
                                }
                            });
                        }
                    });
                    set(product, '_metadata.rules', Object.values(mergeActionResult));
                });
                return flatten(_productList.map((r) => r?._metadata?.rules));
            })
        );
    }

    /**
     * Process the ABC constraint rule action, given attribute rule and matrix constraints.
     * @param product instance of product object.
     * @returns Observable array of processed attribute rules for the given product.
     */
    @MemoizeAll((items) => {
        return join(
            items.map((i) => i.Id),
            '_'
        );
    })
    getCustomAttributeRulesForProducts(productList: Array<string> | Array<Product>): Observable<Array<any>> {
        const subject = new BehaviorSubject<Array<any>>(null);
        if (productList) {
            // First query retrieves list of potential rules that the product list is a member of
            productList = (<any>productList).filter((p) => p != null);
            const product$ = (<Array<any>>productList).every((item: any) => typeof (<any>item) === 'string')
                ? this.productService.get(<Array<string>>productList)
                : of(<Array<Product>>productList);
            product$
                .pipe(
                    mergeMap((_productList: Array<Product>) => {
                        const familyList = _productList.map((p) => p.Family).filter((r) => r != null);
                        const groupList = flatten(_productList.map((p) => p.ProductGroups || []))
                            .map((group) => group.ProductGroupId)
                            .filter((x) => x != null);
                        let filters = [];

                        filters = [
                            new AFilter(
                                EglProductAttributeRule,
                                [
                                    new ACondition(EglProductAttributeRule, 'ProductScope', 'In', ['', 'All']),
                                    new ACondition(
                                        EglProductAttributeRule,
                                        'ProductScope',
                                        'Includes',
                                        _productList.map((p) => p.Id)
                                    ),
                                ],
                                null,
                                'OR'
                            ),
                        ];
                        if (familyList?.length) {
                            filters.push(
                                new AFilter(
                                    EglProductAttributeRule,
                                    [
                                        new ACondition(EglProductAttributeRule, 'ProductFamilyScope', 'In', [
                                            '',
                                            'All',
                                        ]),
                                        new ACondition(
                                            EglProductAttributeRule,
                                            'ProductFamilyScope',
                                            'Includes',
                                            familyList
                                        ),
                                    ],
                                    null,
                                    'OR'
                                )
                            );
                        }
                        if (groupList?.length) {
                            filters.push(
                                new AFilter(
                                    EglProductAttributeRule,
                                    [
                                        new ACondition(EglProductAttributeRule, 'ProductGroupScope', 'In', ['', 'All']),
                                        new ACondition(
                                            EglProductAttributeRule,
                                            'ProductGroupScope',
                                            'Includes',
                                            groupList
                                        ),
                                    ],
                                    null,
                                    'OR'
                                )
                            );
                        }

                        return zip(
                            this.where(
                                [new ACondition(this.type, 'Active', 'Equal', true)],
                                'AND',
                                filters,
                                null,
                                new APageInfo(50, 1),
                                null
                            ),
                            this.describe(ProductAttributeValue),
                            of(_productList)
                        );
                    }),
                    take(1)
                )
                .subscribe((results) => subject.next(results));
        }

        return subject.pipe(filter((r) => r != null));
    }
}
