import { FilterCommodityType } from '../../common/enums/apttus/apt-commodity-typeof-sale';
import { AptSalesInadmissibilityProcess, AptSalesProcess } from '../../common/enums/apttus/apt-sales-process';
import { Tabs } from '../../common/enums/shared/tabs';
import { ActionDefinition } from '../../modules/back-office/common/most-used-actions/most-used-actions.component';
import { MassiveActionsFilters } from '../../modules/back-office/models/bo-filters';
import { CarrelliFilterList } from '../../modules/back-office/models/carrelli-filter-list';
import { GestioneConditions } from '../../modules/back-office/models/gestione-filter-list';
import { MonitoraggioConditions } from '../../modules/back-office/models/monitoraggio-filter-list';
import { SelectedBill } from '../../modules/bills-instalment/models/selected-bill';

export class BoState {
    selectedTab: 'MON' | 'CART' | 'GEST';
    monitoraggioFilter: MonitoraggioConditions;
    gestioneFilter: GestioneConditions;
    carrelliFilter: CarrelliFilterList;
    selectedOpMode: AptSalesProcess;
    selectedMacroOpMode: string;
    selectedInamissibilityMode: AptSalesInadmissibilityProcess;
    selectedQuoteId: string;
    selectedCommodity: FilterCommodityType;
    massiveActionsFilters: MassiveActionsFilters;
    selectedMostUsedAction: ActionDefinition;
    billInstalmentSelectedBill: SelectedBill;
    billInstalmentClientAccountCode: string;

    constructor(tab?: Tabs, applyDefault?: boolean) {
        this.selectedTab = tab || 'MON';
        if (applyDefault) {
            this.selectedOpMode = AptSalesProcess.SwitchIn;
            this.selectedMacroOpMode = 'SWITCH IN';
        }
        
        this.selectedInamissibilityMode = AptSalesInadmissibilityProcess.All;
        this.selectedCommodity = FilterCommodityType.All;
        this.monitoraggioFilter = new MonitoraggioConditions(applyDefault);
        this.gestioneFilter = new GestioneConditions(applyDefault);
        this.carrelliFilter = new CarrelliFilterList(applyDefault);
        this.selectedMostUsedAction = new ActionDefinition();
    }
}
