import { Injectable } from '@angular/core';
import { ACondition } from '@congacommerce/core';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { tap, map, mergeMap, catchError } from 'rxjs/operators';
import { Regex } from '../../../common/config/regex';
import {
    address2EgonRequest,
    egonNormalizedResponse2Address,
    reviewAddress,
} from '../../../common/functions/address.functions';
import { apttusResponseIronHand } from '../../../common/functions/misc.functions';
import { getCivicAndSuffix } from '../../../common/functions/remap.functions';
import { istatCodeNormalizer } from '../../../common/functions/string-format.functions';
import {
    AddressTypeMap,
    customerDataToD365Account,
    D365AccountContactData,
    d365Asset2Product,
    eglAssetLineItem2AssetAccountData,
    eglAssetLineItem2Product,
    getAccountPrimaryContact,
} from '../../../common/functions/transformation.functions';
import { DeepPartial } from '../../../common/interfaces/deep-partial';
import { PaymentToolsStatus } from '../../../common/models/app/payment-tools.response';
import { EglAssetLineItem } from '../../../common/models/apttus/tables/user/egl-asset-line-item';
import { D365Account, D365AddressRoleType, D365Asset } from '../../../common/models/d365/d365-asset.interface';
import { CommonProvider } from '../../../common/providers/common-provider';
import { EgonProvider } from '../../../common/providers/egon-provider';
import { EglAssetLineItemService } from '../../../common/services/apttus/tables/asset/egl-assetline-item.service';
import { D365Service } from '../../../common/services/d365/d365.service';
import { Address, Product, StateAddress } from '../../../store/models/order-entry-state_v2';
import { LoggerService } from '../../../common/services/shared/logger.service';
import { DomiciliationProvider } from '../../domiciliation/providers/domiciliation.provider';

@Injectable()
export class DataLoaderService {
    constructor(
        private d365Srv: D365Service,
        private egonProvider: EgonProvider,
        private assetLineItemSrv: EglAssetLineItemService,
        private commonPrv: CommonProvider,
        private domiciliationSrv: DomiciliationProvider,
        private logger: LoggerService
    ) {}

    private D365AccountAddress2Address(
        d365AccountAddress: D365Account['addresses'][D365AddressRoleType]
    ): Partial<StateAddress> {
        return {
            d365Id: d365AccountAddress?.egl_code,
            toponym: d365AccountAddress?.toponym,
            street: d365AccountAddress?.egl_street,
            ...getCivicAndSuffix(d365AccountAddress?.egl_streetnumber),
            municipality: d365AccountAddress?.egl_city,
            shortProvince: d365AccountAddress?.egl_province,
            country: d365AccountAddress?.egl_country,
            istatCodeMunicipality: (istatCodeNormalizer(d365AccountAddress?.egl_istatcode, 6) || '').substring(3),
            istatCodeProv: (istatCodeNormalizer(d365AccountAddress?.egl_istatcode, 6) || '').substring(0, 3),
            cap: d365AccountAddress?.egl_zipcode,
        };
    }

    getAccountContact(accountNumber: string): Observable<D365AccountContactData> {
        return this.commonPrv.getSalesUpClientData('CODCLIENTE', accountNumber, 'O', false, false).pipe(
            map((res) => res?.response),
            map(({ datiCliente, master }) => customerDataToD365Account({ ...datiCliente.anagrafica, master })),
            // Recupero il contatto primario
            map(({ addresses, contacts, ...account }) => ({
                ...account,
                contact: getAccountPrimaryContact({ ...account, contacts }),
                // Trasformo gli indirizzi in Address
                addresses: addresses
                    ? Object.entries(addresses).reduce(
                          (aggr, [addressType, address]) => ({
                              ...aggr,
                              [addressType]: this.D365AccountAddress2Address(address),
                          }),
                          {} as AddressTypeMap
                      )
                    : {},
            })),
            mergeMap(({ addresses, ...accountContact }) =>
                !Object.keys(addresses).length
                    ? of({
                          ...accountContact,
                          addresses,
                      })
                    : combineLatest(
                          Object.entries(addresses).map(([addressType, { d365Id, ...address }]) =>
                              this.normalizeAddress(address).pipe(
                                  map((normalizedAddress) => ({
                                      // Restituisco un oggetto con chiave/valore -> tipo indirizzo / indirizzo + code
                                      [addressType]: {
                                          ...normalizedAddress,
                                          d365Id,
                                      },
                                  }))
                              )
                          )
                      ).pipe(
                          // Raggruppo gli indirizzi per tipo
                          map((results) =>
                              results.reduce((aggr, addrMap) => ({ ...aggr, ...addrMap }), {} as AddressTypeMap)
                          ),
                          // Resituisco i dati con gli indirizzi raggruppati
                          map((normalizedAddressMap) => ({
                              ...accountContact,
                              addresses: normalizedAddressMap,
                          }))
                      )
            )
        );
    }

    getAssets(assetIds: string[]): Observable<{ products: DeepPartial<Product>[]; accountNumber: string }> {
        // Recupero le informazioni degli asset
        return combineLatest([this.retrieveSalesForceAssets(assetIds), this.retrieveD365Assets(assetIds)]).pipe(
            // Unisco le risposte
            map((results) => results.reduce((aggr, result) => [...aggr, ...result], [])),

            // controllo se i prodotti ricevuti appartengono a clienti differenti
            tap((productsAndAccountNumbers) => {
                const differentAccount =
                    new Set(
                        productsAndAccountNumbers
                            .filter(({ accountNumber }) => (accountNumber || '').trim())
                            .map(({ accountNumber }) => accountNumber.toLowerCase().trim())
                    ).size > 1;
                if (differentAccount) {
                    throw new Error(`It's not possible to proceed having assets by different customers`);
                }
            }),
            //Estraggo l'accountNumber
            map((productsAndAccountNumbers) => ({
                products: productsAndAccountNumbers.map(({ accountNumber, ...product }) => product as Product),
                accountNumber: productsAndAccountNumbers.find(({ accountNumber }) => accountNumber)?.accountNumber,
            })),
            // Arricchisco i dati prodotto e cliente
            mergeMap(({ products, accountNumber }) =>
                //Normalizzo con egon e arricchisco gli indirizzi con l'id d365
                combineLatest(
                    products.map((product) =>
                        forkJoin(
                            this.normalizeAddress(product?.deliveryAddress).pipe(
                                map((deliveryAddress) => ({
                                    ...product,
                                    deliveryAddress,
                                }))
                            ),
                            product?.paymentInfo?.paymentTool?.billingPreferenceCode
                                ? this.domiciliationSrv
                                      .getPaymentTools(
                                          accountNumber,
                                          product?.paymentInfo?.paymentTool?.billingPreferenceCode
                                      )
                                      .pipe(
                                          map((paymentTools) =>
                                              paymentTools.find(
                                                  (paymentTool) => paymentTool?.stato === PaymentToolsStatus.Attivo
                                              )
                                          ),
                                          tap((paymentTool) => {
                                              if (!paymentTool) {
                                                  throw new Error('Nessun metodo di pagamento attivo trovato');
                                              }
                                          }),
                                          map((paymentTool) => ({
                                              paymentInfo: {
                                                  paymentTool: {
                                                      id: paymentTool?.id,
                                                      iban: paymentTool?.iban,
                                                      billingPreferenceCode:
                                                          paymentTool?.billingPreference?.[0]?.billingPreferenceCode ||
                                                          product?.paymentInfo?.paymentTool?.billingPreferenceCode,
                                                      holder: {
                                                          vatCode: paymentTool?.holder?.fullname
                                                              ? paymentTool?.holder?.fiscalcode
                                                              : null,
                                                          companyName: paymentTool?.holder?.fullname,
                                                          fiscalCode: paymentTool?.holder?.fiscalcode,
                                                          firstName: paymentTool?.holder?.firstname,
                                                          lastName: paymentTool?.holder?.lastname,
                                                          fullName: paymentTool?.holder?.fullname,
                                                      },
                                                      sepaSubscriber: {
                                                          fiscalCode: paymentTool?.sepaSubscriber?.taxcode,
                                                          firstName: paymentTool?.sepaSubscriber?.firstname,
                                                          lastName: paymentTool?.sepaSubscriber?.lastname,
                                                      },
                                                  },
                                              },
                                          })),
                                          catchError((err: Error) => {
                                              this.logger.warn(err?.message, false);
                                              return of({});
                                          })
                                      )
                                : of({})
                        ).pipe(map((productParts) => Object.assign({}, ...productParts)))
                    )
                ).pipe(map((enrichedProducts) => ({ products: enrichedProducts, accountNumber })))
            )
        );
    }

    public normalizeAddress(
        srcAddress: Partial<StateAddress & { externalId: string }>,
        ignoreError: boolean = false
    ): Observable<StateAddress> {
        const hasNotMainFields =
            !srcAddress?.street || (!srcAddress?.civic && !srcAddress?.civicSuffix) || !srcAddress?.municipality;
        const isEmptyOrBrokenAddress = hasNotMainFields && !srcAddress?.fullAddress;
        const reviewReadyAddress =
            hasNotMainFields && !!srcAddress?.fullAddress
                ? (srcAddress as Address)
                : reviewAddress(srcAddress as Address);

        return combineLatest([
            // Normalizzazione Egon
            reviewReadyAddress?.streetEgonCode || isEmptyOrBrokenAddress
                ? of(srcAddress as Address)
                : this.egonProvider.egonNormalize(address2EgonRequest(reviewReadyAddress), ignoreError).pipe(
                      map((response) => egonNormalizedResponse2Address(response)),
                      catchError(() => of({ ...srcAddress, certified: false } as Address))
                  ),
            // Recupero Id D365
            srcAddress?.d365Id || !srcAddress?.externalId
                ? of(srcAddress?.d365Id)
                : this.d365Srv.getD365AddressCode(srcAddress?.externalId),
        ]).pipe(
            map(([address, d365Id]) => ({
                ...address,
                d365Id,
            }))
        );
    }

    private retrieveSalesForceAssets(
        assetIds: string[]
    ): Observable<(Product & { accountNumber: string; deliveryAddress: Address & { externalId?: string } })[]> {
        // Filtro la lista degli id tenendo solo quelli di CPQ e utilizzo set per rimuovere duplicati e ritrasformo tutto in array
        const sFAssetIds = Array.from(
            new Set((assetIds || []).filter((assetId) => Regex.SALESFORCE_ID_MATCHER.test(assetId)))
        );
        // Se la lista di id è vuota restituisco oggetto base altrimenti faccio la chiamata di recupero asset
        return !sFAssetIds.length
            ? of([])
            : this.assetLineItemSrv.where([new ACondition(EglAssetLineItem, 'Id', 'In', assetIds)]).pipe(
                  tap((assetLineItemList: EglAssetLineItem[]) => {
                      if (assetLineItemList?.length !== assetIds?.length) {
                          throw new Error(`Impossibile recuperare le informazioni per asset Salesforce: ${assetIds}`);
                      }
                  }),
                  // Rettifico i nomi dei campi rimuovendo underscore e __c alla fine dei nomi
                  map((eglAssetLineItems) => apttusResponseIronHand(eglAssetLineItems, ['AttributeValue'])),
                  // Restituisco la lista degli asset convertiti in product e le informazioni dell'account
                  map((eglAssetLineItems) =>
                      eglAssetLineItems.map((lineItem) => {
                          const { accountNumber } = eglAssetLineItem2AssetAccountData(lineItem);
                          const product: Product = eglAssetLineItem2Product(lineItem) as Product;
                          return {
                              ...product,
                              deliveryAddress: {
                                  ...(product?.deliveryAddress || ({} as Address)),
                                  externalId: lineItem?.egl_service_address?.egl_externalid,
                              },
                              accountNumber,
                          };
                      })
                  )
              );
    }

    private retrieveD365Assets(assetIds: string[]): Observable<(Product & { accountNumber: string })[]> {
        // Filtro la lista degli id tenendo solo quelli di D365 e utilizzo set per rimuovere duplicati e ritrasformo tutto in array
        const d365AssetIds = Array.from(
            new Set((assetIds || []).filter((assetId) => Regex.D365_GUID_MATCHER.test(assetId)))
        );
        // Se la lista di id è vuota restituisco oggetto base altrimenti faccio la chiamata di recupero asset
        return !d365AssetIds.length
            ? of([])
            : this.d365Srv.getD365AssetXML(d365AssetIds).pipe(
                  tap((d365Assets: D365Asset[]) => {
                      if (d365Assets?.length !== d365AssetIds?.length) {
                          throw new Error(`Impossibile recuperare le informazioni per asset D365:  ${d365AssetIds} `);
                      }
                  }),
                  // Restituisco la lista degli asset convertiti in product e le informazioni dell'account
                  map((d365Assets) =>
                      d365Assets.map((lineItem) => ({
                          ...d365Asset2Product(lineItem),
                          accountNumber: lineItem?.account?.egl_customercode,
                      }))
                  )
              );
    }
}
