<ng-container>
    <div class="navbar" *ngIf="show">
        <div class="d-flex w-100 justify-content-between">
            <!--GOTO BACK-->
            <div class="egl-header_left-btn-container" (click)="goBack()" [hidden]="!showBack">
                <egl-icon [path]="'medium-light-left'" [width]="15" [height]="14"></egl-icon>
            </div>

            <!-- SURVEY ICON -->
            <div class="egl-header_left-btn-container" *ngIf="isSurveyAvailable" (click)="openSurveyModal()">
                <egl-icon [path]="'survey'" [width]="32" [height]="32" class="egl-header_survey-icon"></egl-icon>
                {{ 'SURVEY.GENERAL_TITLE' | translate }}
            </div>

            <!-- UPDATE ICON -->
            <egl-header-button
                *ngIf="isHeaderOmnichannelButtonVisible"
                (click)="updateWidget()"
                icon="restart"
                [label]="'DASHBOARD.BUTTON.UPDATE_WIDGET' | translate"
            >
            </egl-header-button>

            <!-- DASHBOARDS ICON -->
            <egl-header-button
                *ngIf="isHeaderOmnichannelButtonVisible"
                (click)="navigateTo('DASH')"
                icon="stats-performance"
                [label]="'DASHBOARD.BUTTON.DASHBOARDS' | translate"
            ></egl-header-button>

            <!-- SIEBEL ICON -->
            <egl-header-button
                *ngIf="isHeaderOmnichannelButtonVisible"
                (click)="navigateTo('SIEBEL')"
                icon="hierarchy-organizations"
                [label]="'DASHBOARD.BUTTON.SIEBEL' | translate"
            >
            </egl-header-button>

            <!-- INSERISCI LEAD -->
            <egl-header-button
                *ngIf="canCreateLeadFromHomePage"
                (click)="navigateToCreateLead()"
                icon="icon-lead"
                [label]="'DASHBOARD.BUTTON.CREATE_LEAD' | translate"
            >
            </egl-header-button>

            <egl-header-button
                *ngIf="apprendiEnabled"
                (click)="openAPPrendi()"
                [label]="'DASHBOARD.BUTTON.APPRENDI' | translate"
            >
            </egl-header-button>

            <!--DROPDOWN SWI-->
            <ng-container
                ><egl-header-dropdown
                    *ngFor="let item of headersElements"
                    [lov]="getAsAny(item.elements)"
                    [placeholder]="item.placeholder"
                    (select)="onDropdownSelect($event)"
                    (discard)="onDiscardSelect($event)"
                ></egl-header-dropdown
            ></ng-container>

            <!--LOGO ENI-->
            <div class="brand">
                <img *ngIf="showLogo" src="../../../../../assets/images/logo2@2x.png" alt="" />
            </div>

            <div class="w-25">
                <div class="user-and-cart">
                    <!-- USER PROFILE -->
                    <div class="user-info-container">
                        <egl-user-info class="float-right"></egl-user-info>
                    </div>

                    <!-- CART -->
                    <div class="mini-cart-container" *ngIf="showMiniCart">
                        <egl-mini-cart></egl-mini-cart>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <egl-contact-bar></egl-contact-bar>
    <egl-wyvern-bar [routeData]="routePageProgress$ | async"></egl-wyvern-bar>

    <egl-order-entry-title
        class="egl-header__egl-order-entry-title"
        [title]="l10nRoot$ | async | flowTranslate : 'TITLE'"
        [description]="l10nRoot$ | async | flowTranslate : 'SUBTITLE'"
    ></egl-order-entry-title>
</ng-container>

<egl-modal-with-footer
    #confirmModal
    (onConfirm)="modalConfirm?.onConfirmFunction()"
    [title]="modalConfirm?.title"
    [buttonLabel]="modalConfirm?.confirmBtn"
    [closeOnConfirm]="true"
>
    <p [innerHTML]="modalConfirm?.message"></p>
</egl-modal-with-footer>
