export enum AptProductFamily {
    Commodity = 'Commodity',
    CommodityLegacy = 'Commodity Legacy',
    CommodityLegacyFull = 'Commodity Legacy Full',
    BeniFisici = 'Beni Fisici senza installazione',
    ServizioTecnico = 'Servizi con tecnico',
    VariazioneTecnica = 'Variazione Tecnica',
    Assicurazione = 'Assicurazione',
    Options = 'Options',
}
