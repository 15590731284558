export const appUserString = {
    SET_USER_STATE: '[USER] set-user-state',
    SET_CONTACT: '[USER] set-contact',
    SET_CONTACT_EMAIL: '[USER] set-contact-email',
    SET_LEAD: '[USER] set-lead',
    SET_AGENT_INFO: '[USER] set-agent-info',
    SET_CUSTOMER_INFO: '[USER] set-customer-info',
    SET_SALEABILITY_JSON_CACHE: '[SALEABILITY] set-saleability-json-cache',
    SET_HISTORIZED_AGENCY_BRANCH: '[USER] set-historized-agency-branch',
    RESET_USER_STATE: '[USER] reset-user-state',
    SET_LAST_USED_CUSTOMER_SEGMENT: '[USER] set-last-used-customer-segment',
    SET_CART_SEGMENT: '[USER] set-cart-segment',
    SET_VIRTUAL_AGENT_CURRENT_AGENCY_BRANCH: '[USER] set-virtual-agent-current-agency-branch',
    SET_CUSTOMER_MASTERSHIP: '[USER] set-customer-mastership',
    SET_CUSTOMER_AND_CONTACT: '[USER] set-customer-and-contact-mastership',
    SET_DATA_OWNERSHIP_CHANGE_STATE: '[USER] set-data-ownership-change-state',
    SET_DATA_OWNERSHIP_CHANGE_DATA: '[USER] set-data-ownership-change-data',
};

export const appActionString = {
    SET_APP_CONFIG: '[APP] set-app-config',
    SET_CONTACT_BAR_VISIBILITY: '[APP] set-contact-bar-visibility',
    SET_PROGRESS_BAR_VISIBILITY: '[APP] set-progress-bar-visibility',
    SET_DEV_BAR_VISIBILITY: '[APP] set-dev-bar-visibility',
    CONNECTION_CHANGED: '[APP] connection-changed',
    CONNECTION_SPEED: '[APP] connection-speed',
    APPLICATION_LOCATION: '[APP] application-location',
    SET_CURRENT_PAGE: '[APP] set-current-page',
    SET_URL_HASH: '[APP] set-url-hash',
    SET_GEO_LOCATION: '[APP] set-geo-location',
    SET_BO_QUOTE_TAB: '[APP] set-bo-quote-tab',
    SET_DEVICE_BY_U_AGENT: '[APP] set-device',
    SET_D365_ENTRY_POINT: '[APP] set-d365-entry-point',
    SET_D365_APP_URL: '[APP] set-d365-app-url',
    SET_SHOULD_OPEN_TROUBLESHOOTING: '[APP] set-should-open-troubleshooting',
};

export const orderEntryActionString = {
    SET_ORDER_ENTRY_STATE: '[ORDER_ENTRY] set-order-entry-state',
    SET_CART_INFO: '[ORDER_ENTRY] set-cart-info',
    SET_CART_ID: '[ORDER_ENTRY] set-cart-id',
    SET_DATI_FORNITURA: '[ORDER_ENTRY] set-dati-fornitura',
    SET_CREDIT_CHECK_RESULT: '[ORDER_ENTRY] set-credit-check-result',
    SET_POD: '[ORDER_ENTRY] set-pod',
    SET_PDR: '[ORDER_ENTRY] set-pdr',
    SET_POWER_CONSUMPTION: '[ORDER_ENTRY] set-power-consumption',
    SET_POWER_HOME_DROPDOWN_OPTION: '[ORDER_ENTRY] set-power-home-dropdown-option',
    SET_GAS_CONSUMPTION: '[ORDER_ENTRY] set-gas-consumption',
    SET_GAS_METER_READING: '[ORDER_ENTRY] set-gas-meter-reading',
    SET_GAS_HOME_DROPDOWN_OPTION: '[ORDER_ENTRY] set-gas-home-dropdown-option',
    SET_TITOLARITA_IMMOBILE: '[ORDER_ENTRY] set-titolarita-immobile',
    SET_DATI_PAGAMENTO: '[ORDER_ENTRY] set-dati-pagamento',
    SET_FOTO_DOCUMENTI: '[ORDER_ENTRY] set-foto-documenti',
    SET_FIRMA: '[ORDER_ENTRY] set-firma',
    SET_MP3_INFO: '[ORDER_ENTRY] set-mp3-info',
    SET_PDF_PLICO_INFO: '[ORDER_ENTRY] set-pdf-plico-info',
    SET_PRODUCT_DATA: '[ORDER_ENTRY] set-product-data',
    SET_NUMERO_PLICO: '[ORDER_ENTRY] set-numero-plico',
    SET_PASSAGGIO_RAPIDO: '[ORDER_ENTRY] set-passaggio-rapido',
    SET_EXISTING_SELECTED_SUPPLIES: '[ORDER_ENTRY] set-selected-existents-supplies',
    SET_EXISTING_SUPPLIES: '[ORDER_ENTRY] set-existing-supplies',
    SET_PRIVACY_TRAT_PERS: '[ORDER_ENTRY] set-privacy-tratt-person',
    SET_QUOTE_STATE_MODEL: '[ORDER_ENTRY] set-quote-state-model',
    SET_VERIFICA_CONTATTO: '[ORDER_ENTRY] set-verifica-contatto',
    RESET_ORDER_ENTRY: '[ORDER_ENTRY] reset-order-entry',
    SET_AGENT_BRANCH: '[ORDER_ENTRY] set-agent-branch',
    SET_MARKED_AS_FRAUD: '[ORDER_ENTRY] set-marked-ad-fraud',
    INIT_SALES: '[ORDER_ENTRY] init-sales',
    INIT_SIGN: '[ORDER_ENTRY] init-sign',
    SET_SKIP_POD: '[ORDER_ENTRY] set-skip-pod',
    SET_SKIP_PDR: '[ORDER_ENTRY] set-skip-pdr',
    SET_SKIP_ATECO: '[ORDER_ENTRY] set-skip-ateco',
    SET_CONTRACT_REQ: '[ORDER_ENTRY] set-contract-request',
    SET_WINBACK: '[ORDER_ENTRY] set-winback',
    SET_WINBACKTYPE: '[ORDER_ENTRY] set-winback-type',
    SET_PLICO_URL: '[ORDER_ENTRY] set-plico-url',
    SET_ANAGRAFICA_MB: '[ORDER_ENTRY] set-anagrafica-mb',
    SET_SEGMENT_IDS: '[ORDER_ENTRY] set-segment-ids',
    SET_OCR_DATA: '[ORDER_ENTRY] set-ocr-data',
    SET_ATECO_MB: '[ORDER_ENTRY] set-ateco-mb',
    SET_POTENZA_IMPEGNATA_MB: '[ORDER_ENTRY] set-potenza-impegnata-mb',
    SET_CART_SEGMENT: '[ORDER_ENTRY] set-cart-segment',
    SET_FATT_ELETT: '[ORDER_ENTRY] set-fatturazione-elettr',
    SET_TROUBLESHOOTING_POWER_DONE: '[ORDER_ENTRY] set-troubleshooting-power-done',
    SET_TROUBLESHOOTING_GAS_DONE: '[ORDER_ENTRY] set-troubleshooting-gas-done',
    SET_IMPERSONATED_AGENT: '[ORDER_ENTRY] set-impersonated-agent',
    SET_INVALID_CF: '[ORDER_ENTRY] set-invalid-cf',
    SET_MODAL_CREDITCHECK: '[ORDER_ENTRY] set-modal-creditcheck',
    SET_FLOW_TYPE: '[ORDER_ENTRY] set-flow-type',
    SET_TYPOLOGIES_OF_USE: '[ORDER_ENTRY] set-typologies-of-use',
    SET_SALES_PROCESS: '[ORDER_ENTRY] set-sales-process',
    SET_OPERATION_TYPE: '[ORDER_ENTRY] set-operation-type',
    SET_ASSET_ID: '[ORDER_ENTRY] set-asset-id',
    SET_NUMERO_DOMICILIAZIONE: '[ORDER_ENTRY] set-numero-domiciliazione',
    SET_CREDIT_CHECK_STATUS: '[ORDER_ENTRY] set-credit-check-status',
    SET_INSOLUTO_NDS: '[ORDER_ENTRY] set-insoluto-nds',
    SET_TAX_VAT_STATUS: '[ORDER_ENTRY] set-tax-vat-status',
    SET_SCORE_CARD_STATUS: '[ORDER_ENTRY] set-score-card-status',
    SET_PAYMENT_SCORE: '[ORDER_ENTRY] set-payment-score',
    SET_CEASE_REASON_CODE: '[ORDER_ENTRY] set-cease-reason-code',
    SET_IBAN_INSOLUTO_NDS: '[ORDER_ENTRY] set-iban-insoluto-nds',
    SET_DEFERRED_SALE_STATUS: '[ORDER_ENTRY] set-deferred-sale-status',
    SET_IS_MORTIS_CAUSA: '[ORDER_ENTRY] set-is-mortis-causa',
    SET_TERMINATION_CART_ID: '[ORDER_ENTRY] set-termination-cart-id',
    SET_TERMINATION_DATE: '[ORDER_ENTRY] set-termination-date',
    SET_SELF_CERTIFICATION: '[ORDER_ENTRY] set-self-certification',
    SET_TERMINATION_TERM_CONTACTS: '[ORDER_ENTRY] set-termination-term-contacts',
    SET_TERMINATION_APPOINTMENT: '[ORDER_ENTRY] set-termination-appointment',
    SET_TERMINATION_COSTS: '[ORDER_ENTRY] set-termination-costs',
    SET_TERMINATION_SEND_COMMUNICATIONS: '[ORDER-ENTRY] set-termination-send-communications',
    SET_AGENCY_BRANCH_FOR_MONITORING: '[ORDER_ENTRY] set-agency-branch-for-monitoring',
    SET_IVASS_ANSWERS: '[ORDER_ENTRY] set-ivass-answers',
    SET_INSURANCE_DUMMY_PRODUCT_ID: '[ORDER_ENTRY] set-insurance-prod-id',
    SET_ADMINISTRATIVE_TECHNICAL_DATA: '[ORDER_ENTRY] set-administration-technical-data',
    SET_POD_ACTIVATION_INFO: '[ORDER_ENTRY] set-pod-activation-info',
    SET_POTENCY_ACTIVATION: '[ORDER_ENTRY] set-potency-activation',
    SET_PDR_ACTIVATION_INFO: '[ORDER_ENTRY] set-pdr-activation-info',
    SET_QUOTE_ID: '[ORDER_ENTRY] set-quote-id',
    SET_COSTS: '[ORDER_ENTRY] set-costs',
    SET_SKIP_COSTS: '[ORDER-ENTRY] set-skip-costs',
    SET_PROPERTY_TYPE_SELECTED: '[ORDER_ENTRY] set-property-type-selected',
    SET_LINKED_COMMODITY_QUOTE_ID: '[ORDER_ENTRY] set-linked-commodity-quote-id',
    SET_COMBINED_SALE: '[ORDER_ENTRY] set-combined-sale',
    SET_COMMODITY_CART_ID: '[ORDER_ENTRY] set-commodity-cart-id',
    SET_INCIDENT: '[ORDER_ENTRY] set-incident',
    SET_TYPE_OF_USE: '[ORDER_ENTRY] set-type-of-use',
    SET_CHECK_BALANCE: '[ORDER_ENTRY] set-check-balance',
};

export const boActionString = {
    SET_MON_FILTER_BAR: '[BO] set-monit-filter-bar',
    SET_MON_ORD_FILTER_BAR: '[BO] set-mon-ord-filter-bar',
    SET_GEST_FILTER_BAR: '[BO] set-gest-filter-bar',
    SET_SELECTED_TAB: '[BO] set-selected-tab',
    SET_CART_FILTER_BAR: '[BO] set-cart-filter-bar',
    SET_SELECTED_OP_MODE: '[BO] set-selected-op-mode',
    SET_SELECTED_MACRO_OP_MODE: '[BO] set-selected-macro-op-mode',
    SET_SELECTED_MOST_USED_ACTION: '[BO] set-selected-most-used-action',
    SET_SELECTED_INADMIS_MODE: '[BO] set-selected-inadmis-mode',
    SET_COMMODITY_TYPE: '[BO] set-commodity-type',
    SET_AGENT_FILTER: '[BO] set-agent-filter',
    SET_CHANNEL_FILTER: '[BO] set-channel-filter',
    SET_VIRTUALAGENCY_FILTER: '[BO] set-virtual-agency-filter',
    SET_AGENCY_BRANCH_FILTER: '[BO] set-agency-branch-filter',
    RESET_MON_FILTER_BAR: '[BO] reset-monit-filter-bar',
    RESET_GEST_FILTER_BAR: '[BO] reset-gest-filter-bar',
    RESET_SEARCH_FILTERS: '[BO] reset-search-filters',
    SET_DEFAULT_SEARCH_FILTERS: '[BO] set-default-search-filters',
    SET_MON_FILTERS_OP_MODE: '[BO] set-monit-filters-and-op-mode',
    SET_MON_FILTERS_OP_MODE_INADMISS_MODE: '[BO] set-monit-filters-and-op-mode-and-inadmiss-mode',
    SET_BO_QUOTE_ID: '[BO] set-bo-quote-id',
    SET_ASSET_REPORT_SEARCH: '[BO] set-asset-report-search',
    SET_ASSET_REPORT_COLUMNS: '[BO] set-asset-report-columns',
    SET_MASSIVE_ACTIONS_FILTERS: '[BO] set-massive-actions-filters',
    SET_BILL_INSTALMENT_SELECTED_BILL: '[BO] set-bill-instalment-selected-bill',
    SET_BILL_INSTALMENT_TABLE: '[BO] set-bill-instalment-table',
    SET_BILL_INSTALMENT_CLIENT_CODE: '[BO] set-bill-instalment-client-code',
};

export const partnershipActionString = {
    SET_FASTWEB_TAX_VAT_CODE: '[PARTNERSHIP] set-fastweb-tax-vat-code',
    SET_FASTWEB_CONTRACT_INFO: '[PARTNERSHIP] set-fastweb-contract-info',
    SET_EGL_PARTNERSHIP_DATA: '[PARTNERSHIP] set-egl-partnership-data',
};

export const orderEntryV2ActionString = {
    SET_V2_CUSTOMER_REGISTERED: '[ORDER_ENTRY_V2] set-v2-customer-registered',
    SET_V2_EMAIL_CONFERMED: '[ORDER_ENTRY_V2] set-v2-email-confirmed',
    SET_V2_PRODUCT_INSURANCE: '[ORDER_ENTRY_V2] set-v2-product-insurance',
    SET_V2_SEND_COMMUNICATIONS: '[ORDER_ENTRY_V2] set-v2-send-communications',
    SET_V2_ANAGRAFICA_MB: '[ORDER_ENTRY_V2] set-v2-anagrafica-mb',
    SET_V2_BUSINESS_DETAILS: '[ORDER_ENTRY_V2] set-v2-business-details',
    SET_V2_CART_ID: '[ORDER_ENTRY_V2] set-v2-cart-id',
    SET_V2_COMMUNICATION_ADDRESS: '[ORDER_ENTRY_V2] set-v2-communication-address',
    SET_V2_DOMICILE_ADDRESS: '[ORDER_ENTRY_V2] set-v2-domicile-address',
    SET_V2_SUBSIDIARY_ADDRESS: '[ORDER_ENTRY_V2] set-v2-subsidiary-address',
    SET_V2_IS_DWELLING_TYPE_USUAL_HOME: '[ORDER_ENTRY_V2] set-v2-is-dwelling-type-usual-home',
    SET_V2_IS_DWELLING_TYPE_PROPERTY_HOME: '[ORDER_ENTRY_V2] set-v2-is-dwelling-type-property-home',
    SET_V2_CONVENTION: '[ORDER_ENTRY_V2] set-v2-convention',
    SET_V2_TAX_DEDUCTION: '[ORDER_ENTRY_V2] set-v2-tax-deduction',
    SET_V2_CREDIT_ASSIGNMENT: '[ORDER_ENTRY_V2] set-v2-credit-assignment',
    SET_V2_PRODUCT_TAX_DEDUCTION: '[ORDER_ENTRY_V2] set-v2-product-tax-deduction',
    SET_V2_DELIVERY_ADDRESS: '[ORDER_ENTRY_V2] set-v2-delivery-address',
    SET_V2_ESE: '[ORDER_ENTRY_V2] set-v2-ese',
    SET_V2_SUPPLY_USE: '[ORDER_ENTRY_V2] set-v2-supplyUse',
    SET_V2_EFFECTIVE_DATE: '[ORDER_ENTRY_V2] set-v2-effective-date',
    SET_V2_EFFECTIVE_DATE_COMPLEX: '[ORDER_ENTRY_V2] set-v2-effective-date-complex',
    SET_V2_ACTIVATION_DATE: '[ORDER_ENTRY_V2] set-v2-activation-date',
    SET_V2_ELECTRONIC_INVOICING: '[ORDER_ENTRY_V2] set-v2-electronic-invoicing',
    SET_V2_INFORMATION_COSTS: '[ORDER_ENTRY_V2] set-v2-information-costs',
    SET_V2_INVOICE_SHIPPING_METHOD: '[ORDER_ENTRY_V2] set-v2-invoice-shipping-method',
    SET_V2_MAIN_ADDRESS: '[ORDER_ENTRY_V2] set-v2-main-address',
    SET_V2_CUSTOMER_CODE: '[ORDER_ENTRY_V2] set-v2-customer-code',
    SET_V2_ORDER_ENTRY_STATE: '[ORDER_ENTRY_V2] set-v2-order-entry-state',
    SET_V2_PART_NUMBER: '[ORDER_ENTRY_V2] set-v2-part-number-id',
    SET_V2_PDF: '[ORDER_ENTRY_V2] set-v2-pdf',
    SET_V2_PRICES: '[ORDER_ENTRY_V2] set-v2-prices',
    SET_V2_PRODUCT_FILLED: '[ORDER_ENTRY_V2] set-v2-product-filled',
    SET_V2_PRODUCT_LINE_ITEM_ID: '[ORDER_ENTRY_V2] set-v2-product-line-item-id',
    SET_V2_PRODUCTS_WIN_BACK: '[ORDER_ENTRY_V2] set-v2-products-win-back',
    SET_V2_PRODUCTS: '[ORDER_ENTRY_V2] set-v2-products',
    SET_V2_REVOCATION_REASON: '[ORDER_ENTRY_V2] set-v2-revocation-reason',
    SET_V2_SKIP_COSTS: '[ORDER_ENTRY_V2] set-v2-skip-costs',
    SET_V2_TECHNICAL_DETAILS_GAS: '[ORDER_ENTRY_V2] set-v2-technical-details-gas',
    SET_V2_TECHNICAL_DETAILS_POWER: '[ORDER_ENTRY_V2] set-v2-technical-details-power',
    SET_V2_TYPE_OF_USAGE: '[ORDER_ENTRY_V2] set-v2-type-of-usage',
    SET_V2_VENDOR: '[ORDER_ENTRY_V2] set-v2-vendor',
    SET_SEND_COMMUNICATIONS: '[ORDER_ENTRY_V2] set-v2-send-communications',
    SET_V2_REMOTE_READING_STATUS: '[ORDER_ENTRY] set-v2-remote-reading-status',
    SET_V2_PRODUCT_BY_IDX: '[ORDER_ENTRY_V2] set-v2-product-by-idx',
    SET_V2_TECHNICAL_APPOINTMENT_DATE: '[ORDER_ENTRY_V2] set_V2_technical_appointment_date',
    SET_V2_SHIPPING_ADDRESS_ALL_SMARTHOME: '[ORDER_ENTRY_V2] set_V2_shipping_address_all_smarthome',
    SET_V2_IS_INSTANT_ACCEPTANCE: '[ORDER_ENTRY_V2] set_V2_is_instant_acceptance',
    SET_V2_DATI_MULTI_PAYMENT: '[ORDER_ENTRY] set-dati-multi-payment',
    SET_V2_FIRST_IBAN: '[ORDER_ENTRY] set-v2-first-iban',
    SET_V2_BLOCK_DATA_EDITING_ON_DOI_CHECKDOI_RESEND:
        '[ORDER_ENTRY_V2] set_V2_block_data_editing_on_doi_checkdoi_resend',
    SET_V2_SKP_AUTOFILL_ID_LIST: '[ORDER_ENTRY] set-v2-skp-autofill-id-list',
    SET_V2_CHECK_AUTOFILL: '[ORDER_ENTRY] set-v2-check-autofill', 
    SET_CASA_LAVORO_CART_ID: '[ORDER_ENTRY] set-casa-lavoro-cart-id',
    SET_CASA_LAVORO_CART_FILTER: '[ORDER_ENTRY] set-casa-lavoro-cart-filter', 
    SET_V2_SALEABILITY_ZIP: '[ORDER_ENTRY_V2] set-v2-saleability-zip', 
};
