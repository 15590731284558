/**
 * @author Marco Ricupero ft Andreea Stegariu, Felice Lombardi
 * @version 1.7
 */
import { Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { AptPartnerships } from '../../../../modules/common/partnership/enums/apt-partnerships';
import { FlowType, MacroFlowType } from '../../../../store/models/flow-type';
import { SalesProcessPartNumber } from '../../../../store/models/order-entry-state';
import { EglState } from '../../../../store/reducers';
import { selectCurrentVaProductTypes } from '../../../../store/selectors/common.selectors';
import {
    v2SelectAllProducts,
    v2SelectCommodityProducts,
    v2SelectCurrentProduct,
    v2SelectDistinctCommodities,
    v2SelectFirma,
    v2SelectFlowType,
    v2SelectInstantAcceptanceFlag,
    v2SelectIsCustomerRegistered,
    v2SelectPaymentInfo,
    v2SelectRemoteReadStatus,
    v2SelectVisibleProducts,
} from '../../../../store/selectors/order-entry-v2.selectors';
import {
    selectAnagraficaMB,
    selectFirmaState,
    selectFlowType,
    selectSkipPrivacy,
    selectTipoPagamento,
} from '../../../../store/selectors/order-entry.selectors';
import { selectEnabledPartnerships } from '../../../../store/selectors/partnership.selectors';
import {
    selectCartSegment,
    selectChannelCode,
    selectContact,
    selectCurrentVirtualAgent,
} from '../../../../store/selectors/user.selectors';
import { RoutesPaths } from '../../../config/routes-paths';
import { AptCommodityType } from '../../../enums/apttus/apt-commodity-typeof-sale';
import { AptLineStatus } from '../../../enums/apttus/apt-line-status';
import { AptPaymentInstrument } from '../../../enums/apttus/apt-payment-instrument';
import { D365ChannelCode } from '../../../enums/d365/d365-channel-code';
import { D365ConfirmationType } from '../../../enums/d365/d365-confirmation-type';
import { D365CustomerSegment } from '../../../enums/d365/d365-customer-segment';
import { D365SignatureType } from '../../../enums/d365/d365-signature-type';
import { isVulnerableOver75 } from '../../../functions/cf-piva.functions';
import {
    containsExtraCommodities,
    containsProductComplex,
    containsProductInsurance,
    containsProductMaintenance,
    containsProductSmartHome,
    flowTypeUtil,
    isCommodityFamily,
    isProductAppurtenance,
} from '../../../functions/verifications.functions';
import { EglProductAttributeGroupMemberService } from '../../apttus/tables/product/egl-product-attribute-group-member.service';
import { EglProductOptionGroupService } from '../../apttus/tables/product/egl-product-option-group.service';
import { D365Service } from '../../d365/d365.service';
import { DataOwnershipChangeService } from '../data-ownership-change.service';
import { FeatureToggleService } from '../feature-toggle.service';
import { DragonRouterSkippingFunction } from './dragon-router.type';

const selectPartnership = () => ((location.pathname.match(/partnership\/([^\/;]+)/i) || [])[1] || '').trim();

const skipPowerCommodity: (input: { eglState?: Store<EglState>; params?: Params }) => Observable<boolean> = ({
    eglState,
    params,
}) =>
    params?.itemId && !isNaN(parseInt(params?.itemId))
        ? eglState
              .select(v2SelectCurrentProduct(params?.itemId))
              .pipe(
                  map(
                      (product) =>
                          !isCommodityFamily(product?.family) || product?.powerOrGas !== AptCommodityType.Power,
                  ),
              )
        : eglState
              .select(v2SelectDistinctCommodities(AptCommodityType.Power))
              .pipe(map((products) => !products?.length));

const skipGasCommodity: (input: { eglState?: Store<EglState>; params?: Params }) => Observable<boolean> = ({
    eglState,
    params,
}) =>
    params?.itemId && !isNaN(parseInt(params?.itemId))
        ? eglState
              .select(v2SelectCurrentProduct(params?.itemId))
              .pipe(
                  map((product) => !isCommodityFamily(product?.family) || product?.powerOrGas !== AptCommodityType.Gas),
              )
        : eglState.select(v2SelectDistinctCommodities(AptCommodityType.Gas)).pipe(map((products) => !products?.length));

export const skipAppurtenances: (input: { eglState?: Store<EglState>; params?: Params }) => Observable<boolean> = ({
    eglState,
    params,
}) =>
    combineLatest([
        eglState.select(v2SelectCurrentProduct(params?.itemId)),
        eglState.select(selectCartSegment),
        eglState.select(v2SelectFlowType),
    ]).pipe(
        map(([product, segment, flowType]) => {
            return (
                isProductAppurtenance(product) &&
                segment === D365CustomerSegment.Residential &&
                flowTypeUtil(flowType).inMacroFlowTypes(
                    MacroFlowType.SwitchIn,
                    MacroFlowType.Voltura,
                    MacroFlowType.VolturaSwitchIn,
                )
            );
        }),
    );

/**
 * Skipping rules generiche
 */
export const GLOBAL_SKIPPING_RULES: {
    [key in RoutesPaths]?: {
        skip: DragonRouterSkippingFunction;
    };
} = {
    [RoutesPaths.DatiGenerici]: {
        skip: ({ eglState, injector }) => {
            const d365Service = injector.get(D365Service);
            return combineLatest([
                eglState.select(selectFlowType),
                eglState.select(selectCartSegment),
                eglState.select(selectAnagraficaMB),
                eglState.select(v2SelectCurrentProduct()),
                eglState.select(selectContact),
                d365Service.retrieveOver75Range(),
            ]).pipe(
                take(1),
                map(
                    ([flowType, segment, anagraficaMB, product, contact, over75Range]) =>
                        flowTypeUtil(flowType).inMacroFlowTypes(MacroFlowType.CambioProdotto) &&
                        ((segment === D365CustomerSegment.Residential &&
                            !product?.vulnerable &&
                            !isVulnerableOver75(contact.egl_taxcode, +over75Range[0]?.range)) ||
                            !!anagraficaMB?.cfLegal),
                ),
            );
        },
    },
    [RoutesPaths.DatiContatto]: {
        skip: ({ eglState }) =>
            combineLatest([
                eglState.select(v2SelectIsCustomerRegistered),
                eglState.select(v2SelectFlowType),
                eglState.select(v2SelectCurrentProduct()),
            ]).pipe(
                map(([isCustomerRegistered, flowType, product]) => {
                    const changeProductSkip =
                        flowTypeUtil(flowType).inMacroFlowTypes(MacroFlowType.CambioProdotto) && isCustomerRegistered;
                    const technicalVariationsSkip =
                        flowTypeUtil(flowType).equalTo(
                            FlowType.VariazioneTecnicaVerificheAmministrativa,
                            FlowType.VariazioneTecnicaLavoriAmministrativa,
                            FlowType.VariazioneTecnicaLavoriDiminuzionePotenza,
                            FlowType.VariazioneTecnicaLavoriDiminuzionePotenzaAmministrativa,
                            FlowType.VariazioneTecnicaLavoriPreventivo,
                            FlowType.VariazioneTecnicaLavoriPreventivoAmministrativa,
                            FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenza,
                            FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenzaAmministrativa,
                            FlowType.VariazioneTecnicaLavoriPreventivoVariazioneFasi,
                            FlowType.VariazioneTecnicaLavoriPreventivoVariazioneFasiAmministrativa,
                            FlowType.VariazioneTecnicaLavoriPreventivoVariazioneTensione,
                            FlowType.VariazioneTecnicaLavoriPreventivoVariazioneTensioneAmministrativa,
                            FlowType.VariazioneTecnicaLavoriPreventivoModificaImpiantoAmministrativa,
                        ) ||
                        (flowTypeUtil(flowType).equalTo(FlowType.VariazioneTecnicaVerifiche) &&
                            product?.partNumber === SalesProcessPartNumber.POWER_INSTANT_VOLTAGE_CHECK);

                    return changeProductSkip || technicalVariationsSkip;
                }),
            ),
    },
    [RoutesPaths.ModalitaAcquisizione]: {
        skip: ({ eglState, configSrv }) =>
            combineLatest([
                eglState.select(selectCartSegment),
                eglState.select(selectChannelCode),
                eglState.select(v2SelectCommodityProducts('ALL')),
            ]).pipe(
                map(([segment, currentChannel, commodities]) => {
                    const ocrChannels = [
                        D365ChannelCode.Agenzia,
                        D365ChannelCode.NegoziESE,
                        D365ChannelCode.AgenziaMicrobusiness,
                        D365ChannelCode.AgenteSingolo,
                        D365ChannelCode.Flagship,
                    ];
                    return (
                        configSrv.get<boolean>('disableOCRBtn') ||
                        segment === D365CustomerSegment.Microbusiness ||
                        !commodities?.length ||
                        !ocrChannels.includes(currentChannel)
                    );
                }),
            ),
    },
    [RoutesPaths.PsFastwebPersonalData]: {
        skip: ({ eglState }) =>
            eglState.select(selectEnabledPartnerships).pipe(
                map((enabledPartnerships) => ({
                    enabledPartnerships: enabledPartnerships.map((enabledPartnership) =>
                        enabledPartnership.toUpperCase(),
                    ) as AptPartnerships[],
                    partnership: selectPartnership().toUpperCase() as AptPartnerships,
                })),
                map(
                    ({ enabledPartnerships, partnership }) =>
                        !enabledPartnerships?.length ||
                        (enabledPartnerships.includes(partnership) && ![AptPartnerships.FASTWEB].includes(partnership)),
                ),
            ),
    },
    [RoutesPaths.PsFastwebActiveContracts]: {
        skip: ({ eglState }) =>
            eglState.select(selectEnabledPartnerships).pipe(
                map((enabledPartnerships) => ({
                    enabledPartnerships: enabledPartnerships.map((enabledPartnership) =>
                        enabledPartnership.toUpperCase(),
                    ) as AptPartnerships[],
                    partnership: selectPartnership().toUpperCase() as AptPartnerships,
                })),
                map(
                    ({ enabledPartnerships, partnership }) =>
                        !enabledPartnerships?.length ||
                        (enabledPartnerships.includes(partnership) && ![AptPartnerships.FASTWEB].includes(partnership)),
                ),
            ),
    },
    [RoutesPaths.CodiceAteco]: {
        skip: ({ eglState }) =>
            eglState.select(selectCartSegment).pipe(map((segment) => segment !== D365CustomerSegment.Microbusiness)),
    },
    [RoutesPaths.PotenzaImpegnata]: {
        skip: ({ eglState, params }) =>
            combineLatest([eglState.select(selectCartSegment), skipPowerCommodity({ eglState, params })]).pipe(
                map(([segment, isNotPower]) => segment !== D365CustomerSegment.Microbusiness || isNotPower),
            ),
    },
    [RoutesPaths.ConsumoLuce]: {
        skip: ({ eglState, params }) =>
            combineLatest([
                eglState.select(selectCartSegment),
                skipPowerCommodity({ eglState, params }),
                skipAppurtenances({ eglState, params }),
            ]).pipe(
                map(
                    ([segment, isNotPower, skipAppurtenances]) =>
                        segment !== D365CustomerSegment.Residential || isNotPower || skipAppurtenances,
                ),
            ),
    },
    [RoutesPaths.DatiPod]: {
        skip: ({ eglState, params }) =>
            combineLatest([eglState.select(v2SelectFlowType), skipPowerCommodity({ eglState, params })]).pipe(
                map(
                    ([flowType, isNotPower]) =>
                        flowTypeUtil(flowType).equalTo(
                            FlowType.AttivazioneN02,
                            FlowType.AttivazioneAmmN02,
                            FlowType.AttivazioneVipN02,
                        ) || isNotPower,
                ),
            ),
    },
    [RoutesPaths.ConsumoGas]: {
        skip: skipGasCommodity,
    },
    [RoutesPaths.DatiPdr]: {
        skip: skipGasCommodity,
    },
    [RoutesPaths.DatiPdrAttivazione]: {
        skip: ({ eglState, params }) =>
            combineLatest([eglState.select(v2SelectFlowType), skipGasCommodity({ eglState, params })]).pipe(
                map(
                    ([flowType, isNotGas]) =>
                        flowTypeUtil(flowType).equalTo(
                            FlowType.AttivazionePN1,
                            FlowType.AttivazioneAmmPN1,
                            FlowType.AttivazioneVipPN1,
                        ) || isNotGas,
                ),
            ),
    },
    [RoutesPaths.ConsumptionValidation]: {
        skip: ({ eglState, params }) =>
            combineLatest([eglState.select(v2SelectRemoteReadStatus()), skipGasCommodity({ eglState, params })]).pipe(
                map(([hasMeterBeenRead, isNotGas]) => isNotGas || hasMeterBeenRead),
            ),
    },
    [RoutesPaths.DatiTecniciAmministrativi]: {
        skip: ({ eglState, params }) =>
            combineLatest([
                eglState.select(v2SelectCommodityProducts()),
                eglState.select(selectFlowType),
                skipGasCommodity({ eglState, params }),
            ]).pipe(
                map(([commodities, flowType, isNotGas]) => {
                    if (flowTypeUtil(flowType).inMacroFlowTypes(MacroFlowType.Attivazione)) {
                        return !flowTypeUtil(flowType).inMacroFlowTypes([
                            MacroFlowType.Attivazione,
                            MacroFlowType.Recovery,
                        ]);
                    } else {
                        return (
                            !commodities?.length ||
                            (flowTypeUtil(flowType).inMacroFlowTypes(MacroFlowType.Voltura) && isNotGas)
                        );
                    }
                }),
            ),
    },
    [RoutesPaths.DatiPagamento]: {
        // saltare se diverso da addebitoCC bollettino ecc
        skip: ({ eglState, cartService }) =>
            combineLatest([
                eglState.select(selectFlowType),
                eglState.select(selectTipoPagamento),
                eglState.select(v2SelectPaymentInfo()),
                eglState.select(v2SelectCurrentProduct()),
                cartService.getMyCart(),
            ]).pipe(
                map(
                    ([flowType, paymentData, paymentInfo, currentProduct, cart]) => {
                        const isChangeProduct = flowTypeUtil(flowType).inMacroFlowTypes(MacroFlowType.CambioProdotto);
                        const needNewDomiciliation = isChangeProduct
                            ? [
                                  cart.LineItems.find((line) => line.LineStatus === AptLineStatus.Cancelled),
                                  cart.LineItems.find((line) => line.LineStatus === AptLineStatus.Upgraded),
                              ]
                                  .map((li) => <AptPaymentInstrument>li?.AttributeValue?.['egl_Payment_Instrument__c'])
                                  .filter(Boolean)
                                  .some((curPayType, _, array) => curPayType !== array[0])
                            : false;
                        return (
                            ![
                                AptPaymentInstrument.Bollettino,
                                AptPaymentInstrument.AddebitoCC,
                                AptPaymentInstrument.CartaCredito,
                                AptPaymentInstrument.CreditoAlConsumo,
                                AptPaymentInstrument.Altro,
                            ].includes(paymentInfo?.paymentInstrument) ||
                            (paymentInfo?.paymentInstrument === AptPaymentInstrument.Bollettino &&
                                paymentData?.isDigitalCommunications) ||
                            //il CP prevede il pagamento solo se dal prodotto vecchio al prodotto nuovo si cambia modalità di pagamento
                            //esclusi i flowType CambioTipologiaFornitura e CPRetroattivoAssetCessato che non devono skippare mai
                            (isChangeProduct &&
                                paymentInfo?.paymentInstrument === AptPaymentInstrument.AddebitoCC &&
                                !needNewDomiciliation &&
                                !flowTypeUtil(flowType).equalTo(
                                    FlowType.CambioTipologiaFornitura,
                                    FlowType.CPRetroattivoAssetCessato,
                                )) || //salto in caso in cui addebito conto corrente, ma non nuova domiciliazione CP
                            (!containsProductComplex(currentProduct?.productType) &&
                                paymentInfo.paymentInstrument === AptPaymentInstrument.Altro)
                        );
                    }, //salto in caso in cui addebito conto corrente, ma non nuova domiciliazione CP
                ),
            ),
    },
    [RoutesPaths.UploadDocumento]: {
        skip: ({ eglState, configSrv }) =>
            combineLatest([
                eglState.select(selectFlowType),
                eglState.select(v2SelectVisibleProducts('ALL')),
                eglState.select(selectCurrentVaProductTypes),
            ]).pipe(
                map(
                    ([flowType, products, currentVaProductTypes]) =>
                        !flowTypeUtil(flowType).inMacroFlowTypes(MacroFlowType.VolturaSwitchIn) &&
                        !flowTypeUtil(flowType).inMacroFlowTypes(MacroFlowType.Voltura) &&
                        (!currentVaProductTypes?.currentVirtualAg?.VirtualAgency?.Channel?.UploadDocument ||
                            (products.some(
                                ({ productType }) =>
                                    !!productType &&
                                    !(configSrv.config?.productCategoriesWithCI || []).includes(productType),
                            ) &&
                                !flowTypeUtil(flowType).equalTo(FlowType.DomiciliazioneAttivazione))),
                ),
            ),
    },
    [RoutesPaths.DatiAgentePiu]: {
        skip: ({ eglState }) =>
            combineLatest([eglState.select(selectCurrentVirtualAgent), eglState.select(selectFlowType)]).pipe(
                map(
                    ([currentVirtualAgent, flowType]) =>
                        (!currentVirtualAgent?.VirtualAgency?.Channel?.CanSelectAgentCode &&
                            !currentVirtualAgent?.VirtualAgency?.Channel?.CanSelectAgencyBranches) ||
                        flowType.includes('AMM') ||
                        flowType.includes('VIP'),
                ),
            ),
    },
    [RoutesPaths.DecretoCasa]: {
        skip: ({ eglState }) =>
            combineLatest([eglState.select(selectFlowType), eglState.select(selectCartSegment)]).pipe(
                map(
                    ([flowType, segment]) =>
                        flowTypeUtil(flowType).inMacroFlowTypes(
                            MacroFlowType.Voltura,
                            MacroFlowType.VolturaSwitchIn,
                            MacroFlowType.Attivazione,
                        ) && segment === D365CustomerSegment.Microbusiness,
                ),
            ),
    },

    [RoutesPaths.SignatureDate]: {
        skip: ({ eglState }) =>
            combineLatest([eglState.select(v2SelectFlowType), eglState.select(v2SelectFirma)]).pipe(
                map(([flowType, sign]) =>
                    flowTypeUtil(flowType).inMacroFlowTypes(MacroFlowType.Attivazione)
                        ? !flowTypeUtil(flowType).inMacroFlowTypes([
                              MacroFlowType.Attivazione,
                              MacroFlowType.Recovery,
                              MacroFlowType.Administrative,
                          ]) || sign.d365SignatureType === D365SignatureType.Cartacea
                        : flowTypeUtil(flowType).equalTo(FlowType.SwitchInAmmRecupero2A),
                ),
            ),
    },
    [RoutesPaths.TipoEsecuzione]: {
        skip: ({ eglState }) =>
            combineLatest([
                eglState.select(selectCartSegment),
                eglState.select(selectCurrentVaProductTypes),
                eglState.select(v2SelectFlowType),
                eglState.select(v2SelectAllProducts),
            ]).pipe(
                map(([segment, currentVaProductTypes, flowType, products]) => {
                    const isMbButNotSwinAmm =
                        segment === D365CustomerSegment.Microbusiness &&
                        !flowTypeUtil(flowType).inMacroFlowTypes([
                            MacroFlowType.SwitchIn,
                            MacroFlowType.Vip,
                            MacroFlowType.Administrative,
                        ]);
                    const isAttivazioneAmm = flowTypeUtil(flowType).inMacroFlowTypes([
                        MacroFlowType.Attivazione,
                        MacroFlowType.Administrative,
                    ]);
                    const checkSHManutenzione = products.every(
                        (p) => containsProductMaintenance(p.productType) || containsProductSmartHome([p.productType]),
                    );
                    const isInsurance = products.every((p) => containsProductInsurance(p.productType));
                    console.log(
                        'currentVaProductTypes?.currentVirtualAg?.VirtualAgency?.Channel?.CanSkipExecution',
                        currentVaProductTypes?.currentVirtualAg?.VirtualAgency?.Channel?.CanSkipExecution,
                    );
                    console.log(
                        'skipping page',
                        !flowTypeUtil(flowType).equalTo(FlowType.CPCommerciale) &&
                            !flowTypeUtil(flowType).inMacroFlowTypes(MacroFlowType.Voltura) &&
                            (isMbButNotSwinAmm ||
                                currentVaProductTypes?.currentVirtualAg?.VirtualAgency?.Channel?.CanSkipExecution ||
                                isAttivazioneAmm ||
                                isInsurance ||
                                checkSHManutenzione),
                    );
                    return (
                        !flowTypeUtil(flowType).equalTo(FlowType.CPCommerciale) &&
                        !flowTypeUtil(flowType).inMacroFlowTypes(MacroFlowType.Voltura) &&
                        (isMbButNotSwinAmm ||
                            currentVaProductTypes?.currentVirtualAg?.VirtualAgency?.Channel?.CanSkipExecution ||
                            isAttivazioneAmm ||
                            isInsurance ||
                            checkSHManutenzione)
                    );
                }),
            ),
    },
    [RoutesPaths.PrivacyDatiPersonali]: {
        skip: ({ eglState }) => eglState.select(selectSkipPrivacy).pipe(map((skipPrivacy) => !!skipPrivacy)),
    },
    [RoutesPaths.FirmaPreviaStampa]: {
        skip: ({ eglState }) =>
            eglState
                .select(selectFirmaState)
                .pipe(map((signature) => signature?.d365SignatureType !== D365SignatureType.PaperPrintedBySystem)),
    },
    [RoutesPaths.FirmaCartacea]: {
        skip: ({ eglState }) =>
            eglState
                .select(selectFirmaState)
                .pipe(map((signature) => signature?.d365SignatureType !== D365SignatureType.Cartacea)),
    },
    [RoutesPaths.FirmaDoiFull]: {
        skip: ({ eglState }) =>
            eglState
                .select(selectFirmaState)
                .pipe(map((signature) => signature?.d365SignatureType !== D365SignatureType.Doi)),
    },
    [RoutesPaths.FirmaVocalOrder]: {
        skip: ({ eglState }) =>
            eglState
                .select(selectFirmaState)
                .pipe(map((signature) => signature?.d365SignatureType !== D365SignatureType.VocalOrder)),
    },
    [RoutesPaths.SceltaContatto]: {
        skip: ({ eglState }) =>
            eglState.select(selectFirmaState).pipe(
                map(
                    (signature) =>
                        // la pagina scelta modalità di conferma viene visualizzata solo se:
                        // - la modalità di conferma relativa alla firma selezionata è diversa da tacito assenso oppure nessuna conferma
                        // - se è possibile modificare la modalità di conferma da check call a check DOI (signature.canBeModified)
                        // tutte queste logiche sono configurate all'interno della matrice firma/conferma su d365
                        !signature?.d365SignatureType ||
                        !signature?.canBeModified ||
                        [D365ConfirmationType.TacitApprovalSMS, D365ConfirmationType.NessunaConferma].includes(
                            signature?.confirmationType,
                        ),
                ),
            ),
    },
    [RoutesPaths.MarketProfiling]: {
        skip: ({ eglState }) =>
            eglState.select(selectCartSegment).pipe(map((segment) => segment !== D365CustomerSegment.Microbusiness)),
    },
    [RoutesPaths.ActivationOrTerminationDate]: {
        skip: ({ eglState }) =>
            eglState
                .select(selectFlowType)
                .pipe(
                    map(
                        (flowType) =>
                            !flowTypeUtil(flowType).inMacroFlowTypes(MacroFlowType.Cessazione, [
                                MacroFlowType.Attivazione,
                                MacroFlowType.Administrative,
                            ]),
                    ),
                ),
    },
    [RoutesPaths.ConfigPdf]: {
        skip: ({ eglState }) =>
            combineLatest([
                eglState.select(v2SelectVisibleProducts('ALL')),
                eglState.select(selectFlowType),
                eglState.select(selectCartSegment),
            ]).pipe(
                map(([products, flowType, segment]) =>
                    flowTypeUtil(flowType).inMacroFlowTypes(MacroFlowType.Voltura, MacroFlowType.Extracommodity)
                        ? products.length === 1
                        : (segment === D365CustomerSegment.Residential && products.length === 1) ||
                          (segment === D365CustomerSegment.Microbusiness && !products[0]?.isFilled),
                ),
            ),
    },
    [RoutesPaths.ModalitaInvio]: {
        skip: ({ eglState }) =>
            combineLatest([
                eglState.select(v2SelectCurrentProduct()),
                eglState.select(v2SelectFlowType),
                eglState.select(v2SelectInstantAcceptanceFlag),
            ]).pipe(
                map(([product, flowType, isInstantAcceptance]) => {
                    return (
                        flowTypeUtil(flowType).equalTo(
                            FlowType.VariazioneTecnicaVerifiche,
                            FlowType.VariazioneTecnicaVerificheAmministrativa,
                            FlowType.VariazioneTecnicaLavoriDiminuzionePotenza,
                            FlowType.VariazioneTecnicaLavoriDiminuzionePotenzaAmministrativa,
                            FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenzaAmministrativa,
                        ) ||
                        (flowTypeUtil(flowType).equalTo(
                            FlowType.VariazioneTecnicaLavori,
                            FlowType.VariazioneTecnicaLavoriAmministrativa,
                        ) &&
                            product?.partNumber !== SalesProcessPartNumber.GAS_ACTIVATION_WITH_METER_DEL_40_14)
                    );
                }),
            ),
    },
    [RoutesPaths.AttivazioneTipologiaImmobile]: {
        skip: ({ eglState, params }) =>
            combineLatest([
                eglState.select(selectFlowType),
                eglState.select(selectCartSegment),
                skipAppurtenances({ eglState, params }),
            ]).pipe(
                map(
                    ([flowType, segment, skipAppurtenances]) =>
                        (flowTypeUtil(flowType).inMacroFlowTypes(MacroFlowType.Attivazione) &&
                            segment === D365CustomerSegment.Microbusiness) ||
                        skipAppurtenances,
                ),
            ),
    },
    [RoutesPaths.InserimentoManualeDocumento]: {
        skip: ({ eglState }) =>
            combineLatest([
                eglState.select(selectFirmaState),
                eglState.select(v2SelectFlowType),
                eglState.select(v2SelectInstantAcceptanceFlag),
            ]).pipe(
                map(
                    ([signature, flowType, isInstantAcceptance]) =>
                        signature?.d365SignatureType === D365SignatureType.NessunaFirma ||
                        (flowTypeUtil(flowType).equalTo(FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenza) &&
                            (!isInstantAcceptance ||
                                signature?.d365SignatureType != D365SignatureType.PaperPrintedBySystem)) ||
                        flowTypeUtil(flowType).equalTo(
                            FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenzaAmministrativa,
                        ),
                ),
            ),
    },
    [RoutesPaths.TerminationMortisCausa]: {
        skip: ({ eglState }) =>
            combineLatest([eglState.select(selectFlowType), eglState.select(selectCartSegment)]).pipe(
                map(
                    ([flowType, segment]) =>
                        flowTypeUtil(flowType).inMacroFlowTypes(MacroFlowType.Cessazione) &&
                        segment === D365CustomerSegment.Microbusiness,
                ),
            ),
    },
    [RoutesPaths.ProductConfigurationStep]: {
        skip: ({ injector, eglState }) => {
            const prodOptionGroupSrv = injector.get(EglProductOptionGroupService);
            const prodAttributeGroupMemberSrv = injector.get(EglProductAttributeGroupMemberService);
            return eglState.select(v2SelectCurrentProduct()).pipe(
                take(1),
                mergeMap(({ productId }) =>
                    combineLatest([
                        prodOptionGroupSrv.hasOrderEntryConfigurableOptionGroups(productId),
                        prodAttributeGroupMemberSrv.hasOrderEntryConfigurableAttributeGroup(productId),
                    ]),
                ),
                map(
                    ([hasConfigurableOptionGroups, hasConfigurableAttributeGroups]) =>
                        !hasConfigurableOptionGroups && !hasConfigurableAttributeGroups,
                ),
            );
        },
    },
    [RoutesPaths.MaintenanceAppointment]: {
        skip: ({ eglState, injector }) => {
            const featureToggleSrv = injector.get(FeatureToggleService);
            return combineLatest([
                of(featureToggleSrv.isExtraCommodityFlowEnabled),
                eglState
                    .select(v2SelectVisibleProducts('ALL'))
                    .pipe(
                        map(
                            (products) =>
                                !!products.filter(
                                    (p) =>
                                        containsProductMaintenance(p.productType) ||
                                        containsProductComplex(p.productType),
                                ).length,
                        ),
                    ),
            ]).pipe(
                take(1),
                map(
                    ([isExtracommoditySaleEnabled, hasNewMaintenances]) =>
                        !isExtracommoditySaleEnabled || (isExtracommoditySaleEnabled && !hasNewMaintenances),
                ),
            );
        },
    },
    [RoutesPaths.AccettazioneImmediata]: {
        skip: ({ eglState }) =>
            combineLatest([eglState.select(v2SelectCurrentProduct()), eglState.select(v2SelectFlowType)]).pipe(
                map(([product, flowType]) => {
                    return (
                        (flowTypeUtil(flowType).equalTo(FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenza) &&
                            !product?.prices?.predetermined) ||
                        flowTypeUtil(flowType).equalTo(
                            FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenzaAmministrativa,
                        )
                    );
                }),
            ),
    },
    [RoutesPaths.SceltaFirma]: {
        skip: ({ eglState }) =>
            combineLatest([
                eglState.select(v2SelectFlowType),
                eglState.select(v2SelectInstantAcceptanceFlag),
                eglState.select(v2SelectCurrentProduct()),
            ]).pipe(
                map(([flowType, instantAcceptanceFlag, product]) => {
                    return (
                        flowTypeUtil(flowType).equalTo(
                            FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenzaAmministrativa,
                        ) ||
                        (flowTypeUtil(flowType).equalTo(FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenza) &&
                            (!product?.prices?.predetermined || !instantAcceptanceFlag))
                    );
                }),
            ),
    },
    [RoutesPaths.DoiConfermaCliente]: {
        skip: ({ injector }) => {
            const dataOwnershipChangeSrv = injector.get(DataOwnershipChangeService);
            return dataOwnershipChangeSrv.skipDataOwnershipChangePage();
        },
    },
    [RoutesPaths.Costs]: {
        skip: ({ eglState }) =>
            combineLatest([eglState.select(v2SelectFlowType), eglState.select(v2SelectCurrentProduct())]).pipe(
                map(([flowType, product]) => {
                    return (
                        flowTypeUtil(flowType).inMacroFlowTypes(MacroFlowType.Attivazione) &&
                        containsExtraCommodities([product])
                    );
                }),
            ),
    },
};
