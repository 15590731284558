export enum AptSalesProcess {
    /**
     * *******************************************************************************************
     * ****************** ☠ IL TRATTINO DA USARE È SEMPRE QUELLO CORTO (-) ☠ *******************
     * *******************************************************************************************
     */
    SwitchIn = 'SWITCH IN',
    SwitchInAmmVip = 'SWITCH-IN AMM. - VIP',
    SwitchInAmmRecupero = 'SWIN AMM. - RECUPERO',
    SwitchInAmmDelibera153 = 'SWIN AMM. - RITORNO DA DEL153',
    SwitchInWeb = 'SWITCH IN WEB',

    VolturaConSwitchIn = 'VOLTURA CON SWITCH',
    VolturaConCambioFornAmmVip = 'VOLTURA CON SWITCH AMM. - VIP',
    VolturaConCambioFornAmmRecupero = 'VOLTURA CON SWITCH AMM. - RECUPERO',

    Voltura = 'VOLTURA',
    VolturaAmmVip = 'VOLTURA AMM. - VIP',
    VolturaAmmSiProvisioning = 'VOLTURA AMM. - SI PROVISIONING',
    VolturaAmmNoProvisioning = 'VOLTURA AMM. - NO PROVISIONING',
    VolturaAmmIncorporazioneSocietaria = 'VOLTURA AMM. - INC.SOCIETARIA',

    Attivazione = 'ATTIVAZIONE',
    Subentro = 'SUBENTRO',
    AttivazioneSemplice = 'ATTIVAZIONE SEMPLICE',
    AttivazioneAmmGasA01 = 'ATT. SEMPLICE AMM. RECUPERO', // attivazione semplice amministrativa recupero
    AttivazioneAmmPowerS01 = 'SUBENTRO AMM. RECUPERO', // subentro amministrativo recupero
    AttSempAmmGasA01 = 'ATT. SEMPLICE AMM. STD', // Attivazione amministrativa - provisioning manuale gas
    SubentroAmmPwrS01 = 'SUBENTRO AMM. STD', // Attivazione Amministrativa - provisioning manuale (subentro) power
    AttivazioneVipGasA01 = 'ATTIVAZIONE SEMPLICE AMM. - VIP', // attivazione semplice VIP
    AttivazioneVipPowerS01 = 'SUBENTRO AMM. - VIP', // subentro VIP
    //ATTIVAZIONE COMPLESSA
    AttivazioneA01 = 'ATTIVAZIONE CON ISTANZA',
    AttivazioneN02 = 'ALLACCIO CON POSA E ATTIVAZIONE',
    AttivazioneA40 = 'ATTIVAZIONE CON ACCERTAMENTO',
    AttivazionePN1 = 'PREVENTIVO E ATTIVAZIONE GAS',
    // Attivazione Complessa Manuale
    AttivazioneAmmN02 = 'ALLACCIO CON POSA E ATTIVAZIONE AMM.', // LUCE
    AttivazionePwrAmmA01 = 'ATTIVAZIONE CON ISTANZA AMM', // LUCE
    AttivazioneAmmA40 = 'ATTIVAZIONE CON ACCERTAMENTO AMM', // GAS
    AttivazioneAmmPN1 = 'PREVENTIVO ATTIVAZIONE GAS AMM', // GAS
    // Attivazione Complessa Vip
    AttivazioneVipN02 = 'ALLACCIO CON POSA E ATTIVAZIONE AMM. VIP', // LUCE
    AttivazionePwrVipA01 = 'ATTIVAZIONE CON ISTANZA AMM. VIP', // LUCE
    AttivazioneVipA40 = 'ATTIVAZIONE CON ACCERTAMENTO AMM. VIP', // GAS
    AttivazioneVipPN1 = 'PREVENTIVO E ATTIVAZIONE GAS AMM. VIP', // GAS

    CambioProdotto = 'CAMBIO PRODOTTO',
    CambioProdottoAmministrativo = 'CAMBIO PRODOTTO AMMINISTRATIVO',
    CambioProdottoDaDelibera = 'CAMBIO PRODOTTO DA DELIBERA',
    CambioProdottoExNovo = 'CAMBIO PRODOTTO EX NOVO',
    CambioProdottoAssetCessato = 'CAMBIO PRODOTTO AMMINISTRATIVO CESSATO',

    Cessazione = 'CESSAZIONE',
    CessazionePerVoltura = 'CESSAZIONE PER VOLTURA',
    CessazioneAmministrativa = 'CESSAZIONE AMMINISTRATIVA',

    AttivazioneDomiciliazione = 'ATTIVAZIONEDOMICILIAZIONE',
    ModificaDomiciliazione = 'MODIFICADOMICILIAZIONE',
    RevocaDomiciliazione = 'REVOCADOMICILIAZIONE',

    // VT DROP1
    VariazioneTecnicaLavori = 'VARIAZIONE TECNICA LAVORI',
    VariazioneTecnicaLavoriAmministrativa = 'VARIAZIONE TECNICA LAVORI AMMINISTRATIVA',
    VariazioneTecnicaVerifiche = 'VARIAZIONE TECNICA VERIFICHE',
    VariazioneTecnicaVerificheAmministrativa = 'VARIAZIONE TECNICA VERIFICHE AMMINISTRATIVA',
    VariazioneTecnicaLavoriDiminuzionePotenza = 'VARIAZIONE TECNICA LAVORI DIMINUZIONE POTENZA',
    VariazioneTecnicaLavoriDiminuzionePotenzaAmministrativa = 'VARIAZIONE TECNICA LAVORI DIMINUZIONE POTENZA AMMINISTRATIVA',

    // VT DROP2
    VariazioneTecnicaLavoriPreventivo = 'VARIAZIONE TECNICA LAVORI PREVENTIVO',
    VariazioneTecnicaLavoriPreventivoAmministrativa = 'VARIAZIONE TECNICA LAVORI PREVENTIVO AMMINISTRATIVA',
    VariazioneTecnicaLavoriPreventivoAumentoPotenza = 'VARIAZIONE TECNICA LAVORI PREVENTIVO AUMENTO POTENZA',
    VariazioneTecnicaLavoriPreventivoAumentoPotenzaAmministrativa = 'VARIAZIONE TECNICA LAVORI PREVENTIVO AUMENTO POTENZA AMMINISTRATIVA',
    VariazioneTecnicaLavoriPreventivoVariazioneFasi = 'VARIAZIONE TECNICA LAVORI PREVENTIVO VARIAZIONE FASI',
    VariazioneTecnicaLavoriPreventivoVariazioneFasiAmministrativa = 'VARIAZIONE TECNICA LAVORI PREVENTIVO VARIAZIONE FASI AMMINISTRATIVA',
    VariazioneTecnicaLavoriPreventivoVariazioneTensione = 'VARIAZIONE TECNICA LAVORI PREVENTIVO VARIAZIONE TENSIONE',
    VariazioneTecnicaLavoriPreventivoVariazioneTensioneAmministrativa = 'VARIAZIONE TECNICA LAVORI PREVENTIVO VARIAZIONE TENSIONE AMMINISTRATIVA',

    // VT 2.1
    VariazioneTecnicaLavoriPreventivoModificaImpianto = 'VARIAZIONE TECNICA LAVORI PREVENTIVO MODIFICA IMPIANTO',
    VariazioneTecnicaLavoriPreventivoModificaImpiantoAmministrativa = 'VARIAZIONE TECNICA LAVORI PREVENTIVO MODIFICA IMPIANTO AMMINISTRATIVA',

    // VT DROP3
    VariazioneModificaParametriFornitura = 'MODIFICA PARAMETRI FORNITURA',
    VariazioneIndirizzo = 'MODIFICA INDIRIZZO FORNITURA',
    AllineaTipologiaUtilizzoDistributore = 'MC1 CAMBIO UTILIZZO POWER',

    ScontoStandalone = 'SCONTO STAND ALONE',
    DisattivazioneScontoStandalone = 'DISATT SCONTO STAND ALONE',

    Riapertura = 'RIAPERTURA',
    RiaperturaAmministrativa = 'RIAPERTURA AMMINISTRATIVA',
    RevocaRiapertura = 'REVOCA RIAPERTURA',
    Sospensione = 'SOSPENSIONE',
    SospensioneAmministrativa = 'SOSPENSIONE AMMINISTRATIVA',
    RevocaSospensione = 'REVOCA SOSPENSIONE',
    Interruzione = 'INTERRUZIONE',
    RevocaInterruzione = 'REVOCA INTERRUZIONE',
    InterruzioneAmministrativa = 'INTERRUZIONE AMMINISTRATIVA',

    CA1 = 'CA1',
    CA2 = 'CA2',
    CA3 = 'CA3',
    CA4 = 'CA4',
    CA5 = 'CA5',
    CA7MotiviDiversi = 'CA7 MOTIVI DIVERSI',
    CAE1 = 'CAE1',
    CAE3 = 'CAE3',
    CAE4 = 'CAE4',
    CAE5 = 'CAE5',
    CAE7 = 'CAE7',
    CAE8MotiviDiversi = 'CAE8 MOTIVI DIVERSI',
    CAE8RifiutoVoltura = 'CAE8 RIFIUTO VOLTURA',
    CA1Amministrativa = 'CA1 AMMINISTRATIVA',
    CA2Amministrativa = 'CA2 AMMINISTRATIVA',
    CA3Amministrativa = 'CA3 AMMINISTRATIVA',
    CA4Amministrativa = 'CA4 AMMINISTRATIVA',
    CA5Amministrativa = 'CA5 AMMINISTRATIVA',
    CA7MotiviDiversiAmministrativa = 'CA7 MOTIVI DIVERSI AMMINISTRATIVA',
    CAE1Amministrativa = 'CAE1 AMMINISTRATIVA',
    CAE3Amministrativa = 'CAE3 AMMINISTRATIVA',
    CAE4Amministrativa = 'CAE4 AMMINISTRATIVA',
    CAE5Amministrativa = 'CAE5 AMMINISTRATIVA',
    CAE7Amministrativa = 'CAE7 AMMINISTRATIVA',
    CAE8MotiviDiversiAmministrativa = 'CAE8 MOTIVI DIVERSI AMMINISTRATIVA',
    CAE8RifiutoVolturaAmministrativa = 'CAE8 RIFIUTO VOLTURA AMMINISTRATIVA',
    RevocaCA1 = 'REVOCA CA1',
    RevocaCA2 = 'REVOCA CA2',
    RevocaCA3 = 'REVOCA CA3',
    RevocaCA4 = 'REVOCA CA4',
    RevocaCA5 = 'REVOCA CA5',
    RevocaCA7Motividiversi = 'REVOCA CA7 MOTIVI DIVERSI',
    RevocaCAE1 = 'REVOCA CAE1',
    RevocaCAE3 = 'REVOCA CAE3',
    RevocaCAE4 = 'REVOCA CAE4',
    RevocaCAE5 = 'REVOCA CAE5',
    RevocaCAE7 = 'REVOCA CAE7',
    RevocaCAE8Rifiutovoltura = 'REVOCA CAE8 RIFIUTOVOLTURA',
    RevocaCAE8Motividiversi = 'REVOCA CAE8 MOTIVIDIVERSI',
    RevocaRisoluzioneContrattuale = 'REVOCA RISOLUZIONE CONTRATTUALE',
    RevocaAltriProcessi = 'REVOCA ALTRI',

    AggregaDisaggregaFornitura = 'AGGREGA DISAGGREGA FORNITURA',
    Aggrega = 'Aggrega',
    Disaggrega = 'Disaggrega',
    AggregaDisaggrega = 'AGGREGA DISAGGREGA FORNITURA',
    VariazioneCommerciale = 'VARIAZIONE COMMERCIALE',
    SwitchOut = 'SWITCH OUT',
    CambioTipologiaFornitura = 'CAMBIO TIPOLOGIA FORNITURA',
    ExtraCommoditySale = 'EXTRACOMMODITY',
    DisattivazionePolizza = 'DISATTIVAZIONE POLIZZA',
    ProdottoUnico = 'Prodotto Unico',
}

// NON AGGIUNGERE OPERATION TYPE (li decide SALESFORCE...)
export enum AptSaleabilityOperationType {
    SwitchIn = 'Switch-In',
    SwitchInAmm = 'SWIN AMM. - RECUPERO',
    SwitchInAmmVip = 'SWITCH-IN AMM. - VIP',
    SwitchInAmmDelibera153 = 'SWIN AMM. - RITORNO DA DEL153',

    Attivazione = 'Attivazione',
    AttivazioneAmm = 'ATTIVAZIONE AMMINISTRATIVA',

    Voltura = 'Voltura',
    VolturaAmmIncorporazioneSocietaria = 'VOLTURA AMM. - INC.SOCIETARIA',
    VolturaAmmNoProvisioning = 'VOLTURA AMM. - NO PROVISIONING',
    VolturaAmmSiProvisioning = 'VOLTURA AMM. - SI PROVISIONING',
    VolturaAmmVip = 'VOLTURA AMM. - VIP',

    VolturaConSwitch = 'Voltura con Switch',
    VolturaConSwitchRecupero = 'VOLTURA CON SWITCH AMM. - RECUPERO',
    VolturaConCambioFornAmmVip = 'VOLTURA CON CAMBIO FORN. AMM. – VIP',
    VolturaConCambioFornAmmRecupero = 'VOLTURA CON CAMBIO FORN. AMM- RECUPERO',

    CambioProdotto = 'Cambio Prodotto',
    CambioProdottoDaDelibera = 'Cambio Prodotto da Delibera',
    CambioProdottoAmministrativo = 'Cambio Prodotto Amministrativo',
    CambioProdottoExNovo = 'CAMBIO PRODOTTO EX NOVO',
    CambioProdottoExNovoCessato = 'CAMBIO PRODOTTO EX NOVO CESSATO',
    CambioProdottoAssetCessato = 'CambioProdottoAssetCessato',
    CambioProdottoAmmAssetCessato = 'CAMBIO PRODOTTO AMMINISTRATIVO CESSATO',

    CambioTipologiaFornitura = 'Cambio Tipologia Fornitura',
    CambioContratto = 'Cambio Contratto',
    Cessione = 'Cessione',
    Verifica = 'Verifica',
    Lavori = 'Lavori',
    LavoriPreventivo = 'Lavori con preventivo',
    DomiciliazioneStandAlone = 'Domiciliazione stand alone',
    VariazioneCommerciale = 'Variazione Commerciale',
    ScontoStandalone = 'Sconto Stand Alone',
    ExtraCommodity = 'EXTRACOMMODITY',
    Nessuno = '',
}

export enum AptSalesInadmissibilityProcess {
    All = 'Tutti gli ordini',
    InadmissibilityOrder = 'Ordini con inammissibilità',
    ReworkableInadmissibilityOrder = 'Ordini con inammissibilità lavorabile',
}
