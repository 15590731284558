import { DEFAULT_GAS_METER_CLASS, DEFAULT_GAS_POTENTIAL } from '../../../../../modules/common/default.values';
import { toLocaleDateString } from '../../../../functions/misc.functions';
import { orderTypeOfUsage } from '../../../../functions/remap.functions';
import { AttributeValues } from './base-attribute-values';

export class GasAttributeValues extends AttributeValues {
    attributesMapping(): void {
        super.attributesMapping();

        this.attr['egl_PDR__c'] = this.currentProduct?.podPdr;
        this.attr['egl_typeofusage__c'] = orderTypeOfUsage(this.currentProduct?.technicalDetails?.typeOfUsage);
        this.attr['egl_gas_consumption__c'] = this.currentProduct?.technicalDetails?.consumption;
        this.attr['egl_annual_consumption__c'] = this.currentProduct?.technicalDetails?.consumption;
        this.attr['egl_gas_consumption_declared__c'] = this.currentProduct?.technicalDetails?.consumption;
        this.attr['egl_gas_potential_consumption__c'] =
            this.currentProduct?.technicalDetails?.gasPotential || DEFAULT_GAS_POTENTIAL;
        this.attr['egl_switchoutdate__c'] = toLocaleDateString(this.currentProduct?.technicalDetails?.switchOutDate);
        this.attr['egl_pdr_serial_number__c'] = this.currentProduct?.technicalDetails?.meterSerialNumber;
        this.attr['egl_pdr_type__c'] = this.currentProduct?.technicalDetails?.gasMeterScope;
        this.attr['egl_serial_number_distibutor__c'] = this.currentProduct?.technicalDetails?.meterSerialNumber;
        this.attr['egl_digits_meter__c'] = this.currentProduct?.technicalDetails?.gasMeterDigits;
        this.attr['egl_meter_reading__c'] = this.currentProduct?.technicalDetails?.meterCounter;
        this.attr['egl_meter_class__c'] =
            this.currentProduct?.technicalDetails?.gasMeterClass || DEFAULT_GAS_METER_CLASS;
        this.attr['egl_meter_integrated__c'] = this.currentProduct?.technicalDetails?.gasMeterEmbeddedCluster;
        this.attr['egl_C_coefficient_corrective__c'] =
            this.currentProduct?.technicalDetails?.gasMeterC2AdjustmentFactor;
        this.attr['egl_digits_corrective__c'] = this.currentProduct?.technicalDetails?.gasMeterAdjustmentDigit;
        this.attr['egl_serial_number_corrector__c'] =
            this.currentProduct?.technicalDetails?.gasMeterAdjustmentSerialNumber;
        this.attr['egl_corrector_reading__c'] = this.currentProduct?.technicalDetails?.gasMeterAdjustmentNumber;
        this.attr['egl_istatcode__c'] = this.currentProduct?.technicalDetails?.gasIstatCommon;
        this.attr['egl_gasProgressiveAnnualVolumes__c'] =
            this.currentProduct?.technicalDetails?.gasProgressiveAnnualVolumes;
        this.attr['egl_citygate__c'] = this.currentProduct?.technicalDetails?.gasCityGate;
        this.attr['egl_supply_class__c'] = this.currentProduct?.technicalDetails?.gasWithdrawalClass;
        this.attr['egl_vulnerabilitytype_socialbonus__c'] =
            this.currentProduct?.configurations?.vulnerabilitySocialBonus;
        this.attr['egl_vulnerabilitytype_saemapre__c'] = this.currentProduct?.configurations?.vulnerabilitySaeMapre;
        this.attr['egl_vulnerabilitytype_disabled__c'] = this.currentProduct?.configurations?.vulnerabilityDisabled;
        this.attr['egl_vulnerabilitytype_over75__c'] = this.currentProduct?.configurations?.vulnerabilityOver75;
    }
}
