import {
    AptSaleabilityOperationType,
    AptSalesInadmissibilityProcess,
    AptSalesProcess,
} from '../enums/apttus/apt-sales-process';
import { D365OperationMode } from '../enums/d365/d365-operation-mode';
import { FlowType, MacroFlowType } from '../../store/models/flow-type';
import { MastershipType } from '../../store/models/user-state';
import { D365AccountMigrated } from '../enums/d365/d365-account-migrated';
import { InadmissibilityFilterSubType, InadmissibilityFilterType } from '../enums/inadmissibility-filter-type';
import { InadmissibilityFilter } from '../models/app/inadmissibility-filter';
import { ScriptDocType } from '../enums/shared/script-doc-type';

/**
 * @description La mappatura va definita per ogni operationType della matrice di vendibilità;
 * L'ordine dei FlowType/SalesProcess è molto importante: Da quello di default a quelli con maggiore granularità
 */
export const PROCESS_OPERATION_MAPPINGS: {
    [key in AptSaleabilityOperationType]?: {
        [key in FlowType]?: AptSalesProcess | AptSalesProcess[];
    };
} = {
    [AptSaleabilityOperationType.SwitchIn]: {
        [FlowType.SwitchIn]: AptSalesProcess.SwitchIn,
        [FlowType.SwitchInAmmRecupero2A]: AptSalesProcess.SwitchInAmmRecupero,
        [FlowType.SwitchInAmmDelibera153]: AptSalesProcess.SwitchInAmmDelibera153,
        [FlowType.ExtraCommoditySale]: AptSalesProcess.ExtraCommoditySale,
    },
    [AptSaleabilityOperationType.SwitchInAmmVip]: {
        [FlowType.SwitchInAmmVip]: AptSalesProcess.SwitchInAmmVip,
    },
    [AptSaleabilityOperationType.SwitchInAmm]: {
        [FlowType.SwitchInAmmRecupero2B]: AptSalesProcess.SwitchInAmmRecupero,
        [FlowType.SwitchInAmmRecupero2C]: AptSalesProcess.SwitchInAmmRecupero,
    },
    [AptSaleabilityOperationType.Attivazione]: {
        [FlowType.Attivazione]: AptSalesProcess.AttivazioneSemplice, // Default
        [FlowType.AttivazioneGas]: AptSalesProcess.AttivazioneSemplice,
        [FlowType.AttivazioneN02]: AptSalesProcess.AttivazioneN02, // Allaccio con posa e attivazione
        [FlowType.AttivazionePwrS01]: AptSalesProcess.Subentro,
        [FlowType.AttivazioneA40]: AptSalesProcess.AttivazioneA40, // Attivazione con accertamento documentale
        [FlowType.AttivazionePwrA01]: AptSalesProcess.AttivazioneA01, // Attivazione con istanza
        [FlowType.AttivazionePN1]: AptSalesProcess.AttivazionePN1, // Preventivo e attivazione

        [FlowType.AttivazioneAmmGasA01]: AptSalesProcess.AttSempAmmGasA01, // Attivazione semplice amministrativa gas
        [FlowType.AttivazioneAmmPwrS01]: AptSalesProcess.SubentroAmmPwrS01, // Subentro amministrativo power
        [FlowType.AttivazioneAmmVipPowerS01]: AptSalesProcess.AttivazioneVipPowerS01,
        [FlowType.AttivazioneAmmVipGasA01]: AptSalesProcess.AttivazioneVipGasA01,

        [FlowType.AttivazioneAmmRecuperoS01]: AptSalesProcess.AttivazioneAmmPowerS01,
        [FlowType.AttivazioneAmmRecuperoA01]: AptSalesProcess.AttivazioneAmmGasA01,

        [FlowType.AttivazioneAmmA40]: AptSalesProcess.AttivazioneAmmA40, // Attivazione con accertamento documentale amm
        [FlowType.AttivazioneAmmPN1]: AptSalesProcess.AttivazioneAmmPN1, // Preventivo e attivazione amm
        [FlowType.AttivazionePwrAmmA01]: AptSalesProcess.AttivazionePwrAmmA01, // Attivazione con istanza amm
        [FlowType.AttivazioneAmmN02]: AptSalesProcess.AttivazioneAmmN02, // Allaccio con posa e attivazione amm

        [FlowType.AttivazioneVipA40]: AptSalesProcess.AttivazioneVipA40, // Attivazione con accertamento documentale Vip
        [FlowType.AttivazioneVipPN1]: AptSalesProcess.AttivazioneVipPN1, // Preventivo e attivazione Vip
        [FlowType.AttivazionePwrVipA01]: AptSalesProcess.AttivazionePwrVipA01, // Attivazione con istanza Vip
        [FlowType.AttivazioneVipN02]: AptSalesProcess.AttivazioneVipN02, // Allaccio con posa e attivazione Vip
    },
    // Attivazioni Amministrative
    [AptSaleabilityOperationType.AttivazioneAmm]: {
        [FlowType.AttivazioneAmmRecupero]: AptSalesProcess.Attivazione, // Default Attivazione amministativo prima di ricevere la commodity
        [FlowType.AttivazioneAmministrativa]: AptSalesProcess.Attivazione, // Default Attivazione amministativo prima di ricevere la commodity
        [FlowType.AttivazioneAmmVip]: AptSalesProcess.Attivazione,
    },
    [AptSaleabilityOperationType.CambioProdotto]: {
        [FlowType.CPCommerciale]: AptSalesProcess.CambioProdotto,
    },
    [AptSaleabilityOperationType.CambioProdottoAmministrativo]: {
        [FlowType.CPAmministrativo]: AptSalesProcess.CambioProdottoAmministrativo,
    },
    [AptSaleabilityOperationType.CambioProdottoDaDelibera]: {
        [FlowType.CPDelibera]: AptSalesProcess.CambioProdottoDaDelibera,
    },
    [AptSaleabilityOperationType.CambioProdottoExNovo]: {
        [FlowType.CPRetroattivoExNovo]: AptSalesProcess.CambioProdottoExNovo,
    },
    [AptSaleabilityOperationType.CambioProdottoExNovoCessato]: {
        [FlowType.CPRetroattivoAssetCessato]: AptSalesProcess.CambioProdottoAssetCessato,
    },
    [AptSaleabilityOperationType.CambioProdottoAssetCessato]: {
        [FlowType.CPRetroattivoAssetCessato]: AptSalesProcess.CambioProdottoAssetCessato,
    },
    [AptSaleabilityOperationType.CambioProdottoAmmAssetCessato]: {
        [FlowType.CPRetroattivoAssetCessato]: AptSalesProcess.CambioProdottoAssetCessato,
    },
    [AptSaleabilityOperationType.CambioTipologiaFornitura]: {
        [FlowType.CambioTipologiaFornitura]: AptSalesProcess.CambioTipologiaFornitura,
    },
    [AptSaleabilityOperationType.Voltura]: {
        [FlowType.Voltura]: AptSalesProcess.Voltura,
    },
    [AptSaleabilityOperationType.VolturaAmmVip]: {
        [FlowType.VolturaAmmVip]: AptSalesProcess.VolturaAmmVip,
    },
    [AptSaleabilityOperationType.VolturaAmmIncorporazioneSocietaria]: {
        [FlowType.VolturaAmmIncorporazioneSocietaria]: AptSalesProcess.VolturaAmmIncorporazioneSocietaria,
    },
    [AptSaleabilityOperationType.VolturaAmmNoProvisioning]: {
        [FlowType.VolturaAmmNoProvisioning]: AptSalesProcess.VolturaAmmNoProvisioning,
    },
    [AptSaleabilityOperationType.VolturaAmmSiProvisioning]: {
        [FlowType.VolturaAmmSiProvisioning]: AptSalesProcess.VolturaAmmSiProvisioning,
    },
    [AptSaleabilityOperationType.VolturaConSwitch]: {
        [FlowType.VolturaCambioFornitore]: AptSalesProcess.VolturaConSwitchIn,
    },
    [AptSaleabilityOperationType.VolturaConSwitchRecupero]: {
        [FlowType.VolturaCambioFornitoreAmmRecupero2A]: AptSalesProcess.VolturaConCambioFornAmmRecupero,
        [FlowType.VolturaCambioFornitoreAmmRecupero2B]: AptSalesProcess.VolturaConCambioFornAmmRecupero,
        [FlowType.VolturaCambioFornitoreAmmRecupero2C]: AptSalesProcess.VolturaConCambioFornAmmRecupero,
    },
    [AptSaleabilityOperationType.VolturaConCambioFornAmmVip]: {
        [FlowType.VolturaCambioFornitoreVip]: AptSalesProcess.VolturaConCambioFornAmmVip,
    },
    [AptSaleabilityOperationType.VariazioneCommerciale]: {
        [FlowType.VariazioneCommerciale]: AptSalesProcess.VariazioneCommerciale,
    },
    [AptSaleabilityOperationType.ScontoStandalone]: {
        [FlowType.ScontoStandalone]: AptSalesProcess.ScontoStandalone,
    },
    [AptSaleabilityOperationType.DomiciliazioneStandAlone]: {
        [FlowType.DomiciliazioneAttivazione]: AptSalesProcess.AttivazioneDomiciliazione,
        [FlowType.DomiciliazioneAttivazioneInserimentoPaymentTool]: AptSalesProcess.AttivazioneDomiciliazione,
        [FlowType.DomiciliazioneModifica]: AptSalesProcess.ModificaDomiciliazione,
        [FlowType.DomiciliazioneModificaInserimentoPaymentTool]: AptSalesProcess.ModificaDomiciliazione,
        [FlowType.DomiciliazioneRevoca]: AptSalesProcess.RevocaDomiciliazione,
    },
    // VT DROP 1
    [AptSaleabilityOperationType.Verifica]: {
        [FlowType.VariazioneTecnicaVerifiche]: AptSalesProcess.VariazioneTecnicaVerifiche,
        [FlowType.VariazioneTecnicaVerificheAmministrativa]: AptSalesProcess.VariazioneTecnicaVerificheAmministrativa,
    },
    [AptSaleabilityOperationType.Lavori]: {
        [FlowType.VariazioneTecnicaLavori]: AptSalesProcess.VariazioneTecnicaLavori,
        [FlowType.VariazioneTecnicaLavoriAmministrativa]: AptSalesProcess.VariazioneTecnicaLavoriAmministrativa,
        [FlowType.VariazioneTecnicaLavoriDiminuzionePotenza]: AptSalesProcess.VariazioneTecnicaLavoriDiminuzionePotenza,
        [FlowType.VariazioneTecnicaLavoriDiminuzionePotenzaAmministrativa]:
            AptSalesProcess.VariazioneTecnicaLavoriDiminuzionePotenzaAmministrativa,
    },
    // VT DROP 2
    [AptSaleabilityOperationType.LavoriPreventivo]: {
        [FlowType.VariazioneTecnicaLavoriPreventivo]: AptSalesProcess.VariazioneTecnicaLavoriPreventivo,
        [FlowType.VariazioneTecnicaLavoriPreventivoAmministrativa]:
            AptSalesProcess.VariazioneTecnicaLavoriPreventivoAmministrativa,
        [FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenza]:
            AptSalesProcess.VariazioneTecnicaLavoriPreventivoAumentoPotenza,
        [FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenzaAmministrativa]:
            AptSalesProcess.VariazioneTecnicaLavoriPreventivoAumentoPotenzaAmministrativa,
        [FlowType.VariazioneTecnicaLavoriPreventivoVariazioneTensione]:
            AptSalesProcess.VariazioneTecnicaLavoriPreventivoVariazioneTensione,
        [FlowType.VariazioneTecnicaLavoriPreventivoVariazioneTensioneAmministrativa]:
            AptSalesProcess.VariazioneTecnicaLavoriPreventivoVariazioneTensioneAmministrativa,
        [FlowType.VariazioneTecnicaLavoriPreventivoVariazioneFasi]:
            AptSalesProcess.VariazioneTecnicaLavoriPreventivoVariazioneFasi,
        [FlowType.VariazioneTecnicaLavoriPreventivoVariazioneFasiAmministrativa]:
            AptSalesProcess.VariazioneTecnicaLavoriPreventivoVariazioneFasiAmministrativa,
        /* VT 2.1 */
        [FlowType.VariazioneTecnicaLavoriPreventivoModificaImpianto]:
            AptSalesProcess.VariazioneTecnicaLavoriPreventivoModificaImpianto,
        [FlowType.VariazioneTecnicaLavoriPreventivoModificaImpiantoAmministrativa]:
            AptSalesProcess.VariazioneTecnicaLavoriPreventivoModificaImpiantoAmministrativa,
    },

    [AptSaleabilityOperationType.Nessuno]: {
        [FlowType.Cessazione]: AptSalesProcess.Cessazione,
        [FlowType.CessazioneAmministrativa]: AptSalesProcess.CessazioneAmministrativa,
        // VT DROP 3
        [FlowType.VariazioneTipologiaFornituraGas]: AptSalesProcess.VariazioneModificaParametriFornitura,
        [FlowType.VariazioneAteco]: AptSalesProcess.VariazioneModificaParametriFornitura,
        [FlowType.VariazioneIndirizzo]: AptSalesProcess.VariazioneIndirizzo,
        [FlowType.AllineaTipologiaUtilizzoDistributore]: AptSalesProcess.AllineaTipologiaUtilizzoDistributore,

        [FlowType.Riapertura]: AptSalesProcess.Riapertura,
        [FlowType.RiaperturaAmministrativa]: AptSalesProcess.RiaperturaAmministrativa,
        [FlowType.RevocaRiapertura]: AptSalesProcess.RevocaRiapertura,
        [FlowType.AnnullamentoRiapertura]: null,

        [FlowType.Sospensione]: AptSalesProcess.Sospensione,
        [FlowType.SospensioneAmministrativa]: AptSalesProcess.SospensioneAmministrativa,
        [FlowType.RevocaSospensione]: AptSalesProcess.RevocaSospensione,
        [FlowType.AnnullamentoSospensione]: null,

        [FlowType.Interruzione]: AptSalesProcess.Interruzione,
        [FlowType.InterruzioneAmministrativa]: AptSalesProcess.InterruzioneAmministrativa,
        [FlowType.RevocaInterruzione]: AptSalesProcess.RevocaInterruzione,
        [FlowType.AnnullamentoInterruzione]: null,

        [FlowType.CA1]: AptSalesProcess.CA1,
        [FlowType.CA1AMM]: AptSalesProcess.CA1Amministrativa,
        [FlowType.CA2]: AptSalesProcess.CA2,
        [FlowType.CA2AMM]: AptSalesProcess.CA2Amministrativa,
        [FlowType.CA3]: AptSalesProcess.CA3,
        [FlowType.CA3AMM]: AptSalesProcess.CA3Amministrativa,
        [FlowType.CA4]: AptSalesProcess.CA4,
        [FlowType.CA4AMM]: AptSalesProcess.CA4Amministrativa,
        [FlowType.CA5]: AptSalesProcess.CA5Amministrativa,
        [FlowType.CA5AMM]: AptSalesProcess.CA5Amministrativa,
        [FlowType.CA7]: AptSalesProcess.CA7MotiviDiversi,
        [FlowType.CA7AMM]: AptSalesProcess.CAE7Amministrativa,
        [FlowType.CAE1]: AptSalesProcess.CAE1,
        [FlowType.CAE1AMM]: AptSalesProcess.CAE1Amministrativa,
        [FlowType.CAE3]: AptSalesProcess.CAE3,
        [FlowType.CAE3AMM]: AptSalesProcess.CAE3Amministrativa,
        [FlowType.CAE4]: AptSalesProcess.CAE4,
        [FlowType.CAE4AMM]: AptSalesProcess.CAE4Amministrativa,
        [FlowType.CAE5]: AptSalesProcess.CAE5,
        [FlowType.CAE5AMM]: AptSalesProcess.CAE5Amministrativa,
        [FlowType.CAE7]: AptSalesProcess.CAE7,
        [FlowType.CAE7AMM]: AptSalesProcess.CAE7Amministrativa,
        [FlowType.CAE8]: AptSalesProcess.CAE8MotiviDiversi,
        [FlowType.CAE8AMM]: AptSalesProcess.CAE8MotiviDiversiAmministrativa,
        [FlowType.CAE8RIFVOLT]: AptSalesProcess.CAE8RifiutoVoltura,
        [FlowType.CAE8RIFVOLTAMM]: AptSalesProcess.CAE8RifiutoVolturaAmministrativa,

        [FlowType.RevocaRisoluzioneContrattuale]: AptSalesProcess.RevocaRisoluzioneContrattuale,
        [FlowType.RevocaAltriProcessi]: AptSalesProcess.RevocaAltriProcessi,

        [FlowType.Aggrega]: AptSalesProcess.AggregaDisaggrega,
        [FlowType.Disaggrega]: AptSalesProcess.AggregaDisaggrega,

        [FlowType.CessazioneExtracommodity]: AptSalesProcess.ExtraCommoditySale,
        [FlowType.DisattivazioneCdC]: null,
    },
};

export const MACRO_FLOW_TYPES: {
    [key in MacroFlowType]?: FlowType | FlowType[];
} = {
    [MacroFlowType.SwitchIn]: [
        FlowType.SwitchIn,
        FlowType.SwitchInAmmVip,
        FlowType.SwitchInAmmRecupero2A,
        FlowType.SwitchInAmmRecupero2B,
        FlowType.SwitchInAmmRecupero2C,
        FlowType.SwitchInAmmDelibera153,
    ],
    [MacroFlowType.VolturaSwitchIn]: [
        FlowType.VolturaCambioFornitore,
        FlowType.VolturaCambioFornitoreAmmRecupero2A,
        FlowType.VolturaCambioFornitoreAmmRecupero2B,
        FlowType.VolturaCambioFornitoreAmmRecupero2C,
        FlowType.VolturaCambioFornitoreVip,
    ],
    [MacroFlowType.Attivazione]: [
        FlowType.Attivazione,
        FlowType.AttivazioneGas,
        FlowType.AttivazioneA40,
        FlowType.AttivazioneN02,
        FlowType.AttivazionePN1,
        FlowType.AttivazionePwrA01,
        FlowType.AttivazionePwrS01,
        FlowType.AttivazioneAmministrativa,
        FlowType.AttivazioneAmmGasA01,
        FlowType.AttivazioneAmmA40,
        FlowType.AttivazioneAmmN02,
        FlowType.AttivazioneAmmPN1,
        FlowType.AttivazioneAmmPwrS01,
        FlowType.AttivazioneAmmRecupero,
        FlowType.AttivazioneAmmRecuperoA01,
        FlowType.AttivazioneAmmRecuperoS01,
        FlowType.AttivazioneAmmVip,
        FlowType.AttivazioneAmmVipGasA01,
        FlowType.AttivazioneAmmVipPowerS01,
        FlowType.AttivazionePwrAmmA01,
        FlowType.AttivazionePwrVipA01,
        FlowType.AttivazioneVipA40,
        FlowType.AttivazioneVipN02,
        FlowType.AttivazioneVipPN1,
    ],
    [MacroFlowType.AttivazioneComplessa]: [
        FlowType.Attivazione,
        FlowType.AttivazioneA40,
        FlowType.AttivazioneN02,
        FlowType.AttivazionePN1,
        FlowType.AttivazionePwrA01,
        FlowType.AttivazioneAmministrativa,
        FlowType.AttivazioneAmmA40,
        FlowType.AttivazioneAmmN02,
        FlowType.AttivazioneAmmPN1,
        FlowType.AttivazionePwrAmmA01,
        FlowType.AttivazioneAmmVip,
        FlowType.AttivazionePwrVipA01,
        FlowType.AttivazioneVipA40,
        FlowType.AttivazioneVipN02,
        FlowType.AttivazioneVipPN1,
    ],
    [MacroFlowType.CambioProdotto]: [
        FlowType.CPCommerciale,
        FlowType.CPDelibera,
        FlowType.CPAmministrativo,
        FlowType.CPRetroattivoExNovo,
        FlowType.CPRetroattivoAssetCessato,
        FlowType.CambioTipologiaFornitura,
    ],
    [MacroFlowType.Voltura]: [
        FlowType.Voltura,
        FlowType.VolturaAmmVip,
        FlowType.VolturaAmmSiProvisioning,
        FlowType.VolturaAmmNoProvisioning,
        FlowType.VolturaAmmIncorporazioneSocietaria,
    ],
    [MacroFlowType.Domiciliazione]: [
        FlowType.DomiciliazioneAttivazione,
        FlowType.DomiciliazioneAttivazioneInserimentoPaymentTool,
        FlowType.DomiciliazioneModifica,
        FlowType.DomiciliazioneModificaInserimentoPaymentTool,
        FlowType.DomiciliazioneRevoca,
    ],
    // VT DROP 1 / 2
    [MacroFlowType.Variazione]: [
        FlowType.VariazioneCommerciale,
        /* DROP 1 */
        FlowType.VariazioneTecnicaVerifiche,
        FlowType.VariazioneTecnicaVerificheAmministrativa,
        FlowType.VariazioneTecnicaLavori,
        FlowType.VariazioneTecnicaLavoriAmministrativa,
        FlowType.VariazioneTecnicaLavoriDiminuzionePotenza,
        FlowType.VariazioneTecnicaLavoriDiminuzionePotenzaAmministrativa,
        /* DROP 2 */
        FlowType.VariazioneTecnicaLavoriPreventivo,
        FlowType.VariazioneTecnicaLavoriPreventivoAmministrativa,
        FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenza,
        FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenzaAmministrativa,
        FlowType.VariazioneTecnicaLavoriPreventivoVariazioneFasi,
        FlowType.VariazioneTecnicaLavoriPreventivoVariazioneFasiAmministrativa,
        FlowType.VariazioneTecnicaLavoriPreventivoVariazioneTensione,
        FlowType.VariazioneTecnicaLavoriPreventivoVariazioneTensioneAmministrativa,
        /* VT 2.1 */
        FlowType.VariazioneTecnicaLavoriPreventivoModificaImpianto,
        FlowType.VariazioneTecnicaLavoriPreventivoModificaImpiantoAmministrativa,
    ],
    // VT DROP 3
    [MacroFlowType.VariazioneParametri]: [
        FlowType.VariazioneAteco,
        FlowType.VariazioneTipologiaFornituraGas,
        FlowType.VariazioneIndirizzo,
        FlowType.AllineaTipologiaUtilizzoDistributore,
    ],
    [MacroFlowType.Cessazione]: [FlowType.Cessazione, FlowType.CessazioneAmministrativa],
    [MacroFlowType.ScontoStandalone]: FlowType.ScontoStandalone,

    [MacroFlowType.Administrative]: [
        FlowType.SwitchInAmmRecupero2A,
        FlowType.SwitchInAmmRecupero2B,
        FlowType.SwitchInAmmRecupero2C,
        FlowType.SwitchInAmmDelibera153,
        FlowType.VolturaCambioFornitoreAmmRecupero2A,
        FlowType.VolturaCambioFornitoreAmmRecupero2B,
        FlowType.VolturaCambioFornitoreAmmRecupero2C,
        FlowType.AttivazioneAmministrativa,
        FlowType.AttivazioneAmmGasA01,
        FlowType.AttivazioneAmmPwrS01,
        FlowType.AttivazioneAmmA40,
        FlowType.AttivazioneAmmPN1,
        FlowType.AttivazionePwrAmmA01,
        FlowType.AttivazioneAmmN02,
        FlowType.AttivazioneAmmRecupero,
        FlowType.AttivazioneAmmRecuperoA01,
        FlowType.AttivazioneAmmRecuperoS01,
        FlowType.CPAmministrativo,
        FlowType.CPRetroattivoExNovo,
        FlowType.CPRetroattivoAssetCessato,
        FlowType.VolturaAmmSiProvisioning,
        FlowType.VolturaAmmNoProvisioning,

        FlowType.VariazioneTecnicaVerificheAmministrativa,
        FlowType.VariazioneTecnicaLavoriAmministrativa,
        FlowType.VariazioneTecnicaLavoriDiminuzionePotenzaAmministrativa,
        FlowType.VariazioneTecnicaLavoriPreventivoAmministrativa,
        FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenzaAmministrativa,
        FlowType.VariazioneTecnicaLavoriPreventivoVariazioneFasiAmministrativa,
        FlowType.VariazioneTecnicaLavoriPreventivoVariazioneTensioneAmministrativa,
        FlowType.VariazioneTecnicaLavoriPreventivoModificaImpiantoAmministrativa,
        FlowType.CessazioneAmministrativa,
    ],
    [MacroFlowType.AggregaDisaggrega]: [FlowType.Aggrega, FlowType.Disaggrega],
    [MacroFlowType.Vip]: [
        FlowType.SwitchInAmmVip,
        FlowType.VolturaCambioFornitoreVip,
        FlowType.AttivazioneAmmVip,
        FlowType.AttivazioneAmmVipPowerS01,
        FlowType.AttivazioneAmmVipGasA01,
        FlowType.VolturaAmmVip,
        FlowType.AttivazioneVipA40,
        FlowType.AttivazioneVipPN1,
        FlowType.AttivazionePwrVipA01,
        FlowType.AttivazioneVipN02,
    ],
    [MacroFlowType.Recovery]: [
        FlowType.SwitchInAmmRecupero2A,
        FlowType.SwitchInAmmRecupero2B,
        FlowType.SwitchInAmmRecupero2C,
        FlowType.SwitchInAmmDelibera153,
        FlowType.VolturaCambioFornitoreAmmRecupero2A,
        FlowType.VolturaCambioFornitoreAmmRecupero2B,
        FlowType.VolturaCambioFornitoreAmmRecupero2C,
        FlowType.AttivazioneAmmRecupero,
        FlowType.AttivazioneAmmRecuperoA01,
        FlowType.AttivazioneAmmRecuperoS01,
    ],
    [MacroFlowType.Preventivi]: [
        /* DROP 2 */
        FlowType.VariazioneTecnicaLavoriPreventivo,
        FlowType.VariazioneTecnicaLavoriPreventivoAmministrativa,
        FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenza,
        FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenzaAmministrativa,
        FlowType.VariazioneTecnicaLavoriPreventivoVariazioneFasi,
        FlowType.VariazioneTecnicaLavoriPreventivoVariazioneFasiAmministrativa,
        FlowType.VariazioneTecnicaLavoriPreventivoVariazioneTensione,
        FlowType.VariazioneTecnicaLavoriPreventivoVariazioneTensioneAmministrativa,
        /* VT 2.1 */
        FlowType.VariazioneTecnicaLavoriPreventivoModificaImpianto,
        FlowType.VariazioneTecnicaLavoriPreventivoModificaImpiantoAmministrativa,
        // Preventivi Attivazioni
        FlowType.AttivazionePN1,
        FlowType.AttivazioneAmmPN1,
        FlowType.AttivazioneVipPN1,
        FlowType.AttivazioneN02,
        FlowType.AttivazioneAmmN02,
        FlowType.AttivazioneVipN02,
    ],
    [MacroFlowType.Morosity]: [
        FlowType.Interruzione,
        FlowType.InterruzioneAmministrativa,
        FlowType.Sospensione,
        FlowType.SospensioneAmministrativa,
        FlowType.Riapertura,
        FlowType.RiaperturaAmministrativa,
        FlowType.CA1,
        FlowType.CA2,
        FlowType.CA3,
        FlowType.CA4,
        FlowType.CA5,
        FlowType.CA7,
        FlowType.CAE1,
        FlowType.CAE3,
        FlowType.CAE4,
        FlowType.CAE5,
        FlowType.CAE7,
        FlowType.CAE8,
        FlowType.CAE8RIFVOLT,
        FlowType.CA1AMM,
        FlowType.CA2AMM,
        FlowType.CA3AMM,
        FlowType.CA4AMM,
        FlowType.CA5AMM,
        FlowType.CA7AMM,
        FlowType.CAE1AMM,
        FlowType.CAE3AMM,
        FlowType.CAE4AMM,
        FlowType.CAE5AMM,
        FlowType.CAE7AMM,
        FlowType.CAE8AMM,
        FlowType.CAE8RIFVOLTAMM,
    ],
    [MacroFlowType.Extracommodity]: [FlowType.ExtraCommoditySale, FlowType.CessazioneExtracommodity],
};

export const TERMINATION_FLOW_TYPES = [FlowType.Cessazione, FlowType.CessazioneAmministrativa];

export const ACTIVATION_POWER_FLOW_TYPES = [
    FlowType.AttivazionePwrS01,
    FlowType.AttivazionePwrA01,
    FlowType.AttivazioneN02,
];

export const ACTIVATION_GAS_FLOW_TYPES = [FlowType.AttivazioneGas, FlowType.AttivazioneA40, FlowType.AttivazionePN1];

export const ACTIVATION_FLOW_TYPES: FlowType[] = Object.keys({
    ...PROCESS_OPERATION_MAPPINGS[AptSaleabilityOperationType.Attivazione],
    ...PROCESS_OPERATION_MAPPINGS[AptSaleabilityOperationType.AttivazioneAmm],
}) as FlowType[];
export const SWITCH_IN_FLOW_TYPES: FlowType[] = Object.keys(
    PROCESS_OPERATION_MAPPINGS[AptSaleabilityOperationType.SwitchIn]
) as FlowType[];
export const VOLTURA_FLOW_TYPES: FlowType[] = Object.keys(
    PROCESS_OPERATION_MAPPINGS[AptSaleabilityOperationType.Voltura]
) as FlowType[];
export const ACTIVATION_SALES_PROCESSES: AptSalesProcess[] = Object.values(
    PROCESS_OPERATION_MAPPINGS[AptSaleabilityOperationType.Attivazione]
).filter(
    (salesProcess, i, salesProcesses) => salesProcess && salesProcesses.indexOf(salesProcess) === i
) as AptSalesProcess[];

export const D365_OPERATION_MODE: {
    [key in FlowType]?: D365OperationMode;
} = {
    [FlowType.SwitchIn]: D365OperationMode.SwitchIn,
    [FlowType.VolturaCambioFornitore]: D365OperationMode.SwitchInConVoltura,
    [FlowType.VolturaCambioFornitoreVip]: D365OperationMode.VolturaCambioFornitoreVip,
    [FlowType.VolturaCambioFornitoreAmmRecupero2A]: D365OperationMode.VolturaCambioFornitoreRecupero,
    [FlowType.VolturaCambioFornitoreAmmRecupero2B]: D365OperationMode.VolturaCambioFornitoreRecupero,
    [FlowType.VolturaCambioFornitoreAmmRecupero2C]: D365OperationMode.VolturaCambioFornitoreRecupero,
    [FlowType.SwitchInAmmVip]: D365OperationMode.SwitchInAmmVip,
    [FlowType.SwitchInAmmRecupero2B]: D365OperationMode.SwitchInAmmRecupero,
    [FlowType.SwitchInAmmRecupero2A]: D365OperationMode.SwitchInAmmRecupero,
    [FlowType.SwitchInAmmRecupero2C]: D365OperationMode.SwitchInAmmRecupero,
    [FlowType.SwitchInAmmDelibera153]: D365OperationMode.SwitchInAmmRipensamento,

    [FlowType.Voltura]: D365OperationMode.Voltura,
    [FlowType.VolturaAmmVip]: D365OperationMode.VolturaAmmVip,
    [FlowType.VolturaAmmSiProvisioning]: D365OperationMode.VolturaAmmSiProvisioning,
    [FlowType.VolturaAmmNoProvisioning]: D365OperationMode.VolturaAmmNoProvisioning,
    [FlowType.VolturaAmmIncorporazioneSocietaria]: D365OperationMode.VolturaAmmIncorporazioneSocietaria,

    [FlowType.CPRetroattivoExNovo]: D365OperationMode.CambioProdottoRetroattivoExNovo,
    [FlowType.CPAmministrativo]: D365OperationMode.CambioProdottoRetroattivo,
    [FlowType.CPDelibera]: D365OperationMode.CambioProdottoDelibera,
    [FlowType.CPCommerciale]: D365OperationMode.CambioProdotto,
    [FlowType.CPRetroattivoAssetCessato]: D365OperationMode.CambioProdottoRetroattivoAssetCessato,

    [FlowType.DomiciliazioneAttivazione]: D365OperationMode.Domiciliazione,
    [FlowType.DomiciliazioneModifica]: D365OperationMode.Domiciliazione,
    [FlowType.DomiciliazioneRevoca]: D365OperationMode.Domiciliazione,
    [FlowType.DomiciliazioneAttivazioneInserimentoPaymentTool]: D365OperationMode.Domiciliazione,
    [FlowType.DomiciliazioneModificaInserimentoPaymentTool]: D365OperationMode.Domiciliazione,
    [FlowType.ScontoStandalone]: D365OperationMode.ScontoStandalone,

    [FlowType.Attivazione]: D365OperationMode.Attivazione,
    [FlowType.AttivazioneGas]: D365OperationMode.Attivazione,

    [FlowType.AttivazioneA40]: D365OperationMode.AttivazioneA40,
    [FlowType.AttivazionePN1]: D365OperationMode.AttivazionePN1,
    [FlowType.AttivazioneN02]: D365OperationMode.AttivazioneN02,
    [FlowType.AttivazionePwrA01]: D365OperationMode.AttivazioneA01,
    [FlowType.AttivazionePwrS01]: D365OperationMode.Attivazione,

    // Attivazione Complessa Manuale
    [FlowType.AttivazioneAmmN02]: D365OperationMode.AttivazioneAmmN02,
    [FlowType.AttivazionePwrAmmA01]: D365OperationMode.AttivazioneAmmA01,
    [FlowType.AttivazioneAmmA40]: D365OperationMode.AttivazioneAmmA40,
    [FlowType.AttivazioneAmmPN1]: D365OperationMode.AttivazioneAmmPN1,

    // Attivazione Complessa Vip
    [FlowType.AttivazioneVipN02]: D365OperationMode.AttivazioneVipN02,
    [FlowType.AttivazionePwrVipA01]: D365OperationMode.AttivazioneVipA01,
    [FlowType.AttivazioneVipA40]: D365OperationMode.AttivazioneVipA40,
    [FlowType.AttivazioneVipPN1]: D365OperationMode.AttivazioneVipPN1,

    [FlowType.AttivazioneAmmVip]: D365OperationMode.AttivazioneAmmVIP,
    [FlowType.AttivazioneAmmVipGasA01]: D365OperationMode.AttivazioneAmmVIP,
    [FlowType.AttivazioneAmmVipPowerS01]: D365OperationMode.AttivazioneAmmVIP,
    [FlowType.AttivazioneAmmRecupero]: D365OperationMode.AttivazioneAmmRecupero,
    [FlowType.AttivazioneAmmRecuperoA01]: D365OperationMode.AttivazioneAmmRecupero,
    [FlowType.AttivazioneAmmRecuperoS01]: D365OperationMode.AttivazioneAmmRecupero,
    [FlowType.AttivazioneAmministrativa]: D365OperationMode.ProvisioningManuale,
    [FlowType.AttivazioneAmmGasA01]: D365OperationMode.ProvisioningManuale,
    [FlowType.AttivazioneAmmPwrS01]: D365OperationMode.ProvisioningManuale,

    [FlowType.VariazioneAteco]: D365OperationMode.VariazioneAteco,
    [FlowType.VariazioneTipologiaFornituraGas]: D365OperationMode.VariazioneTipologiaFornituraGas,
    [FlowType.VariazioneIndirizzo]: D365OperationMode.VariazioneIndirizzo,
    [FlowType.AllineaTipologiaUtilizzoDistributore]: D365OperationMode.AllineaTipologiaUtilizzoDistributore,
    [FlowType.Cessazione]: D365OperationMode.Cessazione,
    [FlowType.CessazioneAmministrativa]: D365OperationMode.CessazioneAmministrativa,
    [FlowType.CambioTipologiaFornitura]: D365OperationMode.CambioTipologiaFornitura,

    [FlowType.Aggrega]: D365OperationMode.Aggrega,
    [FlowType.Disaggrega]: D365OperationMode.Disaggrega,
    [FlowType.ExtraCommoditySale]: D365OperationMode.Extracommodity,
    [FlowType.CessazioneExtracommodity]: D365OperationMode.CessazioneExtracommodity,
    [FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenza]: D365OperationMode.VariazioneAumentoPotenza,
};

export const MASTERSHIP_TO_D365MIGRATED_MAP: {
    [key in MastershipType]: D365AccountMigrated;
} = {
    [MastershipType.D365]: D365AccountMigrated.D365,
    [MastershipType.Siebel]: D365AccountMigrated.SIEBEL,
    [MastershipType.SiebelBloccato]: D365AccountMigrated.SIEBEL_BLOCCATO,
    [MastershipType.New]: D365AccountMigrated.NOT_SET,
};

export const INADMISSIBILITY_TO_INADMISSIBILITY_FILTER_TYPE: {
    [key in AptSalesInadmissibilityProcess]: InadmissibilityFilter;
} = {
    [AptSalesInadmissibilityProcess.All]: { type: InadmissibilityFilterType.All, subType: null },
    [AptSalesInadmissibilityProcess.InadmissibilityOrder]: {
        type: InadmissibilityFilterType.Inadmissible,
        subType: InadmissibilityFilterSubType.All,
    },
    [AptSalesInadmissibilityProcess.ReworkableInadmissibilityOrder]: {
        type: InadmissibilityFilterType.Inadmissible,
        subType: InadmissibilityFilterSubType.Reworkable,
    },
};

export const FLOWTYPE_AMM_VIP_REC_CFG: {
    [key in FlowTypeMode]?: FlowType;
}[] = [
    {
        commercial: FlowType.Attivazione,
        admin: FlowType.AttivazioneAmministrativa,
        vip: FlowType.AttivazioneAmmVip,
        recovery: FlowType.AttivazioneAmmRecupero,
    },
    {
        commercial: FlowType.AttivazioneGas,
        admin: FlowType.AttivazioneAmmGasA01,
        vip: FlowType.AttivazioneAmmVipGasA01,
        recovery: FlowType.AttivazioneAmmRecuperoA01,
    },
    {
        commercial: FlowType.AttivazionePwrS01,
        admin: FlowType.AttivazioneAmmPwrS01,
        vip: FlowType.AttivazioneAmmVipPowerS01,
        recovery: FlowType.AttivazioneAmmRecuperoS01,
    },
    {
        commercial: FlowType.AttivazioneA40,
        admin: FlowType.AttivazioneAmmA40,
        vip: FlowType.AttivazioneVipA40,
        recovery: FlowType.AttivazioneAmmRecuperoA01,
    },
    {
        commercial: FlowType.AttivazionePN1,
        admin: FlowType.AttivazioneAmmPN1,
        vip: FlowType.AttivazioneVipPN1,
        recovery: FlowType.AttivazioneAmmRecuperoA01,
    },
    {
        commercial: FlowType.AttivazioneN02,
        admin: FlowType.AttivazioneAmmN02,
        vip: FlowType.AttivazioneVipN02,
        recovery: FlowType.AttivazioneAmmRecuperoS01,
    },
    {
        commercial: FlowType.AttivazionePwrA01,
        admin: FlowType.AttivazionePwrAmmA01,
        vip: FlowType.AttivazionePwrVipA01,
        recovery: FlowType.AttivazioneAmmRecuperoS01,
    },
];

export enum FlowTypeMode {
    Commercial = 'commercial',
    Admin = 'admin',
    Vip = 'vip',
    Recovery = 'recovery',
}

export const FLOWTYPE_TO_SCRIPT_DOC_TYPE_MAP: {
    [key in FlowType]?: ScriptDocType;
} = {
    [FlowType.DomiciliazioneAttivazione]: ScriptDocType.Domiciliazione,
    [FlowType.DomiciliazioneAttivazioneInserimentoPaymentTool]: ScriptDocType.Domiciliazione,
    [FlowType.DomiciliazioneModifica]: ScriptDocType.Domiciliazione,
    [FlowType.DomiciliazioneModificaInserimentoPaymentTool]: ScriptDocType.Domiciliazione,
    [FlowType.Cessazione]: ScriptDocType.Cessazione,
};
