export enum PowerConsumptionUse {
    ConsumoBasso = 'CONSUMO BASSO',
    ConsumoMedio = 'CONSUMO MEDIO',
    ConsumoAlto = 'CONSUMO ALTO',
}

export enum GasConsumptionUse {
    Cottura = 'COTTURA',
    AcquaCalda = 'ACQUA CALDA',
    Riscaldamento = 'RISCALDAMENTO',
    // AltriUsi = 'ALTRI USI',
    // Dichiarato = 'DECLARED',
    // AltriUsiTecnologico = 'USO TECNOLOGICO',
}

export enum GasConsumptionUseMB {
    usoNonProduttivo = 'ALTRI USI',
    usoAttivitaProduttiva = 'ALTRI USI TECNOLOGICO',
}

export type PowerTypeOfUse = PowerConsumptionUse;
export type GasTypeOfUse = GasConsumptionUse[] | GasConsumptionUseMB;
export type TypeOfUse = PowerTypeOfUse | GasTypeOfUse;
