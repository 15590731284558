import { FieldMap } from '../../../utility/functions/map-fields';
import { FetchXmlQuery } from './fetch-xml-manager';

export function getDocumentaryCheckWorkOrderXmlQuery(assetExternalId: string): FetchXmlQuery {
    // Recupera i dati legati ai work order di credito al consumo
    const entity = 'egl_workorderdocumentarycheck';
    return {
        entity,
        query: `
            <?xml version="1.0" encoding="UTF-8"?>
            <fetch top="100">
                <entity name="${entity}">
                    <attribute name="createdon" />
                    <attribute name="modifiedon" />
                    <attribute name="statecode" />
                    <attribute name="egl_cpqquoteid" />
                    <attribute name="egl_envelopecode" />
                    <attribute name="egl_name" />
                    <attribute name="egl_oticode" />
                    <attribute name="egl_parentassetexternalid" />
                    <filter>
                        <condition attribute="egl_assetexternalid" operator="eq" value="${assetExternalId}" />
                    </filter>
                    <link-entity name="account" from="accountid" to="egl_accountid" alias="Account">
                        <attribute name="egl_taxcode" />
                    </link-entity>
                    <link-entity name="egl_workorderdocumentarychecksubstatus" from="egl_workorderdocumentarychecksubstatusid" to="egl_workorderdocumentarychecksubstatusid" alias="Substatus">
                        <attribute name="egl_code" />
                        <attribute name="egl_name" />
                    </link-entity>
                </entity>
            </fetch>
        `,
    };
}

export interface D365DocumentaryCheckWorkOrder {
    egl_name: string; // Numero WO
    ['statecode@OData.Community.Display.V1.FormattedValue']: string; // Stato WO
    ['Substatus.egl_name']: string; // Sottostato WO
    egl_envelopecode: string; // Codice plico
    egl_workorderdocumentarycheckid: string; // DocumentaryId
    ['Account.egl_taxcode']: string; // Codice fiscale
    egl_oticode: string; // ID OTI
    egl_cpqquoteid: string; // ID QUOTE
    createdon: string; // Data creazione
    modifiedon: string; // Data ultimo aggiornamento
}

export interface SupDocumentaryCheckWorkOrder {
    workOrderNumber: string; // Original: egl_name - Numero WO
    workorderdocumentarycheckid: string; // Original: egl_workorderdocumentarycheckid - 
    workOrderStatus: string; // Original: statecode - Stato
    workOrderSubStatus: string; // Original: egl_workorderdocumenttemplate.egl_name - Sottostato
    envelopeCode: string; // Original: egl_envelopecode - Codice plico
    taxCode: string; // Original: account.egl_taxcode - Codice fiscale
    otiId: string; // Original: egl_oticode - ID OTI
    quoteId: string; // Original: egl_cpqquoteid - ID QUOTE
    creationDate: string; // Original: createdon - Data creazione
    lastUpdateDate: string; // Original: modifiedon - Data ultimo aggiornamento
}

export const D365ToSupDocumentaryCheckWorkOrderFieldMap: FieldMap<
    D365DocumentaryCheckWorkOrder,
    SupDocumentaryCheckWorkOrder
>[] = [
    { sourceField: 'egl_name', resultField: 'workOrderNumber' },
    { sourceField: 'statecode@OData.Community.Display.V1.FormattedValue', resultField: 'workOrderStatus' },
    {
        sourceField: 'Substatus.egl_name',
        resultField: 'workOrderSubStatus',
    },
    { sourceField: 'egl_envelopecode', resultField: 'envelopeCode' },
    { sourceField: 'egl_workorderdocumentarycheckid', resultField: 'workorderdocumentarycheckid' },
    { sourceField: 'Account.egl_taxcode', resultField: 'taxCode' },
    { sourceField: 'egl_oticode', resultField: 'otiId' },
    { sourceField: 'egl_cpqquoteid', resultField: 'quoteId' },
    { sourceField: 'createdon', resultField: 'creationDate' },
    { sourceField: 'modifiedon', resultField: 'lastUpdateDate' },
];
