import { CartService, LineItemService } from '@congacommerce/ecommerce';
import _ from 'lodash';
import { combineLatest, of } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { DEFAULT_GAS_POTENTIAL } from '../../../../modules/common/default.values';
import { OrderEntryProvider } from '../../../../modules/common/order-entry/providers/order-entry-provider';
import {
    setV2EffectiveDate,
    setV2ProductByIdx,
    setV2ProductFilled,
    setV2RemoteReadingStatus,
    setV2TechnicalDetailsGas,
} from '../../../../store/actions/order-entry-v2.actions';
import {
    setAgencyBranchForMonitoring,
    setAgentBranch,
    setNumeroPlico,
    setPowerConsumption,
    setQuoteStateModel,
} from '../../../../store/actions/order-entry.actions';
import { setContact, setLead } from '../../../../store/actions/user.actions';
import { FlowType, MacroFlowType } from '../../../../store/models/flow-type';
import { PowerConsumption } from '../../../../store/models/order-entry-state';
import { GasTechnicalDetails } from '../../../../store/models/order-entry-state_v2';
import {
    v2SelectCurrentProduct,
    v2SelectFirma,
    v2SelectFlowType,
    v2SelectInstantAcceptanceFlag,
    v2SelectInvoiceShippingMethod,
    v2SelectVisibleProducts,
} from '../../../../store/selectors/order-entry-v2.selectors';
import { selectFlowType } from '../../../../store/selectors/order-entry.selectors';
import {
    selectCartSegment,
    selectContactLead,
    selectCurrentVirtualAgent,
} from '../../../../store/selectors/user.selectors';
import { AptBillType } from '../../../enums/apttus/apt-bill-type';
import { AptCommodityType } from '../../../enums/apttus/apt-commodity-typeof-sale';
import { AptQuoteStatus } from '../../../enums/apttus/apt-quote-status';
import { AptQuoteSubStatus } from '../../../enums/apttus/apt-quote-sub-status';
import { AptSaleabilityOperationType } from '../../../enums/apttus/apt-sales-process';
import { D365CustomerSegment } from '../../../enums/d365/d365-customer-segment';
import { D365SignatureType } from '../../../enums/d365/d365-signature-type';
import { GasConsumptionUse } from '../../../enums/shared/type-of-usage.type';
import { flowTypeToD365OperationMode, salesProcessOrFlowTypeToOperationType } from '../../../functions/remap.functions';
import { EglItemGroup } from '../../../functions/transformation.functions';
import {
    containsProductComplex,
    containsProductMaintenance,
    flowTypeUtil,
    isProductAppurtenance,
} from '../../../functions/verifications.functions';
import { StatusResponse } from '../../../interfaces/base-api-response';
import { EglCartItemLight } from '../../../models/apttus/tables/cart/egl-cart-item-light';
import { HistorizedAgencyBranch } from '../../../models/user/historized-agency-branch';
import { CommonProvider } from '../../../providers/common-provider';
import { CreditCheckProvider, ScoreCardCheckTypes } from '../../../providers/credit-check.provider';
import { CartToQuoteService } from '../../apttus/cart-to-quote/cart-to-quote.service';
import { EglAddProductToCartService } from '../../apttus/utility/egl-add-product-to-cart.service';
import { D365Service } from '../../d365/d365.service';
import { DataOwnershipChangeService } from '../data-ownership-change.service';
import { PrivateConfigurationService } from '../private-configuration.service';
import { UtilityService } from '../utility.service';
import { skipAppurtenances } from './dragon-router-skipping-rules';
import { RailwayFn, RailwayFnObs } from './dragon-router.type';

export const dragonIsCustomerRegistered: RailwayFnObs = ({ injector }) =>
    injector
        .get(CommonProvider)
        .isCustomerRegistered()
        .pipe(map(() => null));

export const dragonSaveQuoteV2: RailwayFnObs = ({ injector }) =>
    injector
        .get(EglAddProductToCartService)
        .eglAddMultiProductToCart()
        .pipe(
            mergeMap(() => injector.get(CartToQuoteService).saveQuoteV2()),
            map((quote) => ({
                qId: quote.Id,
                qName: quote.Name,
                qAccountId: quote.accountId,
            })),
        );

export const dragonSaveQuoteLavoriVerificheV2: RailwayFnObs = ({ injector, params: { workOrderId, gtwDossierCode } }) =>
    injector
        .get(CartToQuoteService)
        .saveQuoteV2()
        .pipe(
            map((quote) => ({
                qId: quote.Id,
                qName: quote.Name,
                workOrderId,
                gtwDossierCode,
            })),
        );

export const dragonSaveQuotePreventivo: RailwayFnObs = ({
    injector,
    params: { cartToQuotetype, workOrderId, gtwDossierCode },
    eglState,
}) =>
    dragonSaveQuoteV2({ injector, params: { cartToQuotetype }, eglState }).pipe(
        mergeMap(({ qId, qName }) =>
            injector
                .get(D365Service)
                .saveQuoteIdInd365(workOrderId, qId, qName, gtwDossierCode)
                .pipe(
                    map(() => ({
                        qId,
                        qName,
                        workOrderId,
                        gtwDossierCode,
                    })),
                ),
        ),
    );

export const dragonSaveQuotePreventivoAumPot: RailwayFnObs = ({
    injector,
    params: { cartToQuotetype, workOrderId, gtwDossierCode },
    eglState,
}) =>
    dragonSaveQuoteV2({ injector, params: { cartToQuotetype }, eglState }).pipe(
        mergeMap(({ qId, qName }) =>
            injector
                .get(D365Service)
                .saveQuoteIdInd365AumPot(workOrderId, qId, qName, eglState, gtwDossierCode)
                .pipe(
                    map(() => ({
                        qId,
                        qName,
                        workOrderId,
                        gtwDossierCode,
                    })),
                ),
        ),
    );

export const dragonGetContractCodeNoSign: RailwayFnObs = ({ injector, eglState }) =>
    combineLatest([eglState.select(selectCurrentVirtualAgent), eglState.select(selectFlowType)]).pipe(
        mergeMap(([virtualAgent, flowType]) =>
            injector
                .get(D365Service)
                .getCodicePlico$(
                    virtualAgent.VirtualAgency.Channel.Name,
                    D365SignatureType.NessunaFirma,
                    flowTypeToD365OperationMode(flowType),
                )
                .pipe(
                    tap((codicePlico) =>
                        eglState.dispatch(
                            setNumeroPlico({
                                payload: {
                                    code: codicePlico?.ContractCode,
                                    channelName: virtualAgent.VirtualAgency?.Channel?.Name,
                                    signatureType: D365SignatureType.NessunaFirma,
                                },
                            }),
                        ),
                    ),
                ),
        ),
        map(() => null),
    );

export const dragonGetContractCodeNoSignAndAgencyBranch: RailwayFnObs = ({ injector, eglState }) =>
    combineLatest([
        dragonGetContractCodeNoSign({ injector, eglState }),
        eglState.select(selectCurrentVirtualAgent).pipe(
            take(1),
            tap((currentVirtualAgent) => {
                eglState.dispatch(setAgencyBranchForMonitoring({ a: currentVirtualAgent?.CurrentAgencyBranch?.Name }));
            }),
            mergeMap((currentVirtualAgent) =>
                injector.get(D365Service).getAgencyBranch(currentVirtualAgent, new Date().toISOString()),
            ),
            tap(({ Result }) => {
                if (Result !== 'OK') {
                    of(null);
                }
            }),
            map(({ AgencyBranch }) => JSON.parse(AgencyBranch) as HistorizedAgencyBranch),
            tap((agencyBranch) => {
                eglState.dispatch(setAgentBranch({ h: agencyBranch }));
            }),
            catchError((err: Error) => {
                return of(null);
            }),
            map(() => null),
        ),
    ]);

export const setQuoteStatus: RailwayFn = ({ injector, params: { quoteStatus, quoteSubStatus }, eglState }) => {
    eglState.dispatch(
        setQuoteStateModel({
            s: {
                ...(quoteStatus && { status: quoteStatus }),
                ...(quoteSubStatus && { subStatus: quoteSubStatus }),
            },
        }),
    );
    return null;
};

export const dragonSetQuoteStatus: (quoteStatus: AptQuoteStatus, quoteSubStatus?: AptQuoteSubStatus) => RailwayFn =
    (quoteStatus, quoteSubStatus) =>
    ({ params, ...args }) =>
        setQuoteStatus({ ...args, params: { quoteStatus, quoteSubStatus, ...(params || {}) } });

export const dragonSetQuoteStatusSubstatus: RailwayFn = ({ injector, eglState }) =>
    combineLatest([
        eglState.select(v2SelectFlowType),
        eglState.select(v2SelectFirma),
        eglState.select(v2SelectInstantAcceptanceFlag),
        eglState.select(v2SelectInvoiceShippingMethod()),
        eglState.select(v2SelectCurrentProduct()),
    ]).pipe(
        map(([flowType, signature, isInstantAcceptance, shippingMethod, product]) => {
            const SIGNATURE_STATEMODEL_MAP: {
                [key in D365SignatureType]?: {
                    status: AptQuoteStatus;
                    subStatus: AptQuoteSubStatus | null;
                };
            } = {
                [D365SignatureType.Doi]: {
                    status: AptQuoteStatus.InAttesaFirma,
                    subStatus: AptQuoteSubStatus.FirmaDoi,
                },
                [D365SignatureType.DoiLight]: {
                    status: AptQuoteStatus.InAttesaFirma,
                    subStatus: AptQuoteSubStatus.FirmaDoi,
                },
                [D365SignatureType.Grafometrica]: {
                    status: AptQuoteStatus.InAttesaFirma,
                    subStatus: AptQuoteSubStatus.FirmaGrafometrica,
                },
                [D365SignatureType.PaperPrintedBySystem]: {
                    status: AptQuoteStatus.InAttesaFirma,
                    subStatus: AptQuoteSubStatus.FirmaSemidigital,
                },
                [D365SignatureType.Cartacea]: {
                    status: AptQuoteStatus.Firmato,
                    subStatus: null,
                },
                [D365SignatureType.VocalOrder]: {
                    status: AptQuoteStatus.Firmato,
                    subStatus: null,
                },
                [D365SignatureType.NessunaFirma]: {
                    status: AptQuoteStatus.Firmato,
                    subStatus: null,
                },
            };

            const TERMINATION_AMMINISTRATIVA: {
                [key in FlowType]?: {
                    status: AptQuoteStatus;
                };
            } = {
                [FlowType.CessazioneAmministrativa]: { status: AptQuoteStatus.Confermato },
            };

            const DOMICILIATION_STATEMODEL_MAP: {
                [key in FlowType]?: {
                    status: AptQuoteStatus;
                };
            } = {
                [FlowType.DomiciliazioneAttivazione]: { status: AptQuoteStatus.Firmato },
                [FlowType.DomiciliazioneModifica]: { status: AptQuoteStatus.Firmato },
                [FlowType.DomiciliazioneRevoca]: { status: AptQuoteStatus.Firmato },
            };

            const isAumentoPotenza = flowTypeUtil(flowType).equalTo(
                FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenza,
                FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenzaAmministrativa,
            );
            const quoteStateModelAumPot = getStatusSubStatusAumPot(
                signature?.d365SignatureType,
                isInstantAcceptance,
                shippingMethod,
                product?.prices?.predetermined,
            );
            const quoteStateModel =
                TERMINATION_AMMINISTRATIVA[flowType] ||
                SIGNATURE_STATEMODEL_MAP[signature?.d365SignatureType] ||
                DOMICILIATION_STATEMODEL_MAP[flowType];

            eglState.dispatch(
                setQuoteStateModel(isAumentoPotenza ? { s: quoteStateModelAumPot } : { s: quoteStateModel }),
            );

            return null;
        }),
    );

export const scoreCard: RailwayFn = ({ injector }) =>
    injector.get(CreditCheckProvider).orderEntryScoreCard([ScoreCardCheckTypes.ScoreCard]);

export const PROCESS_STATE_ENRICHER: {
    [key in MacroFlowType]?: RailwayFn;
} = {
    [MacroFlowType.VolturaSwitchIn]: ({ injector, params, eglState }) => {
        eglState.dispatch(
            setV2EffectiveDate({
                effectiveDate: injector.get(UtilityService).addMomentBusinessDays(new Date(), 2).toDate(),
                immediateEffect: true,
            }),
        );
        return params;
    },
};

export const multiSupplyProductFinalizer: RailwayFn = ({ eglState, params }) => {
    eglState.dispatch(setV2ProductFilled({ productIdx: params?.itemId, isFilled: true }));
    return {
        ...(params || {}),
        itemId: null,
    };
};

export const multiSupplySingleProductInitializer: RailwayFn = ({ eglState, params }) =>
    eglState.select(v2SelectVisibleProducts('ALL')).pipe(
        map((products) => products?.length === 1),
        map((singleProduct) =>
            singleProduct
                ? {
                      ...(params || {}),
                      itemId: 0,
                  }
                : params,
        ),
    );

export const maintenanceAppointmentCycleInitializer: RailwayFn = ({ eglState, params }) =>
    eglState.select(v2SelectVisibleProducts('ALL')).pipe(
        take(1),
        map(
            (products) =>
                products
                    .filter(
                        (p) =>
                            containsProductMaintenance(p.productType) ||
                            (containsProductComplex(p.productType) &&
                                p.technicalDetails?.characteristics?.some((c) => c.Name === 'Sopralluogo standard') &&
                                _.isEmpty(p?.configurations?.relatedOffer)),
                    )
                    .map((p) => p.idx)[0],
        ),
        map((itemId) => ({
            itemId: itemId || 0,
        })),
    );

export const dispatchDefaultConsumptionForAppurtenancesCommodity: RailwayFnObs = ({ injector, eglState, params }) =>
    combineLatest([
        eglState.select(selectFlowType),
        eglState.select(v2SelectCurrentProduct(params?.itemId)),
        eglState.select(selectCartSegment),
        skipAppurtenances({ eglState, params }),
    ]).pipe(
        map(([flowType, product, cartSegment]) => {
            if (
                !!product &&
                cartSegment === D365CustomerSegment.Residential &&
                flowTypeUtil(flowType).inMacroFlowTypes(
                    MacroFlowType.SwitchIn,
                    MacroFlowType.Attivazione,
                    MacroFlowType.AttivazioneComplessa,
                    MacroFlowType.Voltura,
                    MacroFlowType.VolturaSwitchIn,
                ) &&
                isProductAppurtenance(product)
            ) {
                const powerConsumption = new PowerConsumption(1200, 1200);
                eglState.dispatch(setPowerConsumption({ c: powerConsumption }));
            }
            return null;
        }),
    );

export const productConfigStepInitializer: RailwayFn = ({ params, injector }) => {
    return injector
        .get(CartService)
        .getMyCart()
        .pipe(
            map((cart) => LineItemService.groupItems(cart.LineItems)?.[0] as unknown),
            map((groupped: EglItemGroup<EglCartItemLight>) =>
                groupped
                    ? {
                          cartItem: groupped.MainLine?.Id,
                          id: groupped.MainLine?.Product?.Id,
                      }
                    : params,
            ),
        );
};

export const remoteMeterCheck: RailwayFn = ({ eglState, injector }) => {
    return eglState.select(v2SelectCurrentProduct()).pipe(
        take(1),
        mergeMap((product) =>
            product?.powerOrGas === AptCommodityType.Gas
                ? injector
                      .get(OrderEntryProvider)
                      .getRemoteMeterCheck(product?.podPdr)
                      .pipe(
                          tap((readingStatus) =>
                              eglState.dispatch(setV2RemoteReadingStatus({ status: readingStatus })),
                          ),
                      )
                : of(null),
        ),
        map(() => true),
    );
};

export const dispatchDefaultPotentialityForActivationGasCommodity: RailwayFnObs = ({ injector, eglState, params }) =>
    combineLatest([
        eglState.select(selectFlowType),
        eglState.select(v2SelectCurrentProduct(params?.itemId)),
        eglState.select(selectCartSegment),
    ]).pipe(
        map(([flowType, product, cartSegment]) => {
            const canGetDefaultPotential = injector
                .get(PrivateConfigurationService)
                .get('callActivationGasPotencyDefault', false);
            const technicalDetails = product?.technicalDetails;
            const typeOfUsage = (technicalDetails?.typeOfUsage as GasConsumptionUse[]) || [];

            if (
                !!canGetDefaultPotential &&
                product &&
                cartSegment === D365CustomerSegment.Residential &&
                product?.powerOrGas === AptCommodityType.Gas &&
                salesProcessOrFlowTypeToOperationType(flowType) === AptSaleabilityOperationType.Attivazione &&
                !technicalDetails?.gasPotentialFromCheckPdr &&
                !!typeOfUsage &&
                typeOfUsage?.length > 0
            ) {
                return injector
                    .get(OrderEntryProvider)
                    .checkActivationGasDefaultPotential(product.lineItemId, typeOfUsage.join('|'))
                    .pipe(tap((res) => res))
                    .pipe(take(1))
                    .subscribe((res) => {
                        const techicalDetailsToDispatch = { ...technicalDetails } as GasTechnicalDetails;

                        if (!techicalDetailsToDispatch?.gasPotentialFromCheckPdr) {
                            techicalDetailsToDispatch.gasPotential =
                                (res && !res.ErrorMessage
                                    ? res?.PotentialConsumption
                                    : techicalDetailsToDispatch.gasPotential) || DEFAULT_GAS_POTENTIAL;

                            eglState.dispatch(
                                setV2TechnicalDetailsGas({
                                    technicalDetails: techicalDetailsToDispatch,
                                }),
                            );
                        }
                    });
            }
            return null;
        }),
    );

export const CheckPortalRegistrationEmail: RailwayFn = ({ eglState, injector }) => {
    const dataOwnershipChangeSrv = injector.get(DataOwnershipChangeService);

    return combineLatest([
        eglState.select(selectContactLead),
        dataOwnershipChangeSrv.skipCheckUniqueRegistrationMailForDataOwneshipChange(),
    ]).pipe(
        mergeMap(([contactLead, skip]) => {
            if (skip) {
                return of(null);
            }
            if (!contactLead.contact && !contactLead.lead) {
                return of(null);
            }

            const lead = contactLead.lead;
            const contact = contactLead.contact;

            const taxOrVatCodeToCheck =
                contact?.egl_vatcode || contact?.egl_taxcode || lead?.egl_vatcode || lead?.egl_taxcode;

            return injector
                .get(CommonProvider)
                .checkUniqueRegistrationEmail(taxOrVatCodeToCheck)
                .pipe(
                    mergeMap((email) => {
                        const retrievedEmail = email;

                        if (lead) {
                            lead.emailaddress1 = retrievedEmail || lead.emailaddress1;
                            lead.isEmailFromPortal = !!retrievedEmail;
                            eglState.dispatch(setLead({ l: lead }));
                        }

                        if (contact) {
                            contact.emailaddress1 = retrievedEmail || contact.emailaddress1;
                            contact.isEmailFromPortal = !!retrievedEmail;
                            eglState.dispatch(setContact({ c: contact }));
                        }

                        return of(null);
                    }),
                );
        }),
    );
};

export const checkDataOwnershipChangeConsinstency: RailwayFn = ({ eglState, injector }) => {
    const dataOwnershipChangeSrv = injector.get(DataOwnershipChangeService);

    return dataOwnershipChangeSrv.checkDataOwnershipChangeDataConsinstency().pipe(
        take(1),
        mergeMap((isDataConsistent) => of(null)),
    );
};

export const retrieveVoltSerialNumberNeta: RailwayFn = ({ eglState, injector }) => {
    return eglState.select(v2SelectCurrentProduct()).pipe(
        take(1),
        mergeMap((product) =>
            product?.powerOrGas === AptCommodityType.Gas &&
            injector.get(PrivateConfigurationService)?.config?.toggleFeature?.retrieveMatrMisNetaVolture
                ? injector
                      .get(OrderEntryProvider)
                      .getRetrieveSerialNumber(product?.podPdr)
                      .pipe(
                          tap((response) =>
                              console.log(
                                  '[Matricola Misuratore]',
                                  '\nValore presente su carrello:',
                                  product?.technicalDetails?.meterSerialNumber,
                                  '\nInvocazione servizio recupera matricola strumento Neta',
                                  '\nStato Response:',
                                  response?.status,
                                  '\nValore Matricola:',
                                  response?.response?.MatricolaCO,
                              ),
                          ),
                          tap((response) =>
                              response?.status === StatusResponse.Success &&
                              response?.response?.MatricolaCO &&
                              response?.response?.MatricolaCO.trim() !== ''
                                  ? eglState.dispatch(
                                        setV2ProductByIdx({
                                            product: {
                                                ...product,
                                                technicalDetails: {
                                                    meterSerialNumber: response?.response?.MatricolaCO,
                                                },
                                            },
                                            productIdx: product?.idx,
                                        }),
                                    )
                                  : of(null),
                          ),
                      )
                : of(null),
        ),
    );
};

function getStatusSubStatusAumPot(
    d365SignatureType: D365SignatureType,
    isInstantAcceptance: boolean,
    shippingMethod: AptBillType,
    predetermined: boolean,
): {
    status: AptQuoteStatus;
    subStatus: AptQuoteSubStatus | null;
} {
    let result = null;
    if (predetermined) {
        if (isInstantAcceptance) {
            switch (d365SignatureType) {
                case D365SignatureType.VocalOrder:
                    result = {
                        status: AptQuoteStatus.Firmato,
                        subStatus: null,
                    };
                    break;
                case D365SignatureType.PaperPrintedBySystem:
                    result = {
                        status: AptQuoteStatus.InAttesaFirma,
                        subStatus: AptQuoteSubStatus.FirmaSemidigital,
                    };
                    break;
                case D365SignatureType.DoiLight:
                    result = {
                        status: AptQuoteStatus.Sospeso,
                        subStatus: null,
                    };
                    break;
                default:
                    result = null;
                    break;
            }
        } else {
            result = {
                status: AptQuoteStatus.Sospeso,
                subStatus: null,
            };
        }
    } else {
        result = {
            status: AptQuoteStatus.Sospeso,
            subStatus: AptQuoteSubStatus.AttesaPreventivoDistributore,
        };
    }
    return result;
}
