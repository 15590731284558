import { FilterCommodityType } from './../../common/enums/apttus/apt-commodity-typeof-sale';
import { createAction, props } from '@ngrx/store';
import { boActionString as c } from './action-types';
import { GestioneConditions } from '../../modules/back-office/models/gestione-filter-list';
import { MonitoraggioConditions } from '../../modules/back-office/models/monitoraggio-filter-list';
import { CarrelliFilterList } from '../../modules/back-office/models/carrelli-filter-list';
import { AptSalesProcess, AptSalesInadmissibilityProcess } from '../../common/enums/apttus/apt-sales-process';
import { Tabs } from '../../common/enums/shared/tabs';
import { IGenericBreadcrumbFilter } from '../../modules/back-office/common/breadcrumb-filter/models/IGenericBreadcrumbFilter';
import { ActionDefinition } from '../../modules/back-office/common/most-used-actions/most-used-actions.component';
import { MassiveActionsFilters } from '../../modules/back-office/models/bo-filters';
import { SelectedBill } from '../../modules/bills-instalment/models/selected-bill';
import { TableModel } from '../../common/components/table/table.component';

export const setMonitoraggioFilterBar = createAction(c.SET_MON_FILTER_BAR, props<{ p: MonitoraggioConditions }>());

export const setSelectedTab = createAction(c.SET_SELECTED_TAB, props<{ p: Tabs }>());
export const setGestioneFilterBar = createAction(c.SET_GEST_FILTER_BAR, props<{ p: GestioneConditions }>());
export const setCarrelliFilterBar = createAction(c.SET_CART_FILTER_BAR, props<{ p: CarrelliFilterList }>());
export const setSelectedOpMode = createAction(c.SET_SELECTED_OP_MODE, props<{ p: AptSalesProcess }>());
export const setSelectedMacroOpMode = createAction(c.SET_SELECTED_MACRO_OP_MODE, props<{ p: string }>());
export const setSelectedMostUsedActions = createAction(
    c.SET_SELECTED_MOST_USED_ACTION,
    props<{ p: ActionDefinition }>()
);
export const setSelectInamissibilityMode = createAction(
    c.SET_SELECTED_INADMIS_MODE,
    props<{ p: AptSalesInadmissibilityProcess }>()
);
export const setCommodityType = createAction(c.SET_COMMODITY_TYPE, props<{ p: FilterCommodityType }>());
export const setAgentFilter = createAction(c.SET_AGENT_FILTER, props<{ p: IGenericBreadcrumbFilter[] }>());
export const setChannelFilter = createAction(c.SET_CHANNEL_FILTER, props<{ p: IGenericBreadcrumbFilter[] }>());
export const setVirtualAgencyFilter = createAction(
    c.SET_VIRTUALAGENCY_FILTER,
    props<{ p: IGenericBreadcrumbFilter[] }>()
);

export const setAgencyBranchFilter = createAction(
    c.SET_AGENCY_BRANCH_FILTER,
    props<{ p: IGenericBreadcrumbFilter[] }>()
);
export const resetSearchFilters = createAction(c.RESET_SEARCH_FILTERS, props<{ p: Tabs }>());
export const setDefaultSearchFilters = createAction(c.SET_DEFAULT_SEARCH_FILTERS, props<{ p?: Tabs }>());
export const setBOQuoteId = createAction(c.SET_BO_QUOTE_ID, props<{ p: string }>());

export const setMonitoraggioFiltersAndOpMode = createAction(
    c.SET_MON_FILTERS_OP_MODE,
    props<{ f: MonitoraggioConditions; opMode: AptSalesProcess; inadmiss: AptSalesInadmissibilityProcess }>()
);
export const setMassiveActionsFilters = createAction(
    c.SET_MASSIVE_ACTIONS_FILTERS,
    props<{ massiveActionsFilters: MassiveActionsFilters }>()
);

export const setBillInstalmentSelectedBill = createAction(
    c.SET_BILL_INSTALMENT_SELECTED_BILL,
    props<{ billInstalmentSelectedBill: SelectedBill }>()
);
export const setBillInstalmentClientCode = createAction(
    c.SET_BILL_INSTALMENT_CLIENT_CODE,
    props<{ billInstalmentClientCode: string }>()
);
