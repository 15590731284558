import CodiceFiscale from 'codice-fiscale-js';
import * as moment from 'moment';
import { Gender } from '../models/user/contact';

/**
 * Ricalcola il checkDigit a partire da una PIVA di input.
 * Restituisce True/False se il checkDigit calcolato risultata
 * uguale a quello dell'PIVA di input
 * @param pIva
 */
export const isPivaValid = (pIva: string): boolean => {
    if (!pIva || pIva.length !== 11) {
        return false;
    }

    const validi = '0123456789';
    for (let i = 0; i < 11; i++) {
        if (validi.indexOf(pIva.charAt(i)) === -1) {
            return false;
        }
    }

    let s = 0;
    for (let i = 0; i <= 9; i += 2) {
        s += pIva.charCodeAt(i) - '0'.charCodeAt(0);
    }

    for (let i = 1; i <= 9; i += 2) {
        let c = 2 * (pIva.charCodeAt(i) - '0'.charCodeAt(0));
        if (c > 9) {
            c = c - 9;
        }
        s += c;
    }
    if ((10 - (s % 10)) % 10 !== pIva.charCodeAt(10) - '0'.charCodeAt(0)) {
        return false;
    }
    return true;
};

/**
 * Verifica di COERENZA tra dati inseriti e CF ed età consentita
 * @param params valori di validazione
 * @param fullCheck se TRUE check sull'interno CF, se FALSE solo i primi 6 caratteri
 * @return isValid: true se cf valido o { isValid: false; reason: ReasonDescription}
 */
export const isCfValid = (params: TaxCodeData, fullCheck: boolean): boolean => {
    let isValid = false;

    if (reverseCf(params.cfCode)) {
        const isItalianCf = !isNoItalianCf(params.cfCode);
        const cfCalculated = calculateCF(params, fullCheck);
        const normalizedCf = CodiceFiscale.compute(CodiceFiscale.computeInverse(params.cfCode));
        // Controllo il CF in modo separato in quanto:
        // - per i cognomi corti l'AdE inserisce una X come terza lettera e deve essere comparata solo la parte iniziale (es: per cognomi "FE" il calculateCF calcola correttamente "FEX", mentre il normalizedCf calcola erroneamente "FXE")
        // - per i casi di fullcheck deve essere presa in considerazione anche la parte finale per ottenere un calcolo corretto e:
        //   - per i casi classici, params.cfCode === cfCalculated === normalizedCf
        //   - per i casi di cognome corto, params.cfCode === cfCalculated
        //   - per i casi di omocodia, cfCalculated === normalizedCf
        if (cfCalculated) {
            isValid = params.cfCode.slice(0, 6).toUpperCase() === cfCalculated.slice(0, 6);
            if (isValid && isItalianCf && fullCheck) {
                isValid = [params.cfCode.slice(6).toUpperCase(), normalizedCf.slice(6)].includes(cfCalculated.slice(6));
            }
        }
    }
    return isValid;
};

/**
 * Restituisce True se il CF contine la lettera Z nell'undicesima posizione
 * @param codFisc CF da verificare
 */
export const isNoItalianCf = (codFisc: string): boolean => {
    if (codFisc && codFisc.length > 11) {
        return codFisc.charAt(11).toUpperCase() === 'Z';
    }
    return false;
};

/**
 * @description Calculates the age of user from fiscal code or given birth date. Moment takes in consideration days and months passed.
 * @param taxCode Codice fiscale
 * @param birthDate YYYY-MM-DD
 * @output The age in years
 */
export const calculateAge = (taxCode?: string, birthDate?: string): number => {
    if (!taxCode && !birthDate) return null;
    const today = moment();
    const birthday = reverseCf(taxCode) ? moment(reverseCf(taxCode)?.birthday) : moment(birthDate);
    const age = today.diff(birthday, 'years');
    return age;
};

/**
 * @description Checks if the person's age is between the minimum and max age allowed.
 * @param age Age in number.
 * @param taxCode Codice fiscale
 * @param birthDate DD-MM-YYYY
 * @param minAge Minimum age allowed. If not defined, min age is 18.
 * @param maxAge Max age allowed. If not defined, max age is 120.
 * @returns True or false, either if its valid or not.
 */
export const isAgeValid = ({
    age,
    taxCode,
    birthDate,
    minAge = 18,
    maxAge = 120,
}: {
    age?: number;
    taxCode?: string;
    birthDate?: string;
    minAge?: number;
    maxAge?: number;
    range?: number;
}): boolean => {
    const userAge = age ? age : calculateAge(taxCode || birthDate);
    return userAge >= minAge && userAge <= maxAge;
};

/**
 * @description Determina se la persona ha già compiuto 75 anni o se il suo 75° compleanno rientra nel range specificato per essere considerata vulnerabile.
 * Il 75° compleanno dev'essere entro l'ultimo giorno del mese corrente + X mesi.
 * @param taxCode Codice fiscale
 * @param range Mesi da aggiungere ad oggi per arrivare all'ultima data possibile.
 * @output Restituisce true se la persona è considerata vulnerabile, altrimenti restituisce false.
 */
export const isVulnerableOver75 = (taxCode: string, range: number = 0): boolean => {
    try {
        if (!taxCode) {
            throw new Error('Missing taxCode');
        }
        // Controllo l'età
        const userAge = calculateAge(taxCode);
        if (!userAge) {
            throw new Error('Cannot calculate age');
        }
        const isOver75 = isAgeValid({ age: userAge, minAge: 76 });
        // Se ha già 75 anni non faccio altri controlli
        if (isOver75) {
            return true;
        } else {
            // Ricavo il compleanno
            const { birthday, day, month, year: birthdayYear } = reverseCf(taxCode);
            // Ricavo l'età al giorno del compleanno di quest'anno, indipendente di quale giorno sia oggi
            const thisYear = new Date().getFullYear();
            const ageInThisYearsBirthday = thisYear - birthdayYear;
            // Ricavo il compleanno in cui compie 75 anni
            const targetAge = 75;
            const willBe75Year = thisYear + (targetAge - ageInThisYearsBirthday);
            const the75thBirthday = `${willBe75Year}-${month}-${day}`;
            // Definisco il range all'interno del quale può compiere 75 anni, entro l'ultimo di questo mese + X mesi (range)
            const today = moment();
            const endRange = moment(moment(today).add(range, 'months')).endOf('month').format('YYYY-MM-DD');
            // Controllo se il 75esimo compleanno è all'interno del range
            const isWithinRange = moment(the75thBirthday).diff(endRange, 'days') <= 0;
            // E' considerato vulnerabile over 75 se nel range
            console.log(
                '[Vulnerabilità over 75 🧓🏻]',
                'Data di nascita:',
                birthday,
                '\nEnd range:',
                endRange,
                '\nEtà calcolata:',
                userAge,
                '\n75° compleanno:',
                the75thBirthday,
                '\nMesi da aggiungere',
                range,
                '\nConsiderato over 75?',
                isWithinRange
            );
            return isWithinRange;
        }
    } catch (error) {
        // in caso di errore, ritorno false
        console.error(error);
        return false;
    }
};

/**
 * Dato un CF di input restituisce un oggetto contenente i valori calcolati attraverso
 * il calcolo inverso del CF. Resituisce Null in caso di CF errato.
 * @param codFisc CF di input
 */

export const reverseCf = (codFisc: string): IOutputCodiceFiscaleObj => {
    try {
        return (((codFisc || '').trim().length === 16 && CodiceFiscale.computeInverse(codFisc.trim().toUpperCase())) ||
            null) as IOutputCodiceFiscaleObj;
    } catch (error) {
        // in caso di eccezione la libreria del reverse non è riuscita ad
        // effettuare il reverse del CF
        return null;
    }
};

const calculateCF = (inp: TaxCodeData, fullCheck: boolean): string => {
    try {
        const isItalianCf = !isNoItalianCf(inp.cfCode);
        if (inp.firstname && inp.lastname) {
            let birtDatas = {
                birtDay: 12,
                birtMonth: 7,
                birtYear: 1957,
                birthPlace: 'Napoli',
                gender: <Gender>'M',
            };

            if (isItalianCf && fullCheck) {
                const date = inp.birtDate;
                birtDatas = {
                    birtDay: date.getDate(),
                    birtMonth: date.getMonth() + 1,
                    birtYear: date.getFullYear(),
                    birthPlace: inp.birthPlace,
                    gender: inp.gender,
                };
            }

            const cfPayload: IInputCodiceFiscaleObj = {
                name: inp.firstname,
                surname: inp.lastname,
                gender: birtDatas.gender,
                day: birtDatas.birtDay,
                month: birtDatas.birtMonth,
                year: birtDatas.birtYear,
                birthplace: birtDatas.birthPlace,
            };

            if (isItalianCf && fullCheck) {
                cfPayload.birthplaceProvincia = inp.birthProvince;
            }

            return CodiceFiscale.compute(cfPayload);
        }
        return null;
    } catch (e) {
        return null;
    }
};
export interface TaxCodeData {
    cfCode: string;
    firstname: string;
    lastname: string;
    birtDate?: Date;
    birthPlace?: string;
    birthProvince?: string;
    gender?: Gender;
}

export interface IInputCodiceFiscaleObj {
    name: string;
    surname: string;
    gender: Gender;
    day: number;
    month: number;
    year: number;
    birthplace: string;
    birthplaceProvincia?: string; // Optional
}

interface IOutputCodiceFiscaleObj {
    name: string;
    surname: string;
    gender: Gender;
    day: number;
    month: number;
    year: number;
    birthplace: string; // in caso di estero restituisce la Nazione
    birthplaceProvincia: string; // in caso di estero restituisce EE
    cf?: string;
    birthday?: string;
}
