import { AfterViewInit, Component, Injector, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { setShouldOpenTroubleshooting, setUrlHash } from './store/actions/app.actions';
import { environment } from '../environments/environment';
import { Store } from '@ngrx/store';
import { InternetConnectionService } from './common/services/app/internet-connection.service';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from './common/services/shared/logger.service';
import { Cart, CartService, UserService } from '@congacommerce/ecommerce';
import { D365Service } from './common/services/d365/d365.service';
import { ApttusService } from './common/services/apttus/apttus.service';
import { LoadingService } from './common/services/shared/loading.service';
import { catchError, filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { EglCartExtended } from './common/models/apttus/tables/cart/egl-cart-extended';
import { EglState } from './store/reducers';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalComponent } from './common/components/modal/modal.component';
import { RoutesPaths } from './common/config/routes-paths';
import { selectAgentInfo, selectContactName, selectLead } from './store/selectors/user.selectors';
import { Lead } from './common/models/user/lead';
import { ACondition, APageInfo, ASort } from '@congacommerce/core';
import { combineLatest, Observable, of } from 'rxjs';
import { D365CustomerSegment } from './common/enums/d365/d365-customer-segment';
import { setCartSegment, setLead } from './store/actions/user.actions';
import { UserState } from './store/models/user-state';
import { Contact } from './common/models/user/contact';
import { EglCartLight } from './common/models/apttus/tables/cart/egl-cart-light';
import { EglCartLightService } from './common/services/apttus/tables/cart/egl-cart-light.service';
import { AptSalesProcess } from './common/enums/apttus/apt-sales-process';
import {
    convertSegmentD365toApt,
    d365OperationModeToFlowType,
    flowTypeToAptSalesProcess,
    getNumberOrNull,
} from './common/functions/remap.functions';
import * as moment from 'moment';
// Importo in modo esplicito, la localizzazione italiana di moment per evitare che venga rimossa in seguito all'ottimizzazione della dimensione della build
import 'moment/locale/it';
import { selectHasDevBar } from './store/selectors/app.selectors';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { UtilityService } from './common/services/shared/utility.service';
import { activationCartFromUrl } from './common/functions/misc.functions';
import { EglSalesupStateService } from './common/services/apttus/tables/egl-salesup-state.service';
import { AgentAuthorizationService } from './common/services/app/agent-authorization.service';
import { OrderEntryState_v2 } from './store/models/order-entry-state_v2';
import { D365OperationMode } from './common/enums/d365/d365-operation-mode';
import { setFlowType } from './store/actions/order-entry.actions';
import { LocalStorageGenericService } from './common/services/shared/local-storage-generic.service';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { Regex } from './common/config/regex';
import { getContactNameFromUserState } from './store/selectors/selector-utility.functions';
import { TelemetryMetricService } from './common/services/app/telemetry-metric.service';
import { DataOwnershipChangeService } from './common/services/shared/data-ownership-change.service';
import { allowedRestoreSalesProcess } from './common/functions/verifications.functions';

@Component({
    selector: 'egl-root',
    template: `
        <ng-container>
            <egl-dev-bar></egl-dev-bar>
            <div [ngStyle]="{ 'padding-top': paddingDev$ | async }">
                <router-outlet *ngIf="!inLoading"></router-outlet>
            </div>
        </ng-container>
        <ng-template #modalMain>
            <egl-modal
                [modalRef]="modal?.modalRef"
                [title]="modal?.title"
                [description]="modal?.description"
                [txtBtnSx]="modal?.btnSx?.label"
                (btnSxClick)="modal?.btnSx?.onClick()"
                [txtBtnCn]="modal?.btnCn?.label"
                (btnCnClick)="modal?.btnCn?.onClick()"
                [txtBtnDx]="modal?.btnDx?.label"
                (btnDxClick)="modal?.btnDx?.onClick()"
                (onClose)="modal?.onClose()"
                [alignLeft]="true"
            >
            </egl-modal>
        </ng-template>
    `,
})
export class AppComponent implements OnInit, AfterViewInit {
    @ViewChild('modalMain', { static: true }) mainModal: TemplateRef<ModalComponent>;
    modal: MainModal;
    inLoading = true;
    segmentToBe: D365CustomerSegment;
    cartId: string;
    userState: UserState;
    orderEntryState: OrderEntryState_v2;
    leadFromCRM: Lead;
    contactFromCRM: Contact;
    paddingDev$ = this.store.select(selectHasDevBar).pipe(map((x) => (x ? '18px' : '0px')));

    constructor(
        private translateSrv: TranslateService,
        private intenetConnectionSrv: InternetConnectionService,
        private store: Store<EglState>,
        private cartLightSrv: EglCartLightService,
        private logger: LoggerService,
        private d365Srv: D365Service,
        private apttusSrv: ApttusService,
        private userSrv: UserService,
        private cartSrv: CartService,
        private utilityService: UtilityService,
        private stateSrv: EglSalesupStateService,
        private agentAuthorizationSrv: AgentAuthorizationService,
        private localStorageSrv: LocalStorageGenericService,
        private router: Router,
        private injector: Injector,
        private telemetrySrv: TelemetryMetricService
    ) {
        this.localStorageSrv.restoreBoFilters = false;
        moment.locale(environment.defaultLanguage);
        registerLocaleData(localeIt, environment.defaultLanguage);
        this.store.dispatch(setUrlHash({ hash: location.hash }));

        this.router.events
            .pipe(filter((evnt) => evnt instanceof NavigationStart))
            .pipe(
                tap((evnt: RouterEvent) => this.logger.info(`NavigationStart to ${evnt.url}`)),
                filter(
                    ({ url: destinationUrl }) =>
                        Regex.ORDER_ENTRY_ROUTE.test(destinationUrl) || Regex.CATALOG_ROUTE.test(destinationUrl)
                ),
                mergeMap(() => this.apttusSrv.cartHaveQuote()),
                tap((cartHaveQuote) => {
                    if (cartHaveQuote) {
                        this.logger.warn(`Cart ${cartHaveQuote.cartId} have a quote ${cartHaveQuote.quoteId}`);
                        setTimeout(() => this.showCartHasQuote(), 1000);
                    }
                })
            )
            .subscribe();
    }

    ngAfterViewInit(): void {
        this.modal = new MainModal(this.mainModal, this.injector);
    }

    /**
     * @description: Metodo dell'interfaccia OnInit
     */
    async ngOnInit(): Promise<void> {
        if (parent.window !== window) {
            this.intenetConnectionSrv.init();
            await this.checkUserAndUrlParams();
            this.setTranslate();
        }

        this.apttusSrv.showDropCartModal.subscribe(() => this.openDropCartModal());
    }

    private showCartHasQuote(): void {
        this.modal.show({
            title: this.translateSrv.instant('ORDER_ENTRY.CART_MODAL.EXIST_QUOTE_TITLE'),
            description: this.translateSrv.instant('ORDER_ENTRY.CART_MODAL.EXIST_QUOTE_DESCRIPTION'),
            onClose: () => this.modalCreateNewCart(),
            btnDx: {
                label: this.translateSrv.instant('ORDER_ENTRY.CART_MODAL.RESTART_TO_SELL'),
                onClick: () => this.modalCreateNewCart(),
            },
        });
    }
    /**
     * @description: Recupero ultimo carrello pending
     */
    private getCurrentCart(): void {
        const acartParam = activationCartFromUrl();
        if (
            (location.pathname.includes('carts/') && !location.pathname.includes('carts/active')) ||
            location.pathname.includes('load-asset/operation') ||
            acartParam
        ) {
            this.logger.info('Skipping app.component / getCurrentCart');
            acartParam && this.logger.info(`Recovery of existing cart Id: ${acartParam}`, location.href);
            this.inLoading = false;
            return;
        }

        this.logger.info('Loading Agent Info');
        this.store
            .select(selectAgentInfo)
            .pipe(
                filter((agentInfo) => !!agentInfo?.Agent && !!agentInfo?.Agency && !!agentInfo?.VirtualAgents),
                take(1),
                mergeMap((agentInfo) => {
                    this.segmentToBe = this.segmentToBe || agentInfo.UserConfiguration.LastUsedCustomerSegment;
                    this.store.dispatch(setCartSegment({ payload: this.segmentToBe }));

                    if (!this.agentAuthorizationSrv.canSell) {
                        this.logger.warn('Agent not enabled to sell, skipping retrive cart');
                    }
                    this.logger.info('Agent Info Loaded. Call to getMyCart');
                    LoadingService.update('Recupero carrello in corso');
                    return this.cartSrv.getMyCart().pipe(take(1));
                }),
                mergeMap((cart: EglCartExtended) => {
                    this.logger.info('[🧻] Loaded cart before checks', cart);
                    if (activationCartFromUrl()) return of(cart);

                    const skipRestoredCart = Object.entries(this.SKIP_CART_RULES).find(([_, rule]) => rule(cart))?.[0];
                    if (!localStorage.getItem('preserveCart') && !!skipRestoredCart) {
                        cart?.Id &&
                            this.logger.info(
                                `Cart NOT recoverable: ${cart?.Id}. Reason: '${skipRestoredCart.toUpperCase()}'.`
                            );
                        return this.apttusSrv.createNewCart(
                            new EglCartExtended(),
                            convertSegmentD365toApt(this.segmentToBe)
                        );
                    }

                    return of(cart);
                }),
                mergeMap((cart: EglCartExtended) => {
                    this.cartId = cart.Id;
                    const dataOwnershipChangeSrv = this.injector.get(DataOwnershipChangeService);
                    return combineLatest([
                        of(cart),
                        this.stateSrv.restoreSupStateByCartId(cart.Id),
                        dataOwnershipChangeSrv.shouldRemoveSaveCart(),
                        dataOwnershipChangeSrv.canDeleteCartWithoutProducts(),
                    ]);
                }),
                LoadingService.loaderOperator('Recupero profilo in corso'),
                this.telemetrySrv.rxTelemetry('retrive-sup-state')
            )
            .subscribe(
                ([cart, supState, shouldRemoveSaveCart, canDeleteCartWithoutProducts]) => {
                    let contactName = null;
                    if (supState) {
                        this.userState = supState.user;
                        this.orderEntryState = supState.orderEntry;
                        contactName = getContactNameFromUserState(this.userState);
                    }

                    const canDeleteEptyCartForUpsellingCWE =
                        Object.keys(supState?.user?.dataOwnershipChange?.data || {}).length > 0 &&
                        canDeleteCartWithoutProducts;

                    if (
                        (cart?.LineItems?.length || canDeleteEptyCartForUpsellingCWE) &&
                        !location.pathname.includes('load-asset/operation')
                    ) {
                        let modalParameters = {
                            title: this.translateSrv.instant('ORDER_ENTRY.CART_MODAL.TERMINATE_CART'),
                            description: this.translateSrv.instant('ORDER_ENTRY.CART_MODAL.EXIST_CART_QUESTION', {
                                contactName: contactName ? `associato a <b>${contactName}</b>` : '<b>anonimo</b>',
                            }),
                            btnSx: {
                                label: this.translateSrv.instant('ORDER_ENTRY.CART_MODAL.RESUME_DELETE'),
                                onClick: () => this.modalCreateNewCart(true),
                            },
                            btnDx: {
                                label: this.translateSrv.instant('ORDER_ENTRY.CART_MODAL.RESUME_CLOSE'),
                                onClick: () => {},
                            },
                        };

                        const btnCn = {
                            btnCn: {
                                label: this.translateSrv.instant('ORDER_ENTRY.CART_MODAL.SAVE_CART'),
                                onClick: () =>
                                    this.modalCreateNewCart(
                                        false,
                                        flowTypeToAptSalesProcess(this.orderEntryState?.flowType)
                                    ),
                            },
                        };

                        if (!shouldRemoveSaveCart) {
                            modalParameters = { ...modalParameters, ...btnCn };
                        }

                        this.modal.show(modalParameters);
                    }
                },
                () => {},
                () => (this.inLoading = false)
            );
    }

    /**
     * @description: Salva il carrello corrente e ne crea uno nuovo
     */
    private modalCreateNewCart(dropCart = false, salesProcess = null): void {
        const navigateTo = this.insideOrderEntry ? [RoutesPaths.Dashboard] : null;
        if (dropCart) {
            this.apttusSrv.clearAndCreateNewCart(navigateTo);
        } else {
            this.apttusSrv
                .createNewCart(
                    new EglCartExtended(),
                    convertSegmentD365toApt(this.segmentToBe),
                    navigateTo,
                    false,
                    true,
                    true,
                    salesProcess
                )
                .subscribe();
        }
    }

    /**
     * @description: Init translator
     */
    private setTranslate(): void {
        this.translateSrv.setDefaultLang(environment.defaultLanguage);
        this.translateSrv
            .use(environment.defaultLanguage)
            .pipe(
                take(1),
                catchError((err) => {
                    this.logger.error('Translator', 'Error in setDefaultLang', err, false);
                    return of(null);
                })
            )
            .subscribe();
    }

    /**
     * @deprecated: Questo va rimosso. Va utilizzato il codice di swin con dovuti aggiustamenti
     */
    private async getLeadForCP(): Promise<void> {
        const userType = this.utilityService.getValueFromHash('userType');
        const userGuid = this.utilityService.getValueFromHash('userGuid');
        if (location.pathname.includes(RoutesPaths.CP) && userGuid && userType === 'lead') {
            // L'ENTRY POINT è UN LEAD DA D365
            const lead = await this.d365Srv.getLead(userGuid).toPromise();
            if (lead) {
                this.logger.info(`LEAD retrieved. GUID: ${userGuid}`);
                this.segmentToBe = lead.egl_customersegmentcode;
                this.store.dispatch(setCartSegment({ payload: this.segmentToBe }));
                this.store.dispatch(setLead({ l: lead, fcrm: lead !== null }));
            } else {
                this.logger.error(`No LEAD retrieved. GUID: ${userGuid}`);
            }
        }
    }

    /**
     * @description: Verifico l'utente corrente ed eventuali parametri (token, lead ecc...)
     */
    private async checkUserAndUrlParams(): Promise<void> {
        const isLoggedIn = await this.userSrv
            .isLoggedIn()
            .pipe(take(1), this.telemetrySrv.rxTelemetry('check-is-logged-in'))
            .toPromise();

        this.logger.info(`User is logged in? ${isLoggedIn}`);
        if (!isLoggedIn) {
            this.inLoading = false;
            LoadingService.update('Autenticazione in corso');
            this.logger.warn('Attempt login');
            return;
        }

        try {
            // Richiesta apertura BO o Scheda Asset
            if (NO_RESTORE_CART.find((x) => location.pathname.includes(x)) || !this.agentAuthorizationSrv.canSell) {
                this.getLeadForCP();
                this.inLoading = false;
                this.localStorageSrv.latestACartLoaded = null;
                return;
            }
            this.checkParameters();
            if (location.hash.length !== 0) {
                const url = new URL(`https://${location.host}?${location.hash.slice(1)}`);
                const userType = url.searchParams.get('userType');
                const userGuid = url.searchParams.get('userGuid');
                // const category = url.searchParams.get('leadtopic');
                const customerSegment = url.searchParams.get('customerSegment');
                const operationMode = url.searchParams.get('operationMode');
                if (operationMode) this.setOperationMode(operationMode);

                if (userType === 'lead') {
                    // L'ENTRY POINT è UN LEAD DA D365
                    await this.leadManager(userGuid, customerSegment);
                } else if (userType === 'account') {
                    // TODO: VERIFICARE SE L'ACCOUNT DEVE FUNZIONARE COME IL LEAD
                    this.contactFromCRM = await this.d365Srv.getContact(userGuid);
                    if (this.contactFromCRM) {
                        this.segmentToBe = this.contactFromCRM.egl_customersegmentcode;
                    }
                    this.getCurrentCart();
                } else {
                    this.getCurrentCart();
                }
            } else {
                this.getCurrentCart();
            }
        } catch (error) {
            this.inLoading = false;
            LoadingService.abort();
            this.logger.error(null, 'An error occurred', error, true);
        } finally {
            this.localStorageSrv.manageSegments = null;
        }
    }

    private async leadManager(userGuid: string, customerSegment: string): Promise<void> {
        const lead = await this.d365Srv.getLead(userGuid, +customerSegment).toPromise(); // passo il customer segment recuperato dall'url
        if (lead) {
            this.segmentToBe = lead.egl_customersegmentcode;
            this.store.dispatch(setCartSegment({ payload: this.segmentToBe }));
            this.store.dispatch(setLead({ l: lead, fcrm: lead !== null }));
        }
        this.store
            .select(selectLead)
            .pipe(
                filter((lead) => lead !== undefined),
                take(1),
                mergeMap((lead) => {
                    this.leadFromCRM = lead;
                    if (!lead) return of(null);

                    const cart$ = this.cartLightSrv.where(
                        [
                            new ACondition(
                                EglCartLight,
                                'egl_sales_process',
                                'NotEqual',
                                AptSalesProcess.CambioProdotto
                            ),
                            new ACondition(EglCartLight, 'egl_leadid', 'Equal', lead.egl_code),
                            new ACondition(EglCartLight, 'Apttus_Config2__BusinessObjectRefId', 'Equal', null),
                        ],
                        'AND',
                        null,
                        [new ASort(EglCartLight, 'LastModifiedDate', 'DESC')],
                        new APageInfo(1, 1)
                    ) as Observable<[EglCartLight]>;
                    return cart$.pipe(
                        take(1),
                        map((cart: [EglCartLight]) => cart[0])
                    );
                }),
                mergeMap((cart: Cart) => {
                    if (cart) {
                        // ESISTE GIà UN CARRELLO ASSOCIATO AL LEAD
                        LoadingService.update('Carrello trovato. Ripristino');
                        CartService.setCurrentCartId(cart.Id);
                        this.cartSrv.refreshCart();
                        return of(cart);
                    } else {
                        // CREO UN NUOVO CARRELLO DA ASSOCIARE AL LEAD
                        LoadingService.update('Nessun carrello trovato');
                        return this.apttusSrv
                            .createNewCart(
                                Object.assign(new EglCartExtended(), {
                                    egl_leadid: this.leadFromCRM.egl_code,
                                    egl_tag: this.leadFromCRM.egl_tag,
                                }),
                                convertSegmentD365toApt(this.segmentToBe)
                            )
                            .pipe(take(1));
                    }
                }),
                mergeMap((cart: unknown) => {
                    if (cart instanceof EglCartLight) {
                        LoadingService.update('Verifica delle sessioni precedenti');
                        return this.stateSrv.restoreSupStateByCartId(cart.Id);
                    }
                    return of(null);
                }),
                LoadingService.loaderOperator('Ricerca carrelli associati al LEAD'),
                this.telemetrySrv.rxTelemetry('search-lead-cart')
            )
            .subscribe((state: EglState) => {
                this.inLoading = false;
                if (state) {
                    this.logger.warn('State found. Dispatch');
                    this.userState = state.user;
                    this.orderEntryState = state.orderEntry;
                }
            });
    }

    private async setOperationMode(operationMode: string) {
        if (operationMode in D365OperationMode) {
            const opmode = getNumberOrNull(operationMode) as D365OperationMode;
            const flowType = d365OperationModeToFlowType(opmode);
            this.store.dispatch(setFlowType({ flowType: flowType }));
        }
    }

    private checkParameters(): void {
        const troubleshooting = this.utilityService.getValueFromHash('troubleshooting');
        const showTroubleShooting = troubleshooting === 'true';
        this.store.dispatch(setShouldOpenTroubleshooting({ openTroubleshooting: showTroubleShooting }));
    }

    openDropCartModal(): void {
        const dataOwnershipChangeSrv = this.injector.get(DataOwnershipChangeService);

        combineLatest([this.store.select(selectContactName), dataOwnershipChangeSrv.shouldRemoveSaveCart()])
            .pipe(take(1))
            .subscribe(([contactName, shouldRemoveSaveButton]) => {
                let modalParameters = {
                    title: this.translateSrv.instant('ORDER_ENTRY.CART_MODAL.TERMINATE_CART'),
                    description: this.translateSrv.instant('ORDER_ENTRY.CART_MODAL.TERMINATE_CART_QUESTION', {
                        contactName: contactName ? `associato a <b>${contactName}</b>` : '<b>anonimo</b>',
                    }),
                    btnSx: {
                        label: this.translateSrv.instant('ORDER_ENTRY.CART_MODAL.DROP_DELETE'),
                        onClick: () => this.modalCreateNewCart(true),
                    },
                    btnDx: {
                        label: this.translateSrv.instant('ORDER_ENTRY.CART_MODAL.DROP_CLOSE'),
                        onClick: () => {},
                    },
                };

                const btnCn = {
                    btnCn: {
                        label: this.translateSrv.instant('ORDER_ENTRY.CART_MODAL.SAVE_CART'),
                        onClick: () => this.modalCreateNewCart(),
                    },
                };

                if (!shouldRemoveSaveButton) {
                    modalParameters = { ...modalParameters, ...btnCn };
                }

                this.modal.show(modalParameters);
            });
    }

    private get insideOrderEntry(): boolean {
        return Regex.ORDER_ENTRY_ROUTE.test(this.router.url);
    }

    /**
     * Funzioni che verificano se il carrello è recuperabile o no.
     * Se la funzione restituisce TRUE allora il carrello non è recuplerabile e se ne deve creare uno nuovo
     */
    private SKIP_CART_RULES: { [key in string]?: (cart: EglCartExtended) => boolean } = {
        ['no cart id']: (cart: EglCartExtended) => !cart?.Id,
        ['starting from lead']: (cart: EglCartExtended) => !!cart?.egl_leadid,
        ['segment is different']: (cart: EglCartExtended) =>
            cart?.egl_customer_type !== convertSegmentD365toApt(this.segmentToBe),
        ['sales process not enabled']: (cart: EglCartExtended) => !allowedRestoreSalesProcess(cart?.egl_sales_process),
        ['cart has linked quote']: (cart: EglCartExtended) => !!cart?.Proposald,
    };
}

const NO_RESTORE_CART = [
    RoutesPaths.BackOffice,
    'asset/',
    'cp/',
    'dev/',
    'voltura',
    RoutesPaths.Offline,
    RoutesPaths.BulkOrderBasePath,
    RoutesPaths.SuspensionBasePath,
    RoutesPaths.InterruptionBasePath,
    RoutesPaths.ResumingBasePath,
    RoutesPaths.ResumingBasePath,
    'workability',
    'deactivation',
];

type MainModalBtn = {
    label: string;
    onClick: () => void;
};

type MainModalConfig = Partial<{
    title: string;
    description: string;
    btnSx: MainModalBtn;
    btnCn: MainModalBtn;
    btnDx: MainModalBtn;
    onClose: () => void;
}>;

class MainModal {
    title: string;
    description: string;
    btnSx: MainModalBtn;
    btnCn: MainModalBtn;
    btnDx: MainModalBtn;
    onClose: () => void;
    modalRef: BsModalRef;

    constructor(private component: TemplateRef<ModalComponent>, private injector: Injector, config?: MainModalConfig) {
        if (!component) throw new Error('MainModal: component is required');
        this.setPropsByConfig(config);
    }

    show = (config?: MainModalConfig) => {
        this.setPropsByConfig(config);
        this.modalRef = this.injector.get(BsModalService).show(this.component);
    };
    hide = () => this.modalRef.hide();
    private setPropsByConfig(config: MainModalConfig) {
        if (!config) return;
        this.title = config?.title || this.title;
        this.description = config?.description || this.description;
        this.btnSx = config?.btnSx;
        this.btnCn = config?.btnCn;
        this.btnDx = config?.btnDx;
        this.onClose = config?.onClose || (() => {});
    }
}
